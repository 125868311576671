import { View, ViewProps } from "./view.tsx";

/** MAIN **/

export type ListItemProps<T> = {
  index: number;
  data: T;
};

export type ListProps = ViewProps<"section">;

export function List(props: ListProps) {
  return (
    <View
      viewProps={props}
      tag="section"
      class="flex flex-col gap-1"
    />
  );
}
