import { useRouter } from "app/hooks/use_router.ts";
import { Profile } from "icons/profile.tsx";
import { Chip } from "local/ui/chip.tsx";
import { Icon } from "local/ui/icon.tsx";

/** MAIN **/

export function UserWidget() {
  const router = useRouter();

  return (
    <Chip
      title="Account Settings"
      href={router.href("settings", "account")}
    >
      <Icon>
        <Profile />
      </Icon>
    </Chip>
  );
}
