import { parseCsv } from "./parse_csv.ts";

/** HELPERS **/

function fromCsv(str: string): ParseResult {
  const lines = parseCsv(str.trim());
  const [header, ...body] = lines;

  const columns = header.map((name) => {
    return {
      name,
    };
  });

  const rows = body.map((row) => {
    const cells = row.map((data, i) => {
      return {
        name: columns[i].name,
        data,
      };
    });

    return {
      cells,
    };
  });

  return {
    columns,
    rows,
  };
}

function fromJson(str: string): ParseResult {
  const data = JSON.parse(str) as Record<string, string>[];
  const columns = Object.keys(data[0]).map((name) => {
    return {
      name,
    };
  });

  const rows = data.map((row) => {
    const cells = Object.entries(row).map(([name, data]) => {
      return {
        name,
        data,
      };
    });

    return {
      cells,
    };
  });

  return {
    columns,
    rows,
  };
}

const parsers: Record<string, (str: string) => ParseResult> = {
  "text/json": fromJson,
  "text/csv": fromCsv,
};

/** MAIN **/

export type ParseResult = {
  columns: {
    name: string;
  }[];
  rows: {
    cells: {
      name: string;
      data: string;
    }[];
  }[];
};

export function parse(text: string, type: string): ParseResult {
  const parser = parsers[type];
  if (!parser) {
    throw new Error(`No parser for type: ${type}`);
  }

  return parser(text);
}

export async function parseFile(file: File): Promise<ParseResult> {
  const text = await file.text();
  return parse(text, file.type);
}
