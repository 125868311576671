import {
  array,
  Infer,
  integer,
  object,
  optional,
  string,
  type,
} from "local/deps/superstruct.ts";

/** MAIN **/

export type EffectFrame = Infer<typeof EffectFrame>;
export const EffectFrame = object({
  layer: string(),
  color: string(),
  duration: integer(), // ms
});

export type Effect = Infer<typeof Effect>;
export const Effect = object({
  description: string(),
  frames: array(EffectFrame),
});

export type Execution = Infer<typeof Execution>;
export const Execution = type({
  id: string(),
  attributes: type({
    status: string(),
    statusReason: optional(string()),
    createdAt: string(),
    output: optional(string()),
  }),
});
