import { useMarkdownRenderer } from "./context.ts";
import { Node } from "./node.ts";

/** MAIN **/

export type MarkdownProps = {
  root: Node<"Root">;
};

export function Markdown(props: MarkdownProps) {
  const { root } = props;
  const render = useMarkdownRenderer();
  return render(root);
}
