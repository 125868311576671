import { Icon } from "./icon.tsx";
import { View, ViewChildren, ViewProps } from "./view.tsx";

/** MAIN **/

export function Chip(
  props: Omit<ViewProps<"a">, "tag"> & {
    selected?: boolean;
    color?: string;
    preventDefault?: boolean;
    toggled?: boolean;
    icon?: ViewChildren;
  },
) {
  const {
    onClick,
    disabled,
    selected,
    color,
    preventDefault,
    toggled,
    children,
    icon,
    ...viewProps
  } = props;

  const interactive = !disabled && (onClick || props.href);
  const tag = props.href
    ? "a" as const
    : (onClick ? "button" as const : "span" as const);

  const defaultColorLight = "black";
  const defaultColorDark = "white";

  const bgOpacityHover = 0.05;
  const bgOpacityFocus = 0.05;
  const bgOpacitySelected = 0.1;
  const bgOpacityDisabled = 0;

  const textOpacity = 0.7;
  const textOpacityInteractive = 1;
  const textOpacityDisabled = 0.3;

  const colorLight = color ?? defaultColorLight;
  const colorDark = color ?? defaultColorDark;

  const bgClasses = [
    `bg-${colorLight} dark:bg-${colorDark}`,
    "bg-opacity-0 dark:bg-opacity-0",
    interactive &&
    `hover:(bg-opacity-[${bgOpacityHover}] dark:bg-opacity-[${bgOpacityHover}])`,
    interactive &&
    `focus:(bg-opacity-[${bgOpacityFocus}] dark:bg-opacity-[${bgOpacityFocus}])`,
    selected &&
    `bg-opacity-[${bgOpacitySelected}] dark:bg-opacity-[${bgOpacitySelected}]`,
    disabled &&
    `bg-opacity-[${bgOpacityDisabled}] dark:bg-opacity-[${bgOpacityDisabled}]`,
  ];

  const textClasses = [
    `text-${colorLight} dark:text-${colorDark}`,
    `text-opacity-[${textOpacity}] dark:text-opacity-[${textOpacity}]`,
    disabled &&
    `text-opacity-[${textOpacityDisabled}] dark:text-opacity-[${textOpacityDisabled}]`,
    interactive &&
    `hover:text-opacity-[${textOpacityInteractive}] dark:hover:text-opacity-[${textOpacityInteractive}]`,
  ];

  return (
    <View
      tag={tag}
      viewProps={viewProps}
      tabIndex={interactive ? undefined : -1}
      onClick={(e: MouseEvent) => {
        if (preventDefault || disabled) {
          e.preventDefault();
        }
        if (onClick) {
          // FIXME: Weird type error below.
          // @ts-ignore need to fix this
          onClick(e);
          e.stopPropagation();
        }
      }}
      class={[
        "overflow-hidden",
        "flex items-center justify-center gap-2",
        "rounded-full transition",
        "p-2 min-w-[2rem] h-8",
        "focus:outline-none",
        "text-xs",
        "whitespace-nowrap",
        disabled && "cursor-not-allowed",
        interactive && "cursor-pointer select-none",
        bgClasses,
        textClasses,
      ]}
    >
      {icon && (
        <Icon>
          {icon}
        </Icon>
      )}
      {children}
    </View>
  );
}
