import { usePolling } from "app/hooks/use_polling.tsx";
import { useCallback, useEffect } from "local/deps/preact/hooks.ts";
import { Signal, useComputed } from "local/deps/preact/signals.ts";
import { Item } from "local/ui/item.tsx";
import { List } from "local/ui/list.tsx";
import { Panel } from "local/ui/panel.tsx";
import { Spinner } from "local/ui/spinner.tsx";
import { Tag } from "local/ui/tag.tsx";
import { View } from "local/ui/view.tsx";
import { usePrompt } from "../../stores/hooks/use_prompt.ts";
import { CompletionItem } from "./completion_item.tsx";

/** MAIN **/

export type Completion = {
  id: string;
  createdAt: string;
  status: "pending" | "running" | "success" | "error" | "cancelled";
};

export interface CompletionsListProps {
  promptId: string;
  completionId: Signal<string | null>;
}

export function CompletionsList(props: CompletionsListProps) {
  const { promptId, completionId } = props;
  const { completions } = usePrompt(promptId);

  const pollingCallback = useCallback(() => {
    completions.load();
  }, [promptId, completions.store.value.data]);

  const polling = usePolling(
    pollingCallback,
    5000,
  );

  useEffect(() => {
    completions.load();
  }, [promptId]);

  const loading = useComputed(() => {
    const { data, loading } = completions.store.value;
    const hasCompletions = data.length > 0;
    return loading && !hasCompletions;
  });

  const isProcessingCompletions = useComputed(() => {
    return completions.store.value.data
      .some((item) => item.status === "pending" || item.status === "running");
  });

  const onCompletionSelected = useCallback((id: string) => {
    completionId.value = completionId.value === id ? null : id;
  }, [completionId]);

  useEffect(() => {
    if (isProcessingCompletions.value) {
      polling.start();
    } else {
      polling.stop();
    }
  }, [isProcessingCompletions.value]);

  const completionsItems = completions.store.value.data;

  return (
    <Panel
      reverseGradient
      header={
        <View tag="h2" class="flex gap-2 items-center text-2xl">
          Results
          {!loading.value && completionsItems.length > 0 && (
            <span>
              <Tag class="mr-3">{completionsItems.length ?? 0}</Tag>
            </span>
          )}
        </View>
      }
    >
      <List>
        {loading.value && (
          <Item>
            <Spinner />
            <p class="text-gray-600 dark:text-gray-400 text-xs italic">
              Loading your results...
            </p>
          </Item>
        )}
        {(!loading.value && !completionsItems.length)
          ? (
            <View class="text-xs italic text-gray-500">
              No results to display
            </View>
          )
          : (completionsItems.map((item) => (
            <CompletionItem
              selected={item.id === completionId.value}
              key={item.id}
              completion={{
                id: item.id,
                promptId,
                createdAt: item.createdAt,
                status: item.status,
              }}
              onCompletionClicked={onCompletionSelected}
            />
          )))}
      </List>
    </Panel>
  );
}
