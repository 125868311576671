import { useLocationHref } from "app/hooks/use_location_href.ts";
import { Item } from "local/ui/item.tsx";
import { ViewChildren } from "local/ui/view.tsx";

/** MAIN **/

export function SettingsItem(props: {
  href: string;
  label: string;
  icon: ViewChildren;
  status?: ViewChildren;
}) {
  const { href, label, icon, status } = props;

  const locationHref = useLocationHref();

  return (
    <Item
      interactive
      class="text-sm"
      selected={locationHref === href}
      href={href}
      icon={icon}
      title={label}
    >
      {status}
    </Item>
  );
}
