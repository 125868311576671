import { createMutation } from "app/utils/graphql.ts";
import { array, number, object, string } from "local/deps/superstruct.ts";

/** HELPERS **/

export const query = createMutation({
  name: "createPromptCommit",
  vars: object({
    input: object({
      message: string(),
      prompt: object({
        id: string(),
        name: string(),
        body: string(),
        parameters: object({
          temperature: number(),
          maxTokens: number(),
          stopSequences: array(string()),
          frequencyPenalty: number(),
          presencePenalty: number(),
          mode: string(),
          model: string(),
        }),
      }),
    }),
  }),
  output: object({
    id: string(),
  }),
  body: `
    mutation ($input: CreatePromptCommitInput!) {
      createPromptCommit(input: $input) {
        id
      }
    }
  `,
});
