import { useUserApiKeyRepo } from "app/features/stores/hooks/use_user_api_key_repo.ts";
import { MathPlus } from "icons/math_plus.tsx";
import { Spinner } from "local/ui/spinner.tsx";
import { View } from "local/ui/view.tsx";
import { nanoid } from "../../../../packages/deps/nanoid.ts";
import { useCallback } from "../../../../packages/deps/preact/hooks.ts";
import {
  useComputed,
  useSignal,
} from "../../../../packages/deps/preact/signals.ts";
import { Chip } from "../../../../packages/ui/chip.tsx";
import { Icon } from "../../../../packages/ui/icon.tsx";
import { Item } from "../../../../packages/ui/item.tsx";
import { List } from "../../../../packages/ui/list.tsx";
import { UserApiKeyItem } from "./user-api-key-item.tsx";

/** MAIN **/

export function UserApiKeyWidget() {
  const repo = useUserApiKeyRepo();

  const keys = repo.store.value.data;

  const revokeApiKey = useCallback((id: string) => {
    const confirmed = window.confirm("Delete this API key?");
    if (!confirmed) {
      return;
    }
    repo.remove(id);
  }, []);

  const isCreatingKey = useSignal(false);

  const generateApiKey = useCallback(() => {
    if (isCreatingKey.value) {
      return;
    }

    isCreatingKey.value = true;
    repo.create({
      id: nanoid(),
      createdAt: new Date().toISOString(),
      vendor: "floweryai",
      secret: "empty",
    }, { optimistic: false }).finally(() => {
      isCreatingKey.value = false;
    });
  }, [isCreatingKey.value]);

  const loading = useComputed(() => repo.store.value.pending > 0);

  return (
    <List class="gap-5">
      {loading.value && keys.length === 0 && (
        <Item>
          <Spinner />
          <View class="text-xs text-gray-500">
            Loading your keys...
          </View>
        </Item>
      )}
      {!loading.value && (
        <Item>
          <Chip
            disabled={isCreatingKey.value}
            onClick={generateApiKey}
            color="blue-500"
          >
            {!isCreatingKey.value && (
              <Icon>
                <MathPlus />
              </Icon>
            )}
            {isCreatingKey.value && <Spinner />}
            Generate API Key
          </Chip>
        </Item>
      )}
      {!loading.value && keys.length === 0 && (
        <Item class="text-sm text-gray-500 italic">
          You have no API keys.
        </Item>
      )}
      {keys?.map((key) => (
        <UserApiKeyItem
          key={key.id}
          apiKey={key}
          onTrash={() => revokeApiKey(key.id)}
        />
      ))}
    </List>
  );
}
