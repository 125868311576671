import { useQuerySignal } from "app/hooks/graphql.ts";
import { useEffect } from "local/deps/preact/hooks.ts";
import { useComputed } from "local/deps/preact/signals.ts";
import * as getFlowRunners from "../queries/flow_runners.ts";

/** MAIN **/
export function useFlowRunners(promptId: string) {
  const result = useQuerySignal(getFlowRunners.query, { id: promptId });

  useEffect(() => {
    result.load();
  }, [promptId]);

  const data = useComputed(() => {
    return result.data.value?.flowCommits
      .flatMap((promptCommit) => promptCommit.flowRunners)
      .sort((a, b) => b.createdAt.localeCompare(a.createdAt));
  });

  useEffect(() => {
    result.data.value = undefined;
  }, [promptId]);

  return {
    ...result,
    data,
  };
}
