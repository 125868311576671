import { ChevronLeft } from "icons/chevron_left.tsx";
import { ChevronRight } from "icons/chevron_right.tsx";
import { Signal } from "local/deps/preact/signals.ts";
import { Chip } from "local/ui/chip.tsx";
import { Icon } from "local/ui/icon.tsx";
import { View } from "local/ui/view.tsx";

/** MAIN **/

export function Switcher(props: {
  count: number;
  index: Signal<number>;
}) {
  const { count, index } = props;
  return (
    <View
      class={[
        "px-2 py-2 flex gap-4 h-12 items-center",
        "rounded-full transition-colors",
      ]}
    >
      <Chip
        onClick={() => {
          index.value = (index.value + count - 1) % count;
        }}
      >
        <Icon>
          <ChevronLeft />
        </Icon>
      </Chip>
      <View class="flex flex-1 justify-center">
        <Chip>
          <span>
            <b>#{index.value + 1}</b> of {count}
          </span>
        </Chip>
      </View>
      <Chip
        onClick={() => {
          index.value = (index.value + 1) % count;
        }}
      >
        <Icon>
          <ChevronRight />
        </Icon>
      </Chip>
    </View>
  );
}
