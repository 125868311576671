import { Router } from "app/utils/router.ts";
import { createContext } from "local/deps/preact.ts";
import { useContext } from "local/deps/preact/hooks.ts";

/** MAIN **/

export const RouterContext = createContext<Router | undefined>(undefined);

export function navigateTo(
  url: URL,
  options?: {
    reload?: boolean;
  },
) {
  const { reload } = options ?? {};

  location.hash = url.hash;

  if (reload) {
    setTimeout(() => {
      location.reload();
    });
  }
}

export function useRouter() {
  const router = useContext(RouterContext);
  if (!router) {
    throw new Error("RouterContext not found");
  }
  return router;
}
