import { useQuery } from "app/hooks/graphql.ts";
import { createMutation, createQuery, fields } from "app/utils/graphql.ts";
import { array, object, string, unknown } from "local/deps/superstruct.ts";
import { createRepo, Repo } from "local/repos/mod.ts";
import { ApiKey } from "../types/mod.ts";

/** HELPERS **/

const listQuery = createQuery({
  name: "listApiKeys",
  output: array(ApiKey),
  body: `
    query {
      listApiKeys {
        ${fields(ApiKey)}
      }
    }
  `,
});

const createMutations = createMutation({
  name: "createApiKey",
  vars: object({
    input: object({
      vendor: string(),
      secret: string(),
    }),
  }),
  output: ApiKey,
  body: `
    mutation ($input: CreateApiKeyInput!) {
      createApiKey(input: $input) {
        ${fields(ApiKey)}
      }
    }
  `,
});

const deleteMutation = createMutation({
  name: "revokeApiKey",
  vars: object({
    id: string(),
  }),
  output: unknown(),
  body: `
    mutation ($id: ID!) {
      revokeApiKey(id: $id) {
        id
      }
    }
  `,
});

/** MAIN **/

let repo: Repo<ApiKey>;

export function useApiKeyRepo() {
  const exec = useQuery();
  if (repo) return repo;

  repo = createRepo({
    create: (item) => {
      return exec(createMutations, {
        input: {
          vendor: item.vendor,
          secret: item.secret,
        },
      });
    },
    update: () => {
      throw new Error("ApiKeys cannot be updated");
    },
    remove: async (id) => {
      await exec(deleteMutation, { id });
    },
    equals: (a, b) => {
      return a.id === b.id &&
        a.createdAt === b.createdAt &&
        a.vendor === b.vendor &&
        a.secret === b.secret;
    },
  });

  const { load, merge } = repo;

  load(async () => {
    merge(await exec(listQuery));
  });

  return repo;
}
