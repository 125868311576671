import { useGuardStatus } from "app/hooks/guard.ts";
import { useHover } from "app/hooks/use_hover.ts";
import { SpinnerTwo } from "icons/spinner_two.tsx";
import { useSignal } from "local/deps/preact/signals.ts";
import { Chip } from "local/ui/chip.tsx";
import { Icon } from "local/ui/icon.tsx";

/** MAIN **/

export function SavingWidget() {
  const guard = useGuardStatus();
  const { hasPending } = guard.value;

  const element = useSignal<HTMLElement | null>(null);
  const hovered = useSignal(false);
  useHover(element, hovered);

  return (
    <Chip
      element={element}
      title={hasPending ? "Saving changes" : "Changes saved"}
      class={[
        hasPending ? "opacity-50" : "pointer-events-none opacity-0",
      ]}
    >
      {hovered.value && (hasPending ? "Saving..." : "Saved")}
      <Icon class="animate-spin">
        <SpinnerTwo />
      </Icon>
    </Chip>
  );
}
