import { User } from "app/utils/auth.ts";
import { createContext } from "local/deps/preact.ts";
import { useContext } from "local/deps/preact/hooks.ts";

/** MAIN **/

export type Auth = undefined | {
  user: User;
  logout: () => void;
};

export const AuthContext = createContext<Auth>(undefined);

export function useAuth() {
  const auth = useContext(AuthContext);
  if (!auth) {
    throw new Error("useUser must be used within a AuthProvider");
  }
  return auth;
}
