import { useFlowRepo, usePromptRepo } from "app/features/stores/mod.ts";
import { ReadonlySignal, useComputed } from "local/deps/preact/signals.ts";
import * as Steps from "../types/steps.ts";

/** MAIN **/

export type FlowItem = {
  title: string | undefined;
  isLoading: boolean;
  isMissing: boolean;
  id: string;
  type: "flows";
  name: string;
};

export type PromptItem = {
  title: string | undefined;
  isLoading: boolean;
  isMissing: boolean;
  id: string;
  type: "prompts";
  name: string;
};

export type EmptyItem = {
  isLoading: boolean;
  isMissing: boolean;
  id: string;
  type: "empty";
  name: string;
  title: "Unknown";
};

export type StepItem = FlowItem | PromptItem | EmptyItem;

export function useStepItems(
  steps: ReadonlySignal<Steps.State>,
): ReadonlySignal<StepItem[]> {
  const flowRepo = useFlowRepo();
  const promptRepo = usePromptRepo();

  return useComputed(() => {
    const flows = flowRepo.store.value;
    const prompts = promptRepo.store.value;
    return steps.value.map((step) => {
      if (step.type === "flows") {
        const flow = flows.data.find((f) => f.id === step.id);
        const status = flowRepo.status(flow);
        const { isLoading, isMissing } = status;
        return {
          ...step,
          type: "flows" as const,
          title: flow?.name,
          isLoading,
          isMissing,
        };
      }

      if (step.type === "prompts") {
        const prompt = prompts.data.find((p) => p.id === step.id);
        const status = promptRepo.status(prompt);
        const { isLoading, isMissing } = status;
        return {
          ...step,
          type: "prompts" as const,
          title: prompt?.name,
          isLoading,
          isMissing,
        };
      }

      return {
        ...step,
        title: "Unknown",
        type: "empty" as const,
        isLoading: false,
        isMissing: false,
      };
    });
  });
}
