import * as config from "app/config/client.ts";
import { AuthContext } from "app/hooks/auth.ts";
import { GraphqlContext } from "app/hooks/graphql.ts";
import { GuardContext } from "app/hooks/guard.ts";
import { parseUser, User } from "app/utils/auth.ts";
import { useEffect } from "local/deps/preact/hooks.ts";
import { useSignal } from "local/deps/preact/signals.ts";
import { Router } from "./router.tsx";

/** MAIN **/

export function App() {
  // Store pending requests.
  const guard = {
    pending: new Map(),
    hasPending: useSignal(false),
  };

  // On unload, check if there are pending requests and ask the user to confirm.
  useEffect(() => {
    function unloadHandler(e: BeforeUnloadEvent) {
      if (guard.pending.size === 0) return;
      e.returnValue = "You have unsaved changes. Leave anyways?";
      e.preventDefault();
      return e.returnValue;
    }

    addEventListener("beforeunload", unloadHandler);
    return () => {
      removeEventListener("beforeunload", unloadHandler);
    };
  }, []);

  // Helper for redirecting to the home page.
  function redirectUnauthorized() {
    location.hash = "";
    location.reload();
  }

  // Helper for logging out.
  function logout() {
    localStorage.removeItem(config.localStorageKeys.user);
    redirectUnauthorized();
  }

  // Helper for getting the user from the URL or local storage.
  function authenticate() {
    // Try to get the user from the URL.
    const user = parseUser(location.hash);
    if (user) {
      localStorage.setItem(
        config.localStorageKeys.user,
        JSON.stringify(user),
      );

      // Remove the login token from the URL and reload the page.
      location.hash = "/dashboard";
      return user;
    }

    // Try to get the user from local storage.
    const json = localStorage.getItem(config.localStorageKeys.user);
    if (!json) return;
    return JSON.parse(json) as User;
  }

  // Authenticate the user.
  const user = authenticate();
  if (!user) {
    redirectUnauthorized();
    return null;
  }

  const auth = {
    user,
    logout,
  };

  const graphql: GraphqlContext = {
    url: config.graphql.url,
    token: user.token,
    pending: new Set(),
    onTokenExpired: logout,
  };

  return (
    <GuardContext.Provider value={guard}>
      <AuthContext.Provider value={auth}>
        <GraphqlContext.Provider value={graphql}>
          <Router />
        </GraphqlContext.Provider>
      </AuthContext.Provider>
    </GuardContext.Provider>
  );
}
