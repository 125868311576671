import { useEffect } from "local/deps/preact/hooks.ts";
import { Signal, useSignal } from "local/deps/preact/signals.ts";

/** MAIN **/

export function useValueStore<T>(value: T): Signal<T> {
  const store = useSignal(value);

  useEffect(() => {
    store.value = value;
  }, [value]);

  return store;
}
