import { History } from "app/components/icons/history.tsx";
import { PlayButton } from "app/components/icons/play_button.tsx";
import {
  tokenize,
  variableNames,
} from "app/features/prompts/utils/tokenize.ts";
import { useFlowRepo, usePromptRepo } from "app/features/stores/mod.ts";
import { useRepoItem } from "app/hooks/use_repo_item.ts";
import { useState } from "local/deps/preact/hooks.ts";
import {
  batch,
  useSignal,
  useSignalEffect,
} from "local/deps/preact/signals.ts";
import { Chip } from "local/ui/chip.tsx";
import { EditorPanel } from "local/ui/editor_panel.tsx";
import { Icon } from "local/ui/icon.tsx";
import { Item } from "local/ui/item.tsx";
import { List } from "local/ui/list.tsx";
import { Spinner } from "local/ui/spinner.tsx";
import { TextEditor } from "local/ui/text_editor.tsx";
import { View } from "local/ui/view.tsx";
import { Animated } from "../../../../packages/ui/animated.tsx";
import { usePlay } from "../hooks/use_play.ts";
import * as Steps from "../types/steps.ts";
import { StepList } from "./step_list.tsx";

const VARIABLE_PALETTE = [
  "#fd7f6f",
  "#7eb0d5",
  "#b2e061",
  "#bd7ebe",
  "#ffb55a",
  "#ffee65",
  "#beb9db",
  "#fdcce5",
  "#8bd3c7",
];

/** MAIN **/
export function Editor(props: {
  id: string;
  onViewResults: () => void;
}) {
  const { id, onViewResults } = props;

  const name = useSignal("");
  const steps = useSignal<Steps.State>([]);
  const flowRepo = useFlowRepo();
  const promptRepo = usePromptRepo();
  const editor = useRepoItem(flowRepo, id);

  const variables = useSignal<{ name: string; color: string }[]>([]);
  const [variableInputs, setVariableInputs] = useState<
    Record<string, string | undefined>
  >({});

  const [runnerLoading, setRunnerLoading] = useState(false);

  useSignalEffect(() => {
    const { data } = editor.store.value;
    if (!data) return;
    batch(() => {
      name.value = data.name;
      steps.value = data.steps;
    });
  });

  useSignalEffect(() => {
    editor.save({
      name: name.value,
      steps: steps.value,
    });
  });

  const play = usePlay();

  function onPlay() {
    setRunnerLoading(true);
    const { data } = editor.store.value;

    if (!data) return;
    play({
      flow: data,
      variables: variables.value.map((v) => ({
        name: v.name,
        value: variableInputs[v.name] ?? "",
      })),
    }).finally(
      () => {
        setRunnerLoading(false);
      },
    );
  }

  const state = editor.store.value;

  useSignalEffect(() => {
    const prompts = promptRepo.store.value.data.filter((prompt) =>
      steps.value.find((step) => step.id === prompt.id)
    );

    const vars = Array.from(
      new Set(prompts.flatMap((prompt) => variableNames(prompt.body))),
    );

    variables.value = vars.map((v, i) => ({
      name: v,
      color: VARIABLE_PALETTE[i % VARIABLE_PALETTE.length],
    }));
  });

  return (
    <EditorPanel
      title={name.value}
      loading={state.status.isLoading}
      loadingMessage="Loading flow..."
      onTitleChange={(value) => {
        name.value = value;
      }}
    >
      <List>
        <Item>
          <View class="flex-1" />
          <Chip
            title="View Results"
            onClick={onViewResults}
          >
            <Icon class="scale-[0.90]">
              <History />
            </Icon>
          </Chip>
          <Chip
            disabled={runnerLoading}
            color="green-500"
            onClick={runnerLoading ? undefined : onPlay}
          >
            {runnerLoading ? <Spinner /> : (
              <Icon>
                <PlayButton />
              </Icon>
            )}
          </Chip>
        </Item>
        <View class="w-full pb-3">
          <Animated duration={500}>
            {variables.value.map((variable) => (
              <View class="w-full my-2">
                <TextEditor
                  title={`Value for {{${variable}}}`}
                  borderColor={variable.color}
                  value={variableInputs}
                  onChange={(value) => {
                    setVariableInputs({
                      ...value,
                      [variable.name]: value[variable.name],
                    });
                  }}
                  tokenize={(value) =>
                    tokenize({
                      body: value[variable.name] ?? "",
                    })}
                  parse={(text) => {
                    return { ...variableInputs, [variable.name]: text };
                  }}
                  stringify={(value) => {
                    return value[variable.name] ?? "";
                  }}
                  renderToken={(token) => (
                    <View tag="span">
                      {token.value}
                    </View>
                  )}
                  placeholder={
                    <View tag="span" class="text-gray-500">
                      Enter an initial value for &#123;&#123;{variable
                        .name}&#125;&#125;
                    </View>
                  }
                />
              </View>
            ))}
          </Animated>
        </View>
        <StepList variablePalette={variables} data={steps} />
      </List>
    </EditorPanel>
  );
}
