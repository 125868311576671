import { UserApiKeySettings } from "app/features/deployments/components/user-api-key-settings.tsx";
import { Toolbar } from "app/features/toolbar/mod.ts";
import { Panel } from "local/ui/panel.tsx";
import { View } from "local/ui/view.tsx";

/** MAIN **/

export function Connect() {
  return (
    <View class="flex flex-1 relative">
      <View class="absolute w-full h-full">
        <Panel>
          <UserApiKeySettings />
        </Panel>
      </View>
      <Toolbar />
    </View>
  );
}
