import {
  array,
  Infer,
  integer,
  nullable,
  object,
  string,
} from "local/deps/superstruct.ts";

/** MAIN **/

export type TableMeta = Infer<typeof TableMeta>;

export const TableMeta = object({
  layout: array(object({
    width: integer(),
  })),
});

export type Table = Infer<typeof Table>;

export const Table = object({
  id: string(),
  createdAt: string(),
  name: string(),
  meta: nullable(TableMeta),
  columns: array(object({
    name: string(),
  })),
});

export function equalsTableMeta(
  a: TableMeta | null,
  b: TableMeta | null,
) {
  if (a === b) return true;
  return (
    !!a &&
    !!b &&
    a.layout.length === b.layout.length &&
    a.layout.every((layout, i) => {
      return (
        layout.width === b.layout[i].width
      );
    })
  );
}

export function equalsTable(a: Table, b: Table) {
  return (
    a.id === b.id &&
    a.name === b.name &&
    // columns
    a.columns.length === b.columns.length &&
    a.columns.every((column, i) => {
      return column.name === b.columns[i].name;
    }) &&
    equalsTableMeta(a.meta, b.meta)
  );
}
