import Portal from "./portal.tsx";
import { View, ViewProps } from "./view.tsx";

/** MAIN **/

export type PopoverState = {
  id: string;
  isOpen: boolean;
  openerRect: DOMRect | null;
};

export function Popover(
  props: ViewProps<"div"> & {
    isOpen?: boolean;
    openerRect?: DOMRect | null;
    align?: ["left" | "right", "top" | "bottom"];
  },
) {
  const { isOpen, openerRect, align = ["right", "top"], ...rest } = props;

  const width = 240;
  const left = align[0] === "left"
    ? openerRect?.left
    : (openerRect?.right ?? 0) - width + 8;

  const top = align[1] === "top"
    ? (openerRect?.bottom ?? 0) + 8
    : (openerRect?.bottom ?? 0);

  return (
    <Portal target={document.querySelector("#popover")}>
      <View
        viewProps={rest}
        class={[
          !isOpen && "pointer-events-none",
          isOpen ? "opacity-100" : "opacity-0",
          "transition-opacity duration-200",
          "absolute",
          "p-1",
          "bg-gray-100 dark:bg-gray-900",
          "rounded-lg",
          "shadow-lg",
        ]}
        style={{
          left,
          top,
          width,
        }}
      />
    </Portal>
  );
}
