import { nanoid } from "local/deps/nanoid.ts";
import { assert, Struct } from "local/deps/superstruct.ts";

/**
 * This TypeScript module provides utility functions for handling GraphQL queries and mutations.
 * It defines types and functions for creating, executing, and validating GraphQL queries and
 * mutations in a structured way. The main functionalities provided are:
 *
 * 1. Context: A type that represents the context required for executing GraphQL queries and
 *    mutations, including the URL, token, pending requests, and a callback for handling token
 *    expiration.
 *
 * 2. Query: A type that represents a GraphQL query, including its name, body, input and output
 *    types, and whether it is guarded (requires authentication).
 *
 * 3. createQuery() and createMutation(): Functions to create Query and Mutation objects.
 *
 * 4. exec(): A function that executes a GraphQL query or mutation, taking a Context and
 *    ExecOptions as arguments, and returning a Promise that resolves to the query or mutation output.
 *
 * 5. fields(): A utility function that recursively extracts fields from a Struct object, which
 *    is useful for building GraphQL queries and mutations.
 *
 * The module is designed to work with the Superstruct library for data validation and the nanoid
 * library for generating unique IDs.
 */

/** MAIN **/

export type Context = {
  url: URL;
  token: string;
  pending: Set<unknown>;
  onTokenExpired?: () => void;
};

export type Query<I, O> = {
  name?: string;
  guarded?: boolean;
  body: string;
  vars?: Struct<I>;
  output?: Struct<O>;
};

export type ExecOptions<I, O> = {
  query: Query<I, O>;
  args?: I;
};

export function createQuery<I, O>(query: Query<I, O>) {
  return query;
}

export function createMutation<I, O>(mutation: Query<I, O>) {
  return {
    ...mutation,
    guarded: true,
  };
}

export async function exec<I, O>(
  context: Context,
  options: ExecOptions<I, O>,
): Promise<O> {
  const { query, args } = options;
  const { name, body, output, vars, guarded } = query;

  const token = nanoid();

  try {
    if (vars) {
      assert(args, vars);
    }

    if (guarded) {
      context.pending.add(token);
    }

    const response = await fetch(context.url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // No "Bearer" prefix here for the GraphQL server.
        Authorization: context.token,
      },
      body: JSON.stringify({
        query: body,
        variables: args,
      }),
    });

    const result = await response.json();

    if (result.errors) {
      const hasTokenExpired = result.errors.some((error: { message: string }) =>
        error.message.includes("Token has expired.")
      );

      if (hasTokenExpired) {
        context.onTokenExpired?.();
      }

      const message = result.errors
        .map((error: { message: string }) => {
          return "- " + error.message;
        })
        .join("\n");

      throw new Error(message);
    }

    const data = name ? result.data[name] : result.data;

    if (output) {
      assert(data, output);
    }

    return data;
  } finally {
    if (guarded) {
      context.pending.delete(token);
    }
  }
}

// deno-lint-ignore no-explicit-any
export function fields(struct: Struct<any, any>, depth = 0): string {
  const keys = Object.keys(struct.schema);
  return keys
    .map((key) => {
      if (key === "TYPE") return "";
      // deno-lint-ignore no-explicit-any
      const type = struct.schema[key] as Struct<any, any>;
      if (type.type === "object") {
        return `${key} { ${fields(type, depth + 1)} }`;
      } else if (type.type === "array") {
        //no subselection is possible for primtiive types
        if (type.schema.type === "string" || type.schema.type === "number") {
          return key;
        }
        return `${key} {\n${fields(type.schema, depth + 1)}\n}`;
      } else {
        return key;
      }
    })
    .map((line) => line.padStart(line.length + depth * 2, " "))
    .join("\n");
}
