import { Toolbar } from "app/features/toolbar/mod.ts";
import { UserSettings } from "app/features/user/mod.ts";
import { Panel } from "local/ui/panel.tsx";
import { View } from "local/ui/view.tsx";

/** MAIN **/

export function Account() {
  return (
    <View class="flex flex-1 relative">
      <View class="absolute w-full h-full">
        <Panel>
          <UserSettings />
        </Panel>
      </View>
      <Toolbar />
    </View>
  );
}
