import { Restart } from "app/components/icons/restart.tsx";
import { Status } from "app/components/status.tsx";
import { CompletionViewer } from "app/features/completions/components/completion_viewer.tsx";
import { useCreateFlowRunner } from "app/features/flow_runners/hooks/use_create_flow_runner.ts";
import { usePolling } from "app/hooks/use_polling.tsx";
import { Copy } from "icons/copy.tsx";
import { useCallback, useEffect, useState } from "local/deps/preact/hooks.ts";
import { Chip } from "local/ui/chip.tsx";
import { Icon } from "local/ui/icon.tsx";
import { Item } from "local/ui/item.tsx";
import { List } from "local/ui/list.tsx";
import { ListDivider } from "local/ui/list_divider.tsx";
import { Spinner } from "local/ui/spinner.tsx";
import { View } from "local/ui/view.tsx";
import { useSignal } from "../../../../packages/deps/preact/signals.ts";
import { Tune } from "../../../components/icons/tune.tsx";
import { FlowRunner as FlowRunnerType } from "../../stores/types/flow_runner.ts";
import { useFlowCommit } from "../hooks/use_flow_commit.ts";

/** MAIN **/

export interface FlowRunnerProps {
  flowRunner?: FlowRunnerType;
  reload: () => void;
}

export function FlowRunner(
  props: FlowRunnerProps,
) {
  const { flowRunner, reload } = props;
  const create = useCreateFlowRunner();
  const [running, setRunning] = useState(false);
  const flowCommit = useFlowCommit(flowRunner?.flowCommitId ?? "");

  const copyUrl = useCallback(() => {
    const url = flowCommit.data.value?.workflows?.[0]?.url;
    if (!url) return;
    navigator.clipboard.writeText(url);
  }, [flowCommit.data.value]);

  const promptParamsOpen = useSignal(false);

  const togglePromptParams = useCallback(() => {
    promptParamsOpen.value = !promptParamsOpen.value;
  }, []);

  const polling = usePolling(
    reload,
    1000,
  );

  useEffect(() => {
    flowCommit.load();
  }, [flowRunner?.id]);

  useEffect(() => {
    const status = flowRunner?.status;
    if (status === "pending" || status === "running" || status === "ready") {
      polling.start();
    } else {
      polling.stop();
    }
    return () => polling.stop();
  }, [flowRunner?.status]);

  const rerun = useCallback(() => {
    const flowCommitId = flowRunner?.flowCommitId;
    const initialVariables = flowRunner?.steps?.[0]?.completion?.variables;

    if (!flowCommitId) return;
    setRunning(true);
    create({
      flowCommitId,
      variables: initialVariables ?? undefined,
    }).finally(() => setRunning(false));
  }, [
    flowRunner?.flowCommitId,
    flowRunner?.variables,
    flowRunner?.steps,
    setRunning,
  ]);

  if (!flowRunner) return null;

  const steps = flowRunner.steps
    .sort((a, b) => a.index - b.index);

  return (
    <List>
      <Item class="text-xs text-gray-500">
        <Status
          status={flowRunner.status}
          startedAt={new Date(flowRunner.createdAt)}
          stoppedAt={flowRunner.stoppedAt
            ? new Date(flowRunner.stoppedAt)
            : undefined}
        />
        <View class="flex-1" />
        <Chip
          color="green-500"
          onClick={running ? undefined : rerun}
          title="Run again"
        >
          {running ? <Spinner /> : (
            <Icon>
              <Restart />
            </Icon>
          )}
        </Chip>
      </Item>
      {flowCommit.data.value?.workflows?.[0]?.url && (
        <Item class="text-xs text-gray-500">
          <Chip
            onClick={copyUrl}
            color="blue-500"
            title="Use this URL to start this flow from anywhere"
          >
            <Icon>
              <Copy />
            </Icon>
            Copy Flow URL
          </Chip>
        </Item>
      )}
      {steps.map((step) => {
        const { completion } = step;
        return (
          <>
            <ListDivider hidden />
            {completion && (
              <CompletionViewer
                key={completion.id}
                completion={completion}
                commit={completion.promptCommit}
                promptParamsOpen={promptParamsOpen}
                renderActions={() => (
                  <>
                    <Chip
                      onClick={togglePromptParams}
                      title="View details"
                    >
                      <Icon class="scale-[0.90]">
                        <Tune />
                      </Icon>
                    </Chip>
                  </>
                )}
              />
            )}
          </>
        );
      })}
    </List>
  );
}
