import { array, enums, Infer, object, string } from "local/deps/superstruct.ts";

/** MAIN **/

export type FlowCommit = Infer<typeof FlowCommit>;

export const FlowCommit = object({
  flowSnapshot: object({
    name: string(),
    steps: array(object({
      id: string(),
      type: enums([
        "prompt-commits",
        "flow-commits",
      ]),
    })),
  }),
});
