import { createQuery, fields } from "app/utils/graphql.ts";
import { array, nullable, object, string } from "local/deps/superstruct.ts";

export const FlowCommit = object({
  id: string(),
  workflows: nullable(
    array(
      nullable(
        object({
          url: string(),
        }),
      ),
    ),
  ),
});

/** MAIN **/
export const query = createQuery({
  name: "getFlowCommit",
  output: nullable(FlowCommit),
  vars: object({
    id: string(),
  }),
  body: `
    query ($id: ID!) {
      getFlowCommit(id: $id) {
        ${fields(FlowCommit)}
      }
    }
  `,
});
