import {
  enums,
  Infer,
  integer,
  nullable,
  object,
  string,
} from "local/deps/superstruct.ts";
import { Completion } from "./completion.ts";
import { PromptCommit } from "./prompt_commit.ts";

/** MAIN **/

export type FlowRunnerStep = Infer<typeof FlowRunnerStep>;

export const FlowRunnerStep = object({
  id: string(),
  index: integer(),
  status: enums(["pending", "ready", "running", "success", "error"]),
  name: string(),
  completion: nullable(
    object({
      ...Completion.schema,
      promptCommit: PromptCommit,
    }),
  ),
});
