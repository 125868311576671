import { Node, render, Theme } from "local/markdown/mod.ts";
import { createContext, VNode } from "preact";
import { useContext } from "preact/hooks";

/** MAIN **/

export type MarkdownContext = {
  theme: Theme<VNode>;
  slots: Record<string, VNode>;
};

export const MarkdownContext = createContext<MarkdownContext | null>(null);

export function useMarkdown(): MarkdownContext {
  const context = useContext(MarkdownContext);
  if (!context) {
    throw new Error("MarkdownContext not found");
  }
  return context;
}

export function useMarkdownRenderer() {
  const { theme } = useMarkdown();
  return (node: Node) => render(node, { theme });
}
