import { Effect } from "./types.ts";

/** MAIN **/

export function parseEffect(input: string): Effect {
  const lines = input.trim().split("\n");
  const [description, ...rest] = lines;
  const headerIndex = rest.findLastIndex((line) => {
    return line.toLowerCase().match(/^layer/);
  });
  const frameLines = rest.slice(headerIndex + 1);
  const frames = frameLines.map((line) => {
    const [layer, color, durationStr] = line
      .trim()
      .split(/,/)
      .map((x) => x.trim());
    const duration = parseInt(durationStr, 10);
    return {
      layer,
      color,
      duration: duration / 1000,
    };
  });
  return { description, frames };
}
