import { ApiKey } from "app/features/stores/types/api_key.ts";
import { Copy } from "icons/copy.tsx";
import { Key } from "icons/key.tsx";
import { Trash } from "icons/trash.tsx";
import { useCallback } from "../../../../packages/deps/preact/hooks.ts";
import { Age } from "../../../../packages/ui/age.tsx";
import { Chip } from "../../../../packages/ui/chip.tsx";
import { Icon } from "../../../../packages/ui/icon.tsx";
import { Item } from "../../../../packages/ui/item.tsx";

export interface UserApiKeyItemProps {
  apiKey: ApiKey;
  onTrash: () => void;
}

export function UserApiKeyItem(props: UserApiKeyItemProps) {
  const { apiKey, onTrash } = props;

  const copySecret = useCallback(() => {
    navigator.clipboard.writeText(apiKey.secret);
  }, [apiKey.secret]);

  return (
    <Item class="border-1 border-gray-500">
      <Icon size={8}>
        <Key />
      </Icon>
      <div class="flex flex-col flex-1">
        <p class="font-mono">
          {apiKey.secret}
        </p>
      </div>
      <p class="text-xs text-gray-500">
        generated <Age time={new Date(apiKey.createdAt)} />
      </p>
      <Chip
        title="Revoke"
        onClick={onTrash}
      >
        <Icon>
          <Trash />
        </Icon>
      </Chip>

      <Chip
        title="Copy Secret"
        onClick={copySecret}
      >
        <Icon>
          <Copy />
        </Icon>
      </Chip>
    </Item>
  );
}
