import { Signal, useSignalEffect } from "local/deps/preact/signals.ts";

/** MAIN **/

export function useFocus<T extends HTMLElement>(
  element: Signal<T | null>,
  focused: Signal<boolean>,
) {
  useSignalEffect(() => {
    const el = element.value;
    if (!el) return;

    const onFocus = () => {
      focused.value = true;
    };

    const onBlur = () => {
      focused.value = false;
    };

    el.addEventListener("focus", onFocus);
    el.addEventListener("blur", onBlur);

    return () => {
      el.removeEventListener("focus", onFocus);
      el.removeEventListener("blur", onBlur);
    };
  });
}
