import { Signal, useSignalEffect } from "local/deps/preact/signals.ts";

/** MAIN **/

export function useHover<T extends HTMLElement>(
  element: Signal<T | null>,
  hovered: Signal<boolean>,
) {
  useSignalEffect(() => {
    const el = element.value;
    if (!el) return;

    const onMouseEnter = () => {
      hovered.value = true;
    };

    const onMouseLeave = () => {
      hovered.value = false;
    };

    el.addEventListener("mouseenter", onMouseEnter);
    el.addEventListener("mouseleave", onMouseLeave);

    return () => {
      el.removeEventListener("mouseenter", onMouseEnter);
      el.removeEventListener("mouseleave", onMouseLeave);
    };
  });
}
