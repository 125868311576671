import { useTheme } from "app/hooks/use_theme.ts";
import { useEffect, useMemo, useState } from "local/deps/preact/hooks.ts";
import { useComputed, useSignal } from "local/deps/preact/signals.ts";
import { View } from "local/ui/view.tsx";

/** HELPERS **/

type Color = [number, number, number];

function lerp(t: number, a: number, b: number) {
  return a + t * (b - a);
}

function lerpColor(t: number, a: Color, b: Color) {
  return [
    lerp(t, a[0], b[0]),
    lerp(t, a[1], b[1]),
    lerp(t, a[2], b[2]),
  ];
}

function uniform(a: number, b: number) {
  return lerp(Math.random(), a, b);
}

function clamp(x: number, a: number, b: number) {
  return Math.min(Math.max(x, a), b);
}

function uniformInt(a: number, b: number) {
  return Math.floor(uniform(a, b));
}

function EllipsisLayer(props: {
  width: number;
  height: number;
  index: number;
  count: number;
  seed: number;
  radius: number;
}) {
  const { width, height, index, count, seed } = props;

  const theme = useTheme();

  const children = useMemo(() => {
    const leaves = uniformInt(2, 64);
    const petals = new Array(leaves).fill(0).map((_, i) => i);

    const minRadius = 120;
    const maxRadius = Math.max(
      Math.min(width, height) / 2 * props.radius,
      minRadius + 100,
    );
    const innerRadius = uniform(minRadius, maxRadius);
    const outerRadius = uniform(innerRadius, maxRadius);
    const centerRadius = (innerRadius + outerRadius) / 2;

    const radialRadius = (outerRadius - innerRadius) / 2;

    const minAngularRadius = 0;
    const maxAngularRadius = Math.min(centerRadius * Math.PI / leaves, 5);
    const angularRadius = uniform(minAngularRadius, maxAngularRadius);

    const smallerRadius = Math.min(radialRadius, angularRadius);
    const largerRadius = Math.max(radialRadius, angularRadius);
    const aspectRatio = largerRadius / smallerRadius;
    const forceRound = aspectRatio < 3.5;

    const radii = {
      radial: forceRound ? smallerRadius : radialRadius,
      angular: forceRound ? smallerRadius : angularRadius,
    };

    const dark = theme.name === "dark";
    const darkColor: Color = [31, 41, 55];
    const lightColor: Color = [229, 231, 235];
    const opacity = dark ? index / count : 1 - index / count;

    const color = lerpColor(opacity, darkColor, lightColor);

    return petals.map((i) => {
      const angle = (i / leaves) * Math.PI * 2;
      const x = Math.cos(angle) * centerRadius;
      const y = Math.sin(angle) * centerRadius;
      const tilt = angle * (180 / Math.PI) - 90;
      const transform = `translate(${x}, ${y}) rotate(${tilt})`;
      return (
        <g
          key={i}
          transform={transform}
        >
          <ellipse
            key={i}
            rx={radii.angular}
            ry={radii.radial}
            fill={`rgb(${color.join(",")})`}
          />
        </g>
      );
    });
  }, [width, height, theme.name, seed]);

  const transform = `rotate(${uniform(0, 360)})`;

  return (
    <View tag="g" transform={transform}>
      {children}
    </View>
  );
}

/** MAIN **/

export function Lily(props: {
  radius?: number;
  open?: boolean;
}) {
  const { radius = 1 } = props;

  const [open, setOpen] = useState(false);
  const [seed, setSeed] = useState(0);
  const [transitioning, setTransitioning] = useState(true);
  const [opacity, setOpacity] = useState(0);

  const rect = useSignal<DOMRect | undefined>(undefined);
  const { width, height } = rect.value || { width: 0, height: 0 };
  const center = { x: width / 2, y: height / 2 };

  useEffect(() => {
    setOpen(props.open ?? false);
    setOpacity(1);
    setTimeout(() => {
      setTransitioning(false);
    });
  }, [props.open]);

  function random() {
    setTransitioning(true);
    setOpacity(0);
    setTimeout(() => {
      setSeed((c) => c + 1);
      setOpacity(1);
      setTimeout(() => {
        setTransitioning(false);
      }, 500);
    }, 500);
  }

  const layers = useMemo(() => {
    const count = uniformInt(3, 8);
    return new Array(count).fill(0).map((_, i) => ({
      index: i,
      children: (
        <EllipsisLayer
          radius={radius}
          seed={seed}
          width={width}
          height={height}
          index={i}
          count={count}
        />
      ),
    }));
  }, [seed, width, height]);

  const start = useMemo(() => Date.now(), []);
  const time = useSignal(0);

  useEffect(() => {
    let running = true;
    const handler = () => {
      if (!running) return;
      time.value = Date.now() - start;
      requestAnimationFrame(handler);
    };
    handler();
    return () => {
      running = false;
    };
  }, []);

  const children = useComputed(() => {
    const t = time.value / 1000;
    const count = layers.length;
    return layers.map((layer, i) => {
      const direction = Math.pow(-1, i % 2);
      const speed = 4 / ((layers.length - i) + 1);
      const offset = direction * t * speed;
      const radius = 0.75 +
        Math.sin(t * Math.pow((i + 1) / count, 2) / 10) * 0.25;

      return (
        <View
          tag="g"
          key={layer.index}
          transform={`rotate(${offset}) scale(${radius})`}
        >
          {layer.children}
        </View>
      );
    });
  });

  return (
    <View
      onClick={random}
      class={[
        "absolute inset-0 flex flex-col items-center justify-center",
        "text-black dark:text-white",
        "cursor-pointer",
        (transitioning || !open) && "pointer-events-none",
        open ? "opacity-100" : "opacity-0",
        "select-none",
        "transition-opacity",
        "bg-gradient-to-br from-gray-100 to-gray-200 dark:(from-gray-900 to-gray-800)",
      ]}
      rect={rect}
    >
      <View
        tag="svg"
        class={[
          "absolute inset-0",
        ]}
        style={{
          transition: "opacity 500ms, transform 500ms",
          transform: `scale(${open ? opacity : 0})`,
          opacity: String(opacity),
        }}
        width={width}
        height={height}
      >
        <g transform={`translate(${center.x}, ${center.y})`}>
          {children.value}
        </g>
      </View>
      <View
        class={[
          "flex flex-col items-center justify-center relative z-[20]",
          !open && "opacity-0",
          "transition-opacity duration-500",
        ]}
      >
        <View
          tag="h1"
          class="text-2xl text-gray-900 dark:text-gray-100"
        >
          Flowery<b class="text-3xl">AI</b>
        </View>
      </View>
    </View>
  );
}
