import { Danger } from "icons/danger.tsx";
import { MathPlus } from "icons/math_plus.tsx";
import { SpinnerTwo } from "icons/spinner_two.tsx";
import { Chip } from "local/ui/chip.tsx";
import { Icon } from "local/ui/icon.tsx";
import { Item } from "local/ui/item.tsx";
import { Kbd } from "local/ui/kbd.tsx";
import { List } from "local/ui/list.tsx";
import { View } from "local/ui/view.tsx";
import { useKeyEditor } from "../hooks/use_key_editor.ts";
import { useValidation } from "../hooks/use_key_validation.ts";

/** HELPERS **/

export function KeyEditor(props: {
  onSubmit?: () => void;
}) {
  const { onSubmit } = props;

  const editor = useKeyEditor({
    onSubmit: () => {
      editor.clear();
      onSubmit?.();
    },
  });

  const { draft, submitting, submit } = editor;
  const { secret } = draft;

  const validation = useValidation(secret);
  const isValid = validation.value.valid;
  const isEmpty = secret.value.length === 0;
  const showError = !isEmpty && !isValid;
  const isSubmitting = submitting.value;
  const isSubmitDisabled = isSubmitting || !isValid || isEmpty;

  const color = (() => {
    if (isEmpty) return "yellow-500";
    if (isValid) return "green-500";
    return "gray-500";
  })();

  return (
    <List>
      <Item>
        <View
          tag="input"
          placeholder="Paste your OpenAI API key here"
          value={secret.value}
          onInput={(e) => {
            const target = e.currentTarget as HTMLInputElement;
            secret.value = target.value;
          }}
          onKeyDown={(e: KeyboardEvent) => {
            if (e.key === "Enter" && validation.value.valid) {
              submit();
            }
          }}
          minLength={validation.value.minLength}
          maxLength={validation.value.maxLength}
          // @ts-ignore - Preact doesn't know about spellcheck
          spellCheck="false"
          class={[
            "rounded-lg border-1",
            "absolute inset-0",
            "font-mono text-xs",
            "p-4",
            "bg-transparent",
            "rounded",
            "focus:(outline-none)",
            `border-${color}`,
            `placeholder-${color}`,
          ]}
        />
      </Item>
      <Item style={{ paddingLeft: "8px" }}>
        <Chip
          color={"red-500"}
          class={[
            !showError && "opacity-0 pointer-events-none",
          ]}
        >
          <Icon>
            <Danger />
          </Icon>
          {validation.value.error ?? "Unknown error"}
        </Chip>
        <View class="flex-1" />
        <Chip
          onClick={submit}
          disabled={isSubmitDisabled}
          color="green-500"
          class={[
            !isValid && "opacity-0 pointer-events-none",
          ]}
        >
          Upload API Key
          <Kbd color="green-500">⏎</Kbd>
          <Icon
            class={[
              isSubmitting && "animate-spin",
            ]}
          >
            {isSubmitting ? <SpinnerTwo /> : <MathPlus />}
          </Icon>
        </Chip>
      </Item>
    </List>
  );
}
