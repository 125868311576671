import { useQuery } from "app/hooks/graphql.ts";
import { createMutation, createQuery, fields } from "app/utils/graphql.ts";
import {
  array,
  enums,
  object,
  string,
  unknown,
} from "local/deps/superstruct.ts";
import { createRepo, Repo } from "local/repos/mod.ts";
import { Flow } from "../types/mod.ts";

/** HELPERS **/

const listQuery = createQuery({
  name: "listFlows",
  output: array(Flow),
  body: `
    query {
      listFlows {
        ${fields(Flow)}
      }
    }
  `,
});

const createMutations = createMutation({
  name: "createFlow",
  vars: object({
    input: object({
      name: string(),
      steps: array(
        object({
          name: string(),
          id: string(),
          type: enums(["prompts", "flows", "empty"]),
        }),
      ),
    }),
  }),
  output: Flow,
  body: `
    mutation ($input: CreateFlowInput!) {
      createFlow(input: $input) {
        ${fields(Flow)}
      }
    }
  `,
});

const updateMutation = createMutation({
  name: "updateFlow",
  vars: object({
    input: Flow,
  }),
  output: unknown(),
  body: `
    mutation ($input: UpdateFlowInput!) {
      updateFlow(input: $input) {
        id
      }
    }
  `,
});

const deleteMutation = createMutation({
  name: "deleteFlow",
  vars: object({
    id: string(),
  }),
  output: unknown(),
  body: `
    mutation ($id: ID!) {
      deleteFlow(id: $id) {
        id
      }
    }
  `,
});

/** MAIN **/

let repo: Repo<Flow>;

export function useFlowRepo() {
  const exec = useQuery();
  if (repo) return repo;

  repo = createRepo({
    create: (item) => {
      return exec(createMutations, {
        input: {
          name: item.name,
          steps: item.steps,
        },
      });
    },
    update: async (data) => {
      await exec(updateMutation, { input: data });
    },
    remove: async (id) => {
      await exec(deleteMutation, { id });
    },
    equals: (a, b) => {
      if (a.id !== b.id) return false;
      if (a.name !== b.name) return false;
      if (a.steps.length !== b.steps.length) return false;
      for (let i = 0; i < a.steps.length; i++) {
        const stepA = a.steps[i];
        const stepB = b.steps[i];
        if (stepA.id !== stepB.id) return false;
        if (stepA.type !== stepB.type) return false;
        if (stepA.name !== stepB.name) return false;
      }
      return true;
    },
  });

  const { load, merge } = repo;

  load(async () => {
    merge(await exec(listQuery));
  });

  return repo;
}
