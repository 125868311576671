import { View, ViewNode } from "local/ui/view.tsx";
import { SavingWidget } from "./saving_widget.tsx";
import { ThemeWidget } from "./theme_widget.tsx";
import { UserWidget } from "./user_widget.tsx";

export function Toolbar(props: { children?: ViewNode }) {
  return (
    <View
      class={[
        "fixed top-0 right-0 bottom-0",
        "h-full w-12 flex flex-col relative z-20",
        "p-2 items-end gap-2",
        "bg-gray-100 dark:bg-gray-900",
      ]}
    >
      <UserWidget />
      <SavingWidget />
      <View class="flex-1" />
      {props.children}
      <View class="flex-1" />
      <ThemeWidget />
    </View>
  );
}
