import { useQuery } from "app/hooks/graphql.ts";
import { createMutation, createQuery, fields } from "app/utils/graphql.ts";
import {
  array,
  nullable,
  object,
  string,
  unknown,
} from "local/deps/superstruct.ts";
import * as Helpers from "local/repos/helpers.ts";
import { RepoStore } from "local/repos/mod.ts";
import { equalsTable, Table } from "../types/table.ts";

/** HELPERS **/

const CREATE = createMutation({
  name: "createTables",
  vars: object({
    input: array(object({
      name: string(),
      columns: array(object({
        name: string(),
      })),
    })),
  }),
  output: array(Table),
  body: `
    mutation ($input: [CreateTableInput!]!) {
      createTables(input: $input) {
        ${fields(Table)}
      }
    }
  `,
});

const LIST = createQuery({
  name: "listTables",
  vars: object({
    input: nullable(object({
      filter: object({
        tableIds: array(string()),
      }),
    })),
  }),
  output: array(Table),
  body: `
    query ($input: ListTablesInput) {
      listTables (input: $input) {
        ${fields(Table)}
      }
    }
  `,
});

const DELETE = createMutation({
  name: "deleteTables",
  vars: object({
    input: array(object({
      id: string(),
    })),
  }),
  output: unknown(),
  body: `
    mutation ($input: [DeleteTableInput!]!) {
      deleteTables(input: $input) {
        id
      }
    }
  `,
});

/** MAIN **/

let repo: {
  store: RepoStore<Table>;
  create: (item: Table) => Promise<Table>;
  remove: (id: string) => void;
};

export function useTableRepo() {
  const exec = useQuery();
  if (repo) return repo;

  const store = Helpers.store<Table>();

  const merge = Helpers.merge(store, {
    equals: equalsTable,
  });

  const createAll = Helpers.createAll(store, {
    handler: async (items) => {
      return await exec(CREATE, {
        input: items.map((item) => ({
          name: item.name,
          columns: item.columns,
        })),
      });
    },
  });

  const removeAll = Helpers.removeAll(store, {
    handler: async (ids: string[]) => {
      await exec(DELETE, {
        input: ids.map((id) => ({ id })),
      });
    },
  });

  const load = Helpers.load(store);

  load(async () => {
    merge(
      await exec(LIST, {
        input: null,
      }),
    );
  });

  repo = {
    store,
    create: Helpers.first(createAll),
    remove: Helpers.one(removeAll),
  };

  return repo;
}
