import { ReadonlySignal, useComputed } from "local/deps/preact/signals.ts";

/** MAIN **/

export type Validation = {
  valid: boolean;
  error?: string;
  minLength: number;
  maxLength: number;
};

export function useValidation(
  secret: ReadonlySignal<string>,
): ReadonlySignal<Validation> {
  const prefix = "sk-";
  const requiredLength = 48 + prefix.length;

  function error(message: string) {
    return {
      valid: false,
      error: message,
      minLength: requiredLength,
      maxLength: requiredLength,
    };
  }

  return useComputed(() => {
    const key = secret.value;
    if (!key.startsWith(prefix)) {
      return error(`must start with '${prefix}'`);
    }

    const payload = key.substring("sk-".length);
    if (!payload.match(/^[a-zA-Z0-9]*$/)) {
      return error(`invalid characters`);
    }

    if (key.length !== requiredLength) {
      return error(`must be ${requiredLength} characters long`);
    }

    return {
      valid: true,
      minLength: requiredLength,
      maxLength: requiredLength,
    };
  });
}
