import { FlowRunner } from "app/features/stores/mod.ts";
import { createQuery, fields } from "app/utils/graphql.ts";
import { object, string } from "local/deps/superstruct.ts";

/** MAIN **/
export const query = createQuery({
  name: "getFlowRunner",
  output: FlowRunner,
  vars: object({
    id: string(),
  }),
  body: `
    query ($id: ID!) {
      getFlowRunner(id: $id) {
        ${fields(FlowRunner)}
      }
    }
  `,
});
