import { NumberSliderInput } from "app/components/number-slider-input.tsx";
import { useSignal, useSignalEffect } from "local/deps/preact/signals.ts";
import { View } from "local/ui/view.tsx";
import { useCallback } from "local/deps/preact/hooks.ts";
import { Panel } from "local/ui/panel.tsx";
import { TextEditor } from "local/ui/text_editor.tsx";
import { usePrompt } from "app/features/stores/hooks/use_prompt.ts";
import { Prompt } from "app/features/stores/mod.ts";
import { tokenize } from "app/features/prompts/utils/tokenize.ts";

/** MAIN **/

const COMPLETION_MODELS = [
  "text-davinci-003",
  "text-curie-001",
  "text-babbage-001",
  "text-ada-001",
];

const CHAT_MODELS = [
  "gpt-4",
  "gpt-3.5-turbo",
];

const MODELS = [...COMPLETION_MODELS, ...CHAT_MODELS];

export interface PromptTunerProps {
  id: string;
}

export function PromptTuner(props: PromptTunerProps) {
  const { id } = props;

  const prompt = usePrompt(id);

  const parameters = useSignal<Prompt["parameters"] | undefined>(undefined);

  const stopSequences = useSignal<string | undefined>(undefined);

  const params = parameters.value;

  useSignalEffect(() => {
    const { data } = prompt.store.value;
    if (!data) return;

    parameters.value = data.parameters;
    stopSequences.value = data.parameters.stopSequences.join("\n");
  });

  useSignalEffect(() => {
    const { data } = prompt.store.value;
    const params = parameters.value;
    if (!data || !params) return;
    const sequences =
      stopSequences.value?.trim().split("\n").filter(Boolean).slice(0, 4) ||
      [];

    prompt.save({
      name: data.name,
      body: data.body,
      parameters: { ...params, stopSequences: sequences },
    });
  });

  const onChangeModel = useCallback((e: Event) => {
    if (!parameters.value) return;

    const target = e.target as HTMLSelectElement;
    const model = target.value;

    const mode = COMPLETION_MODELS.includes(model) ? "complete" : "chat";

    parameters.value = {
      ...parameters.value,
      model,
      mode,
    };
  }, [parameters.value]);

  return (
    <Panel
      reverseGradient
      header={
        <View tag="h2" class="text-2xl">
          Prompt Tuner
        </View>
      }
    >
      {params && (
        <View class="flex flex-col gap-10">
          <View>
            <View class="flex flex-col">
              <View class="text-sm mb-1">
                Model
              </View>
              <View
                title="The GPT model to use"
                tag="select"
                class="bg-transparent text-sm"
                onChange={onChangeModel}
                value={params.model}
                name="Models"
                id="Models"
                style={{
                  ".select-Views": "{background-color: red;}",
                }}
              >
                {[...MODELS].map((model) => (
                  <View class="text-sm" tag="option" value={model}>
                    {model}
                  </View>
                ))}
              </View>
            </View>
          </View>
          <View>
            <NumberSliderInput
              title="Max Tokens"
              tooltip="The maximum number of tokens to generate. One token ~= 4 characters in English."
              value={params.maxTokens}
              onInput={(maxTokens: number) => {
                parameters.value = {
                  ...params,
                  maxTokens,
                };
              }}
              lowerLimit={1}
              upperLimit={4096}
            />
          </View>
          <View>
            <NumberSliderInput
              title="Temperature"
              tooltip="The higher the temperature, the more random the completions. As the temperature approaches zero, the model will become deterministic and repetitive. Higher temperature results in more creative completions, but also more spelling mistakes."
              value={params.temperature}
              onInput={(temperature: number) => {
                parameters.value = {
                  ...params,
                  temperature,
                };
              }}
              lowerLimit={0}
              step={0.01}
              upperLimit={1}
            />
          </View>
          <View>
            <View
              title="Up to four sequences where the API will stop generating tokens. The returned text will not contain the stop sequence."
              class="w-full flex flex-col"
            >
              <View class="text-sm">
                Stop Sequences
              </View>
              <View class="mb-1 text-xs text-gray-500 italic">
                one sequence per line
              </View>
              <TextEditor
                value={stopSequences.value}
                onChange={(value) => {
                  stopSequences.value = value;
                }}
                tokenize={(value) =>
                  tokenize({
                    body: value ?? "",
                  })}
                parse={(text) => {
                  return text;
                }}
                stringify={(value) => {
                  return value ?? "";
                }}
                renderToken={(token) => (
                  <View tag="span">
                    {token.value}
                  </View>
                )}
              />
            </View>
          </View>
          <View>
            <NumberSliderInput
              title="Frequency Penalty"
              tooltip="How much to penalize new tokens based on their existing frequency in the text so far. Decreases the model's likelihood to repeat the same line verbatim."
              value={params.frequencyPenalty}
              onInput={(frequencyPenalty: number) => {
                parameters.value = {
                  ...params,
                  frequencyPenalty,
                };
              }}
              lowerLimit={0}
              step={0.01}
              upperLimit={2}
            />
          </View>
          <View>
            <NumberSliderInput
              title="Presence Penalty"
              tooltip="How much to penalize new tokens based on whether they appear in the text so far. Increase the model's likelihood to talk about new topics."
              value={params.presencePenalty}
              onInput={(presencePenalty: number) => {
                parameters.value = {
                  ...params,
                  presencePenalty,
                };
              }}
              lowerLimit={0}
              step={0.01}
              upperLimit={2}
            />
          </View>
        </View>
      )}
    </Panel>
  );
}
