import { useSignal } from "local/deps/preact/signals.ts";

/** MAIN **/

export function useDialog(props: {
  onClose?: () => void;
  onOpen?: () => void;
} = {}) {
  const { onClose, onOpen } = props;
  const opened = useSignal(false);

  function close() {
    opened.value = false;
    onClose?.();
  }

  function open() {
    opened.value = true;
    onOpen?.();
  }

  return {
    close,
    open,
    opened,
  };
}
