import { History } from "app/components/icons/history.tsx";
import { Input } from "app/components/icons/input.tsx";
import { PlayButton } from "app/components/icons/play_button.tsx";
import { useCallback, useEffect, useState } from "local/deps/preact/hooks.ts";
import {
  batch,
  useSignal,
  useSignalEffect,
} from "local/deps/preact/signals.ts";
import { Chip } from "local/ui/chip.tsx";
import { EditorPanel } from "local/ui/editor_panel.tsx";
import { Icon } from "local/ui/icon.tsx";
import { Item } from "local/ui/item.tsx";
import { List } from "local/ui/list.tsx";
import { Spinner } from "local/ui/spinner.tsx";
import { TextEditor } from "local/ui/text_editor.tsx";
import { View } from "local/ui/view.tsx";
import { Animated } from "../../../../packages/ui/animated.tsx";
import { usePrompt } from "../../stores/hooks/use_prompt.ts";
import { usePlay } from "../hooks/use_play.ts";
import { tokenize } from "../utils/tokenize.ts";
import { PromptText } from "./prompt_text.tsx";

const VARIABLE_PALETTE = [
  "#fd7f6f",
  "#7eb0d5",
  "#b2e061",
  "#bd7ebe",
  "#ffb55a",
  "#ffee65",
  "#beb9db",
  "#fdcce5",
  "#8bd3c7",
];

/** MAIN **/

export interface PromptEditorProps {
  id: string;
  onViewResults: () => void;
}

export function PromptEditor(props: PromptEditorProps) {
  const { id, onViewResults } = props;

  const prompt = usePrompt(id);

  const name = useSignal("");
  const body = useSignal("");

  const [completionLoading, setCompletionLoading] = useState(false);

  const [variableInputs, setVariableInputs] = useState<
    Record<string, string | undefined>
  >({});

  const [inputVariables, setInputVariables] = useState<string[]>([]);

  useSignalEffect(() => {
    const { data } = prompt.store.value;
    if (!data) return;
    batch(() => {
      name.value = data.name;
      body.value = data.body;
    });
  });

  useSignalEffect(() => {
    const { data } = prompt.store.value;
    if (!data) return;

    prompt.save({
      name: name.value,
      body: body.value,
      parameters: data.parameters,
    });
  });

  useEffect(() => {
    setVariableInputs(Object.fromEntries(inputVariables.map((v) => {
      return [v, variableInputs[v] ?? ""];
    })));
  }, [inputVariables, setVariableInputs]);

  const onAddInputVariable = useCallback(() => {
    body.value += `{{new variable}}`;
    setVariableInputs((prev) => ({
      ...prev,
      ["new variable"]: "",
    }));
  }, [setVariableInputs]);

  useEffect(() => {
    setInputVariables(
      [...tokenize({ body: body.value })].filter((t) => t.type === "variable")
        .map(
          (t) => t.value.replace("{{", "").replace("}}", ""),
        ),
    );
  }, [body.value, setInputVariables]);

  const play = usePlay();

  const playPrompt = useCallback(() => {
    setCompletionLoading(true);
    const { data } = prompt.store.value;
    if (!data) return;

    play({
      prompt: {
        id,
        name: name.value,
        body: body.value,
        parameters: data.parameters,
      },
      completionInput: {
        variables: inputVariables.map((v) => ({
          name: v,
          value: variableInputs[v] ?? "",
        })),
      },
    }).finally(() => setCompletionLoading(false));
  }, [
    name.value,
    body.value,
    id,
    inputVariables,
    variableInputs,
    setCompletionLoading,
  ]);

  return (
    <EditorPanel
      title={name.value}
      loading={prompt.store.value.status.isLoading}
      loadingMessage="Loading prompt..."
      onTitleChange={(value) => {
        name.value = value;
      }}
    >
      <List class="max-w-2xl w-full">
        <Item style={{ paddingLeft: "8px" }}>
          <Chip
            onClick={onAddInputVariable}
            color="blue-500"
            title="Add new variable"
          >
            <Icon>
              <Input />
            </Icon>
            Add new variable
          </Chip>
          <View class="flex-1" />
          <Chip
            title="View Results"
            onClick={onViewResults}
          >
            <Icon class="scale-[0.90]">
              <History />
            </Icon>
          </Chip>
          <Chip
            disabled={completionLoading}
            color="green-500"
            onClick={completionLoading ? undefined : playPrompt}
          >
            {completionLoading ? <Spinner /> : (
              <Icon>
                <PlayButton />
              </Icon>
            )}
          </Chip>
        </Item>
        <View class="mb-2 w-full">
          <Animated duration={200}>
            {Boolean(inputVariables.length) && (
              Array.from(new Set(inputVariables)).map((variable, index) => (
                <View class="w-full my-2">
                  <TextEditor
                    title={`Value for {{${variable}}}`}
                    borderColor={VARIABLE_PALETTE[
                      index % VARIABLE_PALETTE.length
                    ]}
                    value={variableInputs}
                    onChange={(value) => {
                      setVariableInputs({
                        ...value,
                        [variable]: value[variable],
                      });
                    }}
                    tokenize={(value) =>
                      tokenize({
                        body: value[variable] ?? "",
                      })}
                    parse={(text) => {
                      return { ...variableInputs, [variable]: text };
                    }}
                    stringify={(value) => {
                      return value[variable] ?? "";
                    }}
                    renderToken={(token) => (
                      <View tag="span">
                        {token.value}
                      </View>
                    )}
                    placeholder={
                      <View tag="span" class="text-gray-500">
                        Enter a value for &#123;&#123;{variable}&#125;&#125;
                      </View>
                    }
                  />
                </View>
              ))
            )}
          </Animated>
        </View>
        <PromptText state={body} />
      </List>
    </EditorPanel>
  );
}
