import { View, ViewProps } from "./view.tsx";

/** MAIN **/

export function Icon(
  props: ViewProps<"span"> & {
    size?: number;
  },
) {
  const { size } = props;
  const style = size ? `w-${size} h-${size}` : "w-5 h-5 -m-0.5";
  return (
    <View
      viewProps={props}
      tag="span"
      class={style}
    />
  );
}
