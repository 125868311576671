import { History } from "app/components/icons/history.tsx";
import { FlowEditor } from "app/features/flows/mod.ts";
import {
  FlowRunnerDetails,
  FlowRunnerList,
} from "app/features/flow_runners/mod.ts";
import { Toolbar } from "app/features/toolbar/mod.ts";
import { useEvent } from "app/hooks/event_bus.tsx";
import { useEffect } from "local/deps/preact/hooks.ts";
import { useSignal } from "local/deps/preact/signals.ts";
import { View } from "local/ui/view.tsx";
import { Chip } from "../../../packages/ui/chip.tsx";
import { Icon } from "../../../packages/ui/icon.tsx";

/** MAIN **/

const FOREGROUND = "z-10";

export function Editor(props: {
  flowId: string;
  flowRunnerId?: string;
}) {
  const contextBarOpen = useSignal<boolean>(false);
  const flowRunnerId = useSignal<string | null>(null);

  useEffect(() => {
    flowRunnerId.value = null;
  }, [props.flowId]);

  const onClickResults = () => {
    contextBarOpen.value = !contextBarOpen.value;

    if (!contextBarOpen.value) {
      flowRunnerId.value = null;
    }
  };

  useEvent("flow-runner-create", () => {
    contextBarOpen.value = true;
  });

  return (
    <View class="flex flex-1 relative">
      {flowRunnerId.value && (
        <View
          class={[
            FOREGROUND,
            "absolute w-full h-full dark:bg-gray-800 bg-gray-200",
          ]}
        >
          <FlowRunnerDetails
            flowRunnerId={flowRunnerId}
          />
        </View>
      )}
      <View class="absolute w-full h-full">
        <FlowEditor id={props.flowId} onViewResults={onClickResults} />
      </View>
      <View
        class={[
          FOREGROUND,
          "mr-12 w-[300px]",
          "fixed top-0 right-0 bottom-0",
          "border-r border-gray-200 dark:border-gray-800",
          "transition-all",
          !contextBarOpen.value &&
          "transform translate-x-full pointer-events-none",
        ]}
      >
        <FlowRunnerList
          flowId={props.flowId}
          flowRunnerId={flowRunnerId}
        />
      </View>
      <Toolbar>
        <Chip
          selected={contextBarOpen.value}
          onClick={onClickResults}
          title="Results"
        >
          <Icon>
            <History />
          </Icon>
        </Chip>
      </Toolbar>
    </View>
  );
}
