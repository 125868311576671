import { useEffect, useState } from "local/deps/preact/hooks.ts";

/** MAIN **/

export function useTheme() {
  const storageKey = "app/theme";

  const [name, setName] = useState(() => {
    const stored = globalThis.localStorage.getItem(storageKey);
    if (stored != null) return stored;
    return "dark";
  });

  useEffect(() => {
    globalThis.document.documentElement.classList.toggle(
      "dark",
      name === "dark",
    );
  }, [name]);

  return {
    name,
    toggle: () => {
      setName((name) => {
        const newName = name === "dark" ? "light" : "dark";
        globalThis.localStorage.setItem(storageKey, newName);

        return newName;
      });
    },
  };
}
