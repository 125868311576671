import { useRouter } from "app/hooks/use_router.ts";
import { MathPlus } from "icons/math_plus.tsx";
import { Signal } from "local/deps/preact/signals.ts";
import { Animated } from "local/ui/animated.tsx";
import { Chip } from "local/ui/chip.tsx";
import { Icon } from "local/ui/icon.tsx";
import { Item } from "local/ui/item.tsx";
import { List } from "local/ui/list.tsx";
import { Tag } from "local/ui/tag.tsx";
import { useStepItems } from "../hooks/use_step_items.ts";
import * as Step from "../types/step.ts";
import * as Steps from "../types/steps.ts";
import { StepItem } from "./step_item.tsx";

/** MAIN **/

export function StepList(props: {
  data: Signal<Steps.State>;
  variablePalette?: Signal<{ name: string; color: string }[]>;
}) {
  const { data, variablePalette } = props;

  const router = useRouter();
  const items = useStepItems(data);

  function onAdd() {
    data.value = Steps.append(data.value, Step.fromEmpty());
  }

  function onRemove(index: number) {
    data.value = Steps.remove(data.value, index);
  }

  function onDrop(index: number, dropped: string) {
    const route = router.match(dropped);

    if (!route) return;

    const { path, data: routeData } = route;

    if (path === "/prompt/:promptId") {
      const { promptId } = routeData;
      data.value = Steps.replace(
        data.value,
        index,
        Step.fromPromptId(promptId),
      );
    }

    if (path === "/flow/:flowId") {
      const { flowId } = routeData;
      data.value = Steps.replace(data.value, index, Step.fromFlowId(flowId));
    }
  }

  function onUpdateName(index: number, name: string) {
    const step = data.value[index];
    data.value = Steps.replace(data.value, index, { ...step, name });
  }

  const count = data.value.length;
  const isEmpty = count === 0;

  return (
    <Animated>
      <List
        class={[
          "rounded-md border-1",
          isEmpty
            ? "border-gray-300 dark:border-gray-700"
            : "border-transparent",
          "transition-all",
        ]}
      >
        <Item>
          <h2 class="flex-1 flex gap-2 items-center">
            Steps
            {!isEmpty && (
              <span>
                <Tag>{count}</Tag>
              </span>
            )}
          </h2>
          {onAdd && (
            <Chip title="Add a new step to the flow" onClick={onAdd}>
              <Icon>
                <MathPlus />
              </Icon>
            </Chip>
          )}
        </Item>
        {isEmpty && (
          <Item>
            <p class="text-gray-600 dark:text-gray-400 text-xs italic">
              Add steps to see them here.
            </p>
          </Item>
        )}
        {!isEmpty && items.value.map((item, index) => (
          <StepItem
            stepItem={item}
            index={index}
            onDrop={(data) => onDrop(index, data)}
            onTrash={() => onRemove(index)}
            onUpdateName={(name) => onUpdateName(index, name)}
            variablePalette={variablePalette}
          />
        ))}
      </List>
    </Animated>
  );
}
