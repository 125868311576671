/** MAIN **/

export type Token = {
  type: string;
  value: string;
};

export type State = {
  body: string;
  variables?: { name: string; value: string }[];
  output?: string | null;
};

export function variableNames(text: string): string[] {
  const iter = text.matchAll(/{{[\w\s]+}}/g);
  const matches = Array.from(iter).sort(
    (m1, m2) => (m1.index ?? 0) - (m2.index ?? 0),
  );
  return matches.map((m) => m[0].replace(/[{}]/g, ""));
}

export function* tokenize(state: State): IterableIterator<Token> {
  const { body, output, variables } = state;

  const iter = body?.matchAll(/{{[\w\s]+}}/g);
  const matches = Array.from(iter).sort(
    (m1, m2) => (m1.index ?? 0) - (m2.index ?? 0),
  );
  let currentIndex = 0;

  for (const match of matches) {
    const before = body.slice(currentIndex, match.index);
    const variableExpression = match[0];

    const variableName = variableExpression.replace(/[{}]/g, "");
    const variable = variables?.find((v) => v.name === variableName)?.value ??
      null;

    currentIndex = (match.index ?? 0) + variableExpression.length;
    yield { type: "text", value: before };
    yield {
      type: "variable",
      value: variable ?? match[0],
    };
  }

  const rest = body.slice(currentIndex, body.length);
  yield { type: "text", value: rest };

  if (output) {
    yield { type: "output", value: output };
  }
}

export function stringify(state: State): string {
  const tokens = [...tokenize(state)];
  return tokens.map((token) => token.value).join("");
}
