import { ViewNode } from "local/ui/view.tsx";
import { createRouter, Route, Router } from "./router.ts";

/** MAIN **/

export type RouteRenderer<T> = Route<T> & {
  render: (data: T) => ViewNode;
};

export type RouterRenderer = {
  router: Router;
  add: <T>(route: RouteRenderer<T>) => void;
  render: (url: URL) => ViewNode | null;
};

export function createRouterRenderer(root: URL): RouterRenderer {
  const router = createRouter(root);

  // deno-lint-ignore no-explicit-any
  const renderers = new Map<string, RouteRenderer<any>>();

  function add<T>(route: RouteRenderer<T>) {
    router.add(route);
    renderers.set(route.path, route);
  }

  function render(url: URL) {
    const match = router.match(url);
    if (!match) return null;
    const { path, data } = match;

    const renderer = renderers.get(path)!;
    return renderer.render(data);
  }

  return {
    router,
    add,
    render,
  };
}
