import * as Step from "./step.ts";

/** MAIN **/

export type State = Step.State[];

export function append(state: State, step: Step.State) {
  return [...state, step];
}

export function replace(state: State, index: number, step: Step.State) {
  return state.map((s, i) => {
    return i !== index ? s : step;
  });
}

export function remove(state: State, index: number) {
  return state.filter((_, i) => {
    return i !== index;
  });
}
