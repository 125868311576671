import { Restart } from "app/components/icons/restart.tsx";
import { Tune } from "app/components/icons/tune.tsx";
import {
  useCompletionRepo,
  usePromptCommitRepo,
} from "app/features/stores/mod.ts";
import { usePolling } from "app/hooks/use_polling.tsx";
import { useRepoItem } from "app/hooks/use_repo_item.ts";
import { Copy } from "icons/copy.tsx";
import { useCallback, useEffect, useState } from "local/deps/preact/hooks.ts";
import { Signal, useSignal } from "local/deps/preact/signals.ts";
import { Chip } from "local/ui/chip.tsx";
import { Icon } from "local/ui/icon.tsx";
import { Spinner } from "local/ui/spinner.tsx";
import { CompletionViewer } from "./completion_viewer.tsx";

/** MAIN **/

export interface CompletionViewProps {
  id: Signal<string | null>;
}

export function CompletionView(
  props: CompletionViewProps,
) {
  const { id } = props;

  const completionRepo = useCompletionRepo();
  const completionItem = useRepoItem(completionRepo, id.value ?? "");
  const completion = completionItem.store.value.data;

  const commitRepo = usePromptCommitRepo();
  const commitItem = useRepoItem(commitRepo, completion?.promptCommitId ?? "");
  const commit = commitItem.store.value.data;

  const [running, setRunning] = useState(false);

  const promptParamsOpen = useSignal(false);

  const isProcessingCompletion = completion?.status === "running" ||
    completion?.status === "pending";

  const polling = usePolling(
    () => {
      if (id.value) {
        completionRepo.loadById(id.value);
      }
    },
    1000,
  );

  useEffect(() => {
    if (isProcessingCompletion) {
      polling.start();
    } else {
      polling.stop();
    }
    return () => polling.stop();
  }, [isProcessingCompletion, polling]);

  const togglePromptParams = useCallback(() => {
    promptParamsOpen.value = !promptParamsOpen.value;
  }, []);

  const copyOutput = useCallback(() => {
    const output = completion?.output;
    if (!output) return;
    navigator.clipboard.writeText(output);
  }, [completion?.output]);

  const rerun = useCallback(() => {
    const promptCommitId = completion?.promptCommitId;
    if (promptCommitId == null) return;
    setRunning(true);

    completionRepo.fromPromptCommit(
      promptCommitId,
      completion?.variables ?? undefined,
    ).finally(() => {
      setRunning(false);
    });
  }, [completion?.promptCommitId, completion?.variables]);

  if (!completion || !commit) return null;

  return (
    <CompletionViewer
      completion={completion}
      commit={commit}
      promptParamsOpen={promptParamsOpen}
      renderActions={() => (
        <>
          <Chip
            onClick={togglePromptParams}
            title="View details"
          >
            <Icon class="scale-[0.90]">
              <Tune />
            </Icon>
          </Chip>
          <Chip
            onClick={copyOutput}
            title="Copy output to clipboard"
          >
            <Icon class="scale-[0.90]">
              <Copy />
            </Icon>
          </Chip>
          <Chip
            color="green-500"
            onClick={running ? undefined : rerun}
            title="Run again"
          >
            {running ? <Spinner /> : (
              <Icon>
                <Restart />
              </Icon>
            )}
          </Chip>
        </>
      )}
    />
  );
}
