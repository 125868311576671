import { useQuery } from "app/hooks/graphql.ts";
import { createMutation, fields } from "app/utils/graphql.ts";
import { array, object, string, unknown } from "local/deps/superstruct.ts";
import * as Helpers from "local/repos/helpers.ts";
import { RepoStore } from "local/repos/mod.ts";
import { TableRow } from "../types/table_row.ts";

/** HELPERS **/

const CREATE = createMutation({
  name: "createTableRows",
  vars: object({
    input: array(object({
      tableId: string(),
      cells: array(object({
        name: string(),
        data: string(),
      })),
    })),
  }),
  output: array(TableRow),
  body: `
    mutation ($input: [CreateTableRowInput!]!) {
      createTableRows(input: $input) {
        ${fields(TableRow)}
      }
    }
  `,
});

const DELETE = createMutation({
  name: "deleteTableRows",
  vars: object({
    input: array(object({
      id: string(),
    })),
  }),
  output: unknown(),
  body: `
    mutation ($input: [DeleteTableRowInput!]!) {
      deleteTableRows(input: $input) {
        id
      }
    }
  `,
});

/** MAIN **/

let repo: {
  store: RepoStore<TableRow>;
  create: (item: TableRow) => Promise<TableRow>;
  createAll: (items: TableRow[]) => Promise<TableRow[]>;
  remove: (id: string) => void;
  load: (fn: () => Promise<TableRow[]>) => Promise<TableRow[]>;
};

export function useTableRowRepo() {
  const exec = useQuery();
  if (repo) return repo;

  const store = Helpers.store<TableRow>();

  const createAll = Helpers.createAll(store, {
    handler: async (items) => {
      return await exec(CREATE, {
        input: items.map((item) => ({
          tableId: item.tableId,
          cells: item.cells,
        })),
      });
    },
  });

  const removeAll = Helpers.removeAll(store, {
    handler: async (ids: string[]) => {
      await exec(DELETE, {
        input: ids.map((id) => ({ id })),
      });
    },
  });

  const load = Helpers.load(store);

  repo = {
    store,
    load,
    createAll,
    create: Helpers.first(createAll),
    remove: Helpers.one(removeAll),
  };

  return repo;
}
