import { createMutation } from "app/utils/graphql.ts";
import { array, enums, object, string } from "local/deps/superstruct.ts";

/** HELPERS **/

const StepType = enums(["prompt-commits", "flow-commits", "empty"]);

const Step = object({
  id: string(),
  type: StepType,
  name: string(),
});

export const query = createMutation({
  name: "createFlowCommit",
  vars: object({
    input: object({
      message: string(),
      flow: object({
        id: string(),
        name: string(),
        steps: array(Step),
      }),
    }),
  }),
  output: object({
    id: string(),
  }),
  body: `
    mutation ($input: CreateFlowCommitInput!) {
      createFlowCommit(input: $input) {
        id
      }
    }
  `,
});
