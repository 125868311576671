import { MarkdownContext, Theme } from "local/markdown/mod.ts";
import { Block } from "local/text/block.tsx";
import { Blockquote } from "local/text/blockquote.tsx";
import { Break } from "local/text/break.tsx";
import { CodeBlock } from "local/text/code_block.tsx";
import { Delete } from "local/text/delete.tsx";
import { Emphasis } from "local/text/emphasis.tsx";
import { Heading } from "local/text/heading.tsx";
import { Image } from "local/text/image.tsx";
import { InlineCode } from "local/text/inline_code.tsx";
import { Link } from "local/text/link.tsx";
import { List } from "local/text/list.tsx";
import { ListItem } from "local/text/list_item.tsx";
import { Paragraph } from "local/text/paragraph.tsx";
import { Strong } from "local/text/strong.tsx";
import { ThematicBreak } from "local/text/thematic_break.tsx";
import { ComponentChildren, VNode } from "preact";
import { useMarkdownRenderer } from "./context.ts";

/** HELPERS **/

function Unknown(props: {
  type: string;
}) {
  const { type } = props;
  return (
    <div style="color: red; text-decoration: underline;">
      Unknown node type: {type}
    </div>
  );
}

export const DefaultTheme: Theme<VNode> = {
  Root: function (props) {
    const { blocks } = props;
    const render = useMarkdownRenderer();
    return <>{blocks.map(render)}</>;
  },
  Block: function (props) {
    const { child } = props;
    const render = useMarkdownRenderer();
    return <Block>{render(child as never)}</Block>;
  },
  Blockquote: function (props) {
    const { children } = props;
    const render = useMarkdownRenderer();
    return <Blockquote>{children.map(render)}</Blockquote>;
  },
  Break: function (_) {
    return <Break />;
  },
  CodeBlock: function (props) {
    return <CodeBlock>{props.value}</CodeBlock>;
  },
  Delete: function (props) {
    const { children } = props;
    const render = useMarkdownRenderer();
    return <Delete>{children.map(render)}</Delete>;
  },
  Emphasis: function (props) {
    const { children } = props;
    const render = useMarkdownRenderer();
    return <Emphasis>{children.map(render)}</Emphasis>;
  },
  FootnoteReference: function (props) {
    const { identifier } = props;
    const url = "#" + identifier;
    const label = "[" + identifier + "]";
    return <Link href={url}>{label}</Link>;
  },
  Heading: function (props) {
    const { id, depth, children, blockIndex } = props;
    const render = useMarkdownRenderer();
    return (
      <Heading
        id={id}
        blockIndex={blockIndex}
        depth={depth}
      >
        {children.map(render)}
      </Heading>
    );
  },
  Html: function (props) {
    const { value } = props;
    return (
      <div
        dangerouslySetInnerHTML={{ __html: value }}
      />
    );
  },
  Slot: function (props) {
    return <>{props.value}</>;
  },
  Image: function (props) {
    const { url, title, alt } = props;
    return (
      <Image
        src={url}
        alt={alt ?? undefined}
        title={title ?? undefined}
      />
    );
  },
  InlineCode: function (props) {
    const { value } = props;
    return <InlineCode>{value}</InlineCode>;
  },
  Link: function (props) {
    const { url, children } = props;
    const render = useMarkdownRenderer();
    return <Link href={url}>{children.map(render)}</Link>;
  },
  LinkReference: function (props) {
    const { identifier, children } = props;
    const render = useMarkdownRenderer();
    return <Link href={identifier}>{children.map(render)}</Link>;
  },
  List: function (props) {
    const { children, ordered } = props;
    const render = useMarkdownRenderer();
    return <List ordered={ordered ?? undefined}>{children.map(render)}</List>;
  },
  ListItem: function (props) {
    const { children } = props;
    const render = useMarkdownRenderer();
    return <ListItem>{children.map(render)}</ListItem>;
  },
  Paragraph: function (props) {
    const { children } = props;
    const render = useMarkdownRenderer();
    return <Paragraph>{children.map(render)}</Paragraph>;
  },
  Strong: function (props) {
    const { children } = props;
    const render = useMarkdownRenderer();
    return <Strong>{children.map(render)}</Strong>;
  },
  Text: function (props) {
    const { value } = props;
    return <>{value}</>;
  },
  ThematicBreak: function (_) {
    return <ThematicBreak />;
  },

  // TODO: implement these
  Definition: function (_) {
    return <Unknown type="Definition" />;
  },
  Footnote: function (_) {
    return <Unknown type="Footnote" />;
  },
  FootnoteDefinition: function (_) {
    return <Unknown type="FootnoteDefinition" />;
  },
  ImageReference: function (_) {
    return <Unknown type="ImageReference" />;
  },
  Table: function (_) {
    return <Unknown type="Table" />;
  },
  TableRow: function (_) {
    return <Unknown type="TableRow" />;
  },
  TableCell: function (_) {
    return <Unknown type="TableCell" />;
  },
  Yaml: function (_) {
    return <Unknown type="Yaml" />;
  },
};

export function ThemeProvider(props: {
  children: ComponentChildren;
  theme?: Partial<Theme<VNode>>;
}) {
  const { theme, children } = props;
  return (
    <MarkdownContext.Provider
      value={{
        slots: {},
        theme: {
          ...DefaultTheme,
          ...theme,
        },
      }}
    >
      {children}
    </MarkdownContext.Provider>
  );
}
