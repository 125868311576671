import { useQuery } from "app/hooks/graphql.ts";
import { createMutation, createQuery, fields } from "app/utils/graphql.ts";
import { useMemo } from "local/deps/preact/hooks.ts";
import { computed } from "local/deps/preact/signals.ts";
import {
  array,
  nullable,
  object,
  string,
  unknown,
} from "local/deps/superstruct.ts";
import { useValueStore } from "local/hooks/use_value_store.ts";
import * as Helpers from "local/repos/helpers.ts";
import { equalsTable, Table } from "../types/table.ts";
import { TableRow } from "../types/table_row.ts";
import { useTableRepo } from "./use_table_repo.ts";
import { useTableRowRepo } from "./use_table_row_repo.ts";

/** HELPERS **/

const UPDATE = createMutation({
  name: "updateTables",
  vars: object({
    input: array(object({
      id: string(),
      name: string(),
      columns: array(object({
        name: string(),
      })),
    })),
  }),
  output: unknown(),
  body: `
    mutation ($input: [UpdateTableInput!]!) {
      updateTables(input: $input) {
        id
      }
    }
  `,
});

const LOAD_ROWS = createQuery({
  name: "listTableRows",
  vars: object({
    input: object({
      tableId: string(),
      tableRowIds: nullable(array(string())),
    }),
  }),
  output: array(TableRow),
  body: `
    query ($input: ListTableRowsInput!) {
      listTableRows (input: $input) {
        ${fields(TableRow)}
      }
    }
  `,
});

/** MAIN **/

export function useTable(initialData: Table) {
  const id = useValueStore(initialData.id);

  const repo = useTableRepo();
  const tableRowRepo = useTableRowRepo();
  const exec = useQuery();

  return useMemo(() => {
    const store = Helpers.select(repo.store, id);

    const update = Helpers.update(repo.store, {
      equals: equalsTable,
      handler: (items) => {
        return exec(UPDATE, {
          input: items.map((item) => ({
            id: item.id,
            name: item.name,
            columns: item.columns,
          })),
        });
      },
    });

    const rows = computed(() => {
      const currentId = id.value;
      return {
        data: tableRowRepo.store.value.data
          .filter((row) => row.tableId === currentId),
        loading: tableRowRepo.store.value.pending > 0,
      };
    });

    const loadRows = () => {
      return tableRowRepo.load(() =>
        exec(LOAD_ROWS, {
          input: {
            tableId: id.value,
            tableRowIds: null,
          },
        })
      );
    };

    return {
      store,
      rows,
      loadRows,
      update: (fn: (draft: Table) => void) => update(store, fn),
    };
  }, []);
}
