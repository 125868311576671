import { View, ViewProps } from "local/ui/view.tsx";

/** MAIN **/

export function SidebarItem(
  props: ViewProps & {
    selected?: boolean;
  },
) {
  const { selected, ...viewProps } = props;
  return (
    <View
      viewProps={viewProps}
      class={[
        "relative",
        "flex flex-col items-center justify-center w-16 h-16 gap-1",
        "rounded-xl",
        "cursor-pointer",
        selected && "bg-gray-200 dark:bg-gray-800",
        "hover:(bg-gray-200 dark:bg-gray-800)",
        "text-gray-900 dark:text-gray-100",
        "transition-colors",
        "text-xs",
        "focus:(outline-none bg-gray-200 dark:bg-gray-800)",
      ]}
    />
  );
}
