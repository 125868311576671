import { Icon } from "local/ui/icon.tsx";
import { View, ViewNode } from "local/ui/view.tsx";

/** HELPERS **/

function Indicator(props: {
  color: string;
}) {
  const { color } = props;
  return (
    <View
      class={[
        "absolute top-2 right-2",
        "w-2 h-2",
        "rounded-full",
        `bg-${color}-500`,
      ]}
    />
  );
}

/** MAIN **/

export type SidebarStatus = "warning" | "error";

export function SidebarGlyph(props: {
  label: string;
  icon: ViewNode;
  statusColor?: string;
  iconSize?: number;
}) {
  const { label, icon, statusColor, iconSize = 6 } = props;

  return (
    <>
      {statusColor && <Indicator color={statusColor} />}
      <Icon size={iconSize}>
        {icon}
      </Icon>
      {label}
    </>
  );
}
