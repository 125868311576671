import { nanoid } from "local/deps/nanoid.ts";
import { createContext } from "local/deps/preact.ts";
import { useContext } from "local/deps/preact/hooks.ts";
import { Signal, useComputed } from "local/deps/preact/signals.ts";

/** MAIN **/

export type GuardContext = {
  pending: Map<string, Promise<unknown>>;
  hasPending: Signal<boolean>;
};

export const GuardContext = createContext<GuardContext | undefined>(
  undefined,
);

export function usePromiseGuard() {
  const context = useContext(GuardContext);
  if (!context) {
    throw new Error("use must be used within a Provider");
  }
  return (promise: Promise<unknown>) => {
    const id = nanoid();
    context.pending.set(id, promise);
    context.hasPending.value = true;
    promise.finally(() => {
      context.pending.delete(id);
      context.hasPending.value = context.pending.size > 0;
    });
  };
}

export function useGuardStatus() {
  const context = useContext(GuardContext);
  if (!context) {
    throw new Error("use must be used within a Provider");
  }
  return useComputed(() => {
    return {
      hasPending: context.hasPending.value,
    };
  });
}
