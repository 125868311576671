import { Toolbar } from "app/features/toolbar/mod.ts";
import { View } from "local/ui/view.tsx";

/** MAIN **/

export function Dashboard() {
  return (
    <View>
      <Toolbar />
    </View>
  );
}
