import { App } from "app/features/app/mod.ts";
import { Home } from "app/features/home/mod.ts";
import * as web from "local/web/client.ts";

/** MAIN **/

if (globalThis.document) {
  web.initTwind();
  web.renderPreact({
    root: location.hash.length > 1 ? <App /> : <Home />,
  });
}
