import {
  array,
  enums,
  Infer,
  nullable,
  object,
  string,
} from "local/deps/superstruct.ts";
import { FlowRunnerStep } from "./flow_runner_step.ts";

/** MAIN **/

export type FlowRunner = Infer<typeof FlowRunner>;

export const FlowRunner = object({
  id: string(),
  status: enums([
    "pending",
    "ready",
    "running",
    "success",
    "error",
    "cancelled",
  ]),
  variables: nullable(
    array(
      object({
        name: string(),
        value: string(),
      }),
    ),
  ),
  output: nullable(string()),
  createdAt: string(),
  startedAt: nullable(string()),
  stoppedAt: nullable(string()),
  flowCommitId: string(),
  steps: array(FlowRunnerStep),
});
