import {
  useCallback,
  useEffect,
  useState,
} from "../../packages/deps/preact/hooks.ts";

/** MAIN **/

export function usePolling<T>(
  // deno-lint-ignore no-explicit-any
  cb: (...args: any) => T | void,
  pollingInterval: number,
): {
  start: () => void;
  stop: () => void;
  result: T | void;
} {
  const [result, setResult] = useState<ReturnType<typeof cb>>();
  const [intervalRef, setIntervalRef] = useState<number | null>();

  const start = useCallback(() => {
    if (intervalRef !== null) return;
    setIntervalRef(setInterval(() => {
      setResult(cb());
    }, pollingInterval));
  }, [cb, setIntervalRef, pollingInterval]);

  const stop = useCallback(() => {
    if (intervalRef === null) return;
    clearInterval(intervalRef);
    setIntervalRef(null);
  }, [intervalRef]);

  useEffect(() => {
    return stop;
  }, [stop]);

  return { start, stop, result };
}
