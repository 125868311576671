import { View, ViewProps } from "./view.tsx";

/** MAIN **/

export function Tag(props: ViewProps<"span">) {
  return (
    <View
      viewProps={props}
      class={[
        "flex items-center justify-center min-w-[1.5rem] h-5 px-1 text-xs",
        "bg-black bg-opacity-5 dark:bg-white dark:bg-opacity-10 rounded-md",
      ]}
    />
  );
}
