import { ComponentChildren } from "local/deps/preact.ts";
import { useSignal } from "local/deps/preact/signals.ts";
import { View } from "./view.tsx";

/** MAIN **/

export function Animated(props: {
  duration?: number;
  children?: ComponentChildren;
}) {
  const { children, duration = 300 } = props;
  const rect = useSignal<DOMRect | null>(null);
  return (
    <View
      class={[
        duration > 0 && `transition-all duration-${duration}`,
      ]}
      style={{
        height: rect.value?.height ?? undefined,
      }}
    >
      <View rect={rect}>
        {children}
      </View>
    </View>
  );
}
