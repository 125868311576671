import humanizeDuration from "https://esm.sh/humanize-duration@3.28.0";
import { useEffect } from "local/deps/preact/hooks.ts";
import { useSignal } from "local/deps/preact/signals.ts";
import { View, ViewProps } from "./view.tsx";

/** MAIN **/

export function Age(
  props: ViewProps<"time"> & {
    time: Date;
    refreshSeconds?: number;
  },
) {
  const { time, refreshSeconds = 10, ...viewProps } = props;
  const age = useSignal("right now");

  function updateAge() {
    const now = new Date();
    const diff = now.getTime() - time.getTime();
    const seconds = Math.round(diff / 1000);
    if (seconds < 60) {
      age.value = "just now";
    } else {
      age.value = humanizeDuration(seconds * 1000, {
        largest: 1,
      }) + " ago";
    }
  }

  useEffect(() => {
    updateAge();
    const interval = setInterval(() => {
      updateAge();
    }, refreshSeconds * 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <View
      viewProps={viewProps}
      tag="time"
      // @ts-ignore FIXME
      datetime={time.toISOString()}
      title={time.toLocaleString()}
    >
      {age.value}
    </View>
  );
}
