import { Icon } from "local/ui/icon.tsx";
import { ViewNode } from "local/ui/view.tsx";

/** MAIN **/

export function SettingsStatus(props: {
  color: string;
  icon: ViewNode;
  message: string;
}) {
  const { color, icon, message } = props;
  return (
    <Icon
      size={4}
      title={message}
      class={[
        "mx-2",
        `text-${color}-500`,
      ]}
    >
      {icon}
    </Icon>
  );
}
