import {
  ReadonlySignal,
  Signal,
  useSignal,
  useSignalEffect,
} from "local/deps/preact/signals.ts";
import { TextEditor } from "local/ui/text_editor.tsx";
import { View } from "local/ui/view.tsx";
import { tokenize, variableNames } from "../utils/tokenize.ts";

/** MAIN **/

const VARIABLE_PALETTE = [
  "#fd7f6f",
  "#7eb0d5",
  "#b2e061",
  "#bd7ebe",
  "#ffb55a",
  "#ffee65",
  "#beb9db",
  "#fdcce5",
  "#8bd3c7",
];

export function PromptText(props: {
  state: Signal<string>;
  disabled?: ReadonlySignal<boolean>;
}) {
  const { state, disabled } = props;

  const variables = useSignal<string[]>([]);

  useSignalEffect(() => {
    variables.value = variableNames(state.value);
  });

  return (
    <TextEditor
      disabled={disabled?.value}
      value={state.value}
      onChange={(value) => {
        state.value = value;
      }}
      tokenize={(value) =>
        tokenize({
          body: value,
        })}
      stringify={(value) => {
        return value;
      }}
      parse={(text) => {
        return text;
      }}
      placeholder={
        <View tag="span" class="text-gray-500 italic">
          Enter a prompt
        </View>
      }
      renderToken={(token) => {
        const colorIndex = variables.value.indexOf(
          token.value.replace(/[\{\}]/g, ""),
        ) % VARIABLE_PALETTE.length;

        const color = VARIABLE_PALETTE[colorIndex];

        if (token.type === "variable") {
          return (
            <View tag="span" class={[`text-[${color}]`]}>
              {token.value}
            </View>
          );
        } else if (token.type === "output") {
          return (
            <View tag="span" class="text-purple-500">
              {token.value}
            </View>
          );
        } else {
          return (
            <View tag="span">
              {token.value}
            </View>
          );
        }
      }}
    />
  );
}
