import { useEffect, useState } from "local/deps/preact/hooks.ts";

/** MAIN **/

export function useLocationHref() {
  const [href, setHref] = useState(location.href);

  useEffect(() => {
    const handler = () => setHref(location.href);
    addEventListener("hashchange", handler);
    return () => removeEventListener("hashchange", handler);
  }, [setHref]);

  return href;
}
