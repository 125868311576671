import { MoreVertical } from "app/components/icons/more_vertical.tsx";
import { useTable } from "app/features/stores/hooks/use_table.ts";
import { useTableRowRepo } from "app/features/stores/hooks/use_table_row_repo.ts";
import { Table, TableMeta } from "app/features/stores/types/table.ts";
import { MathPlus } from "icons/math_plus.tsx";
import { Pen } from "icons/pen.tsx";
import { Trash } from "icons/trash.tsx";
import { nanoid } from "local/deps/nanoid.ts";
import { useEffect } from "local/deps/preact/hooks.ts";
import { useSignal, useSignalEffect } from "local/deps/preact/signals.ts";
import { EditorPanel } from "local/ui/editor_panel.tsx";
import { Item } from "local/ui/item.tsx";
import { List } from "local/ui/list.tsx";
import { ListDivider } from "local/ui/list_divider.tsx";
import { View, ViewNode, ViewProps } from "local/ui/view.tsx";
import { Field } from "../../../../packages/ui/field.tsx";
import { Popover } from "../../../../packages/ui/popover.tsx";
import { parseFile } from "../utils/parse.ts";
import { TableEmptyView } from "./table_empty_view.tsx";

/** HELPERS **/

const DEFAULT_WIDTH = 240;

function updateMeta(table: Table, columnId: number, width: number): TableMeta {
  function getWidth(index: number) {
    const metaWidth = table.meta?.layout?.[columnId]?.width;

    if (index !== columnId) {
      return metaWidth ?? DEFAULT_WIDTH;
    }

    return Math.max(
      metaWidth ?? DEFAULT_WIDTH,
      width,
    );
  }

  return {
    layout: table.columns.map((_, index) => {
      return {
        width: getWidth(index),
      };
    }),
  };
}

function HeaderMenu(props: {
  onDeleteColumn: () => void;
  onEditName: () => void;
}) {
  const { onDeleteColumn, onEditName } = props;
  return (
    <List>
      <Item
        icon={<Pen />}
        title="Edit name"
        interactive
        onClick={() => {
          onEditName();
        }}
      />
      <Item
        icon={<Trash />}
        title="Delete column"
        color="red"
        interactive
        onClick={() => {
          const confirmed = confirm(
            "Delete this column?",
          );

          if (confirmed) {
            onDeleteColumn();
          }
        }}
      />
    </List>
  );
}

function Cell(
  props: Omit<ViewProps<"input">, "value"> & {
    width?: number;
    title: string;
    menu?: ViewNode;
    onChangeTitle: (name: string) => void;
  },
) {
  const { width, title, menu, onChangeTitle, ...rest } = props;
  const editing = useSignal(false);
  const input = useSignal<HTMLInputElement | null>(null);
  const isToggled = useSignal(false);
  const secondaryRect = useSignal<DOMRect | null>(null);

  useSignalEffect(() => {
    // select all
    input.value?.select();
    input.value?.focus();
  });

  useSignalEffect(() => {
    const rect = secondaryRect.value;
    if (!isToggled.value || !rect) return;
  });

  function save() {
    onChangeTitle?.(input.value?.value ?? "");
  }

  function saveAndExit() {
    save();
    editing.value = false;
  }

  if (editing.value) {
    return (
      <Field
        tag="input"
        element={input}
        value={title}
        color="blue"
        class="text-sm"
        onKeyDown={(event: KeyboardEvent) => {
          if (event.key === "Enter") {
            saveAndExit();
          }
          if (event.key === "Escape") {
            saveAndExit();
          }
        }}
        onInput={() => {
          save();
        }}
        onBlur={() => {
          saveAndExit();
        }}
        style={{
          width: width ? `${width}px` : undefined,
          fontSize: "14px",
        }}
        {...rest}
      />
    );
  }

  return (
    <Item
      title={title}
      interactive
      style={{
        width,
      }}
      tabIndex={0}
      onSecondaryAction={() => {
        isToggled.value = !isToggled.value;
      }}
      isSecondaryToggled={isToggled.value}
      secondaryIcon={<MoreVertical />}
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          editing.value = true;
        }
      }}
      onClick={() => {
        editing.value = true;
      }}
      renderSecondary={({ children }) => {
        return (
          <View
            rect={secondaryRect}
          >
            {children}
            <Popover
              isOpen={isToggled.value}
              openerRect={secondaryRect.value}
            >
              {menu}
            </Popover>
          </View>
        );
      }}
      {...(rest as Record<PropertyKey, unknown>)}
    />
  );
}

/** MAIN **/

export type TableEditorProps = {
  id: string;
};

export function TableEditor(props: TableEditorProps) {
  const { id } = props;

  const tableItem = useTable({
    id,
    name: "",
    createdAt: new Date().toISOString(),
    columns: [],
    meta: null,
  });

  const tableRowRepo = useTableRowRepo();

  const name = useSignal("");

  useEffect(() => {
    tableItem.loadRows();
  }, []);

  useSignalEffect(() => {
    const { data } = tableItem.store.value;
    if (!data) return;
    name.value = data.name;
  });

  const table = tableItem.store.value;
  const hasRows = tableItem.rows.value.data.length > 0;
  const columns = table.data?.columns ?? [];
  const layout = table.data?.meta?.layout ?? [];

  return (
    <EditorPanel
      fullWidth
      fullHeight
      title={name.value}
      loading={table.status.isLoading}
      loadingMessage="Loading table..."
      onTitleChange={(value) => {
        name.value = value;
        tableItem.update((data) => {
          data.name = value;
        });
      }}
    >
      <List class="h-full">
        <View
          tag="header"
          class="flex gap-1"
        >
          {columns.map((column, colIndex) => {
            const width = layout[colIndex]?.width ?? DEFAULT_WIDTH;
            console.log("width", width);
            return (
              <Cell
                key={colIndex}
                width={width}
                title={column.name}
                placeholder="Untitled"
                onChangeTitle={(name) => {
                  tableItem.update((data) => {
                    data.columns[colIndex].name = name;
                  });
                }}
                menu={
                  <HeaderMenu
                    onDeleteColumn={() => {
                      tableItem.update((data) => {
                        data.columns.splice(colIndex, 1);
                      });
                    }}
                    onEditName={() => {
                    }}
                  />
                }
              />
            );
          })}
          <Item>
            <Item
              size={1}
              color="green"
              title="Add Column"
              icon={<MathPlus />}
              interactive
              onClick={() => {
                tableItem.update((data) => {
                  data.columns.push({
                    name: "",
                  });
                });
              }}
            />
          </Item>
        </View>
        {!hasRows && (
          <TableEmptyView
            onAddColumn={() => {
              tableItem.update((data) => {
                data.columns.push({
                  name: "",
                });
              });
            }}
            onOpenFile={() => {}}
            onDropFile={async (file) => {
              const data = await parseFile(file);

              tableItem.update((state) => {
                state.columns = data.columns;
              });

              tableRowRepo.createAll(data.rows.map((row) => ({
                id: nanoid(),
                tableId: id,
                createdAt: new Date().toISOString(),
                userId: "",
                cells: row.cells,
              })));
            }}
          />
        )}
        {hasRows && <ListDivider />}
        {hasRows &&
          tableItem.rows.value.data.map((row, rowIndex) => (
            <View class="flex gap-1">
              {columns.map((column, colIndex) => {
                const width = layout?.[colIndex]?.width ??
                  DEFAULT_WIDTH;
                const cell = row.cells.find(
                  (cell) => cell.name === column.name,
                );
                const cellData = cell?.data ?? "";
                return (
                  <Cell
                    key={`${rowIndex}-${colIndex}`}
                    placeholder="Empty"
                    title={cellData}
                    width={width}
                    onChangeTitle={(title) => {
                      console.log("title", title);
                    }}
                  />
                );
              })}
            </View>
          ))}
      </List>
    </EditorPanel>
  );
}
