import { createLoginUrl } from "app/utils/auth.ts";

// TODO: allow prod env
import * as env from "./envs/prod.ts";

/** HELPERS **/

/** MAIN **/

export * from "./shared.ts";

export const root = new URL(
  (env.ssl ? "https" : "http") +
    "://" +
    env.hostname +
    (env.port ? ":" + env.port : ""),
);

export const graphql = {
  url: new URL(
    "https://6hm4hf7bx5hknhkumgzns273uu.appsync-api.eu-west-1.amazonaws.com/graphql",
  ),
};

export const localStorageKeys = {
  user: "app/user",
  theme: "app/theme",
};

export const auth = {
  url: createLoginUrl({
    redirectUri: root,
    base: new URL(
      "https://promptkitchen.auth.eu-west-1.amazoncognito.com/oauth2/authorize",
    ),
    clientId: "2qhm8dmemu07qmel7ccc3o8mps",
    responseType: "token",
    scope: "email openid profile",
  }),
};
