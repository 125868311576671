import { History } from "app/components/icons/history.tsx";
import { Tune } from "app/components/icons/tune.tsx";
import { useEvent } from "app/hooks/event_bus.tsx";
import { useEffect } from "../../../packages/deps/preact/hooks.ts";
import {
  useComputed,
  useSignal,
} from "../../../packages/deps/preact/signals.ts";
import { Chip } from "../../../packages/ui/chip.tsx";
import { Icon } from "../../../packages/ui/icon.tsx";
import { View } from "../../../packages/ui/view.tsx";
import { CompletionDetails } from "../../features/completions/components/completion_details.tsx";
import { CompletionsList } from "../../features/completions/mod.ts";
import { PromptEditor, PromptTuner } from "../../features/prompts/mod.ts";
import { Toolbar } from "../../features/toolbar/mod.ts";

const FOREGROUND = "z-10";

export function Editor(props: {
  promptId: string;
  completionId?: string;
}) {
  const completionId = useSignal<string | null>(null);

  const contextBarView = useSignal<"results" | "tuner" | null>(null);
  const contextBarOpen = useComputed(() => contextBarView.value !== null);

  useEffect(() => {
    completionId.value = null;
  }, [props.promptId]);

  const onClickResults = () => {
    if (contextBarView.value === "results") {
      contextBarView.value = null;
      completionId.value = null;
      return;
    }

    contextBarView.value = "results";
  };

  const onClickTuner = () => {
    completionId.value = null;
    if (contextBarView.value === "tuner") {
      contextBarView.value = null;
      return;
    }

    contextBarView.value = "tuner";
  };

  useEvent("completion-create", () => {
    contextBarView.value = "results";
  });

  return (
    <View class="flex flex-1 relative">
      {completionId.value && (
        <View
          class={[
            FOREGROUND,
            "absolute w-full h-full dark:bg-gray-800 bg-gray-200",
          ]}
        >
          <CompletionDetails
            completionId={completionId}
          />
        </View>
      )}
      <View class="absolute w-full h-full">
        <PromptEditor
          id={props.promptId}
          onViewResults={onClickResults}
        />
      </View>
      <View
        class={[
          FOREGROUND,
          "mr-12 w-[300px]",
          "fixed top-0 right-0 bottom-0",
          "border-r border-gray-200 dark:border-gray-800",
          "transition-all",
          !contextBarOpen.value && "transform translate-x-full",
          !contextBarOpen.value && "pointer-events-none",
        ]}
      >
        {contextBarView.value === "results" && (
          <CompletionsList
            completionId={completionId}
            promptId={props.promptId}
          />
        )}
        {contextBarView.value === "tuner" && (
          <PromptTuner
            id={props.promptId}
          />
        )}
      </View>
      <Toolbar>
        <View class="flex flex-col gap-4 items-center justify-center">
          <Chip
            selected={contextBarView.value === "tuner"}
            onClick={onClickTuner}
            title="Prompt Tuner"
          >
            <Icon>
              <Tune />
            </Icon>
          </Chip>
          <Chip
            selected={contextBarView.value === "results"}
            onClick={onClickResults}
            title="Results"
          >
            <Icon>
              <History />
            </Icon>
          </Chip>
        </View>
      </Toolbar>
    </View>
  );
}
