import * as mutations from "app/features/flows/mutations/mod.ts";
import { useEventBus } from "app/hooks/event_bus.tsx";
import { useQuery } from "app/hooks/graphql.ts";

/** MAIN **/

export function useCreateFlowRunner() {
  const query = useQuery();
  const eventBus = useEventBus();

  return async function (props: {
    flowCommitId: string;
    variables?: { name: string; value: string }[];
  }) {
    const data = await query(mutations.createFlowRunner.query, {
      input: {
        flowCommitId: props.flowCommitId,
        variables: props.variables ?? null,
      },
    });

    eventBus.publish({
      type: "flow-runner-create",
      data,
    });
  };
}
