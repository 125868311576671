import { View } from "./view.tsx";

/** MAIN **/

export function ListDivider(props: {
  collapseTop?: boolean;
  collapseBottom?: boolean;
  hidden?: boolean;
} = {}) {
  const { collapseTop, collapseBottom, hidden } = props;
  return (
    <View
      class={[
        "flex",
        collapseTop
          ? collapseBottom ? "h-0" : "h-6"
          : collapseBottom
          ? "h-6"
          : "h-12",
        collapseTop ? "items-start" : "items-center",
      ]}
    >
      <View
        tag="hr"
        class={[
          "w-full",
          "bg-black dark:bg-white",
          "border-none",
          "h-[1px]",
          hidden ? "opacity-0" : "opacity-10",
          "transition-opacity",
        ]}
      />
    </View>
  );
}
