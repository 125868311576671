import { useEventBus } from "app/hooks/event_bus.tsx";
import { nanoid } from "../../../../packages/deps/nanoid.ts";
import { useCompletionRepo, usePromptCommitRepo } from "../../stores/mod.ts";
import { Prompt } from "../../stores/types/mod.ts";

/** MAIN **/

export function usePlay() {
  const eventBus = useEventBus();
  const completionsRepo = useCompletionRepo();
  const promptCommitRepo = usePromptCommitRepo();

  return async function (options: {
    prompt: Prompt;
    completionInput?: { variables: { name: string; value: string }[] | null };
  }) {
    const promptCommit = await promptCommitRepo.create({
      promptId: options.prompt.id,
      promptSnapshot: {
        name: options.prompt.name,
        body: options.prompt.body,
        parameters: options.prompt.parameters,
      },
      message: "Auto-generated commit",
      id: nanoid(),
      createdAt: new Date().toISOString(),
    });

    const completion = await completionsRepo.fromPromptCommit(
      promptCommit.id,
      options.completionInput?.variables ?? undefined,
    );

    eventBus.publish({
      type: "completion-create",
      data: completion,
    });
  };
}
