import { DropPosition, useDropTarget } from "app/hooks/use_drop_target.ts";
import { useFocus } from "app/hooks/use_focus.ts";
import { useHover } from "app/hooks/use_hover.ts";
import {
  ReadonlySignal,
  useComputed,
  useSignal,
} from "local/deps/preact/signals.ts";
import { Icon } from "./icon.tsx";
import { View, ViewChildren, ViewNode, ViewProps } from "./view.tsx";

/** HELPERS **/

function Secondary(props: {
  id?: string;
  active?: ReadonlySignal<boolean>;
  onClick?: () => void;
  icon?: ViewNode;
  toggled?: boolean;
  size: ItemSize;
  isToggled?: boolean;
}) {
  const { onClick, active, icon, toggled, size, isToggled } = props;

  if (!onClick || !icon) return null;

  const hover = useSignal(false);
  const focus = useSignal(false);
  const element = useSignal<HTMLDivElement | null>(null);
  const openerRect = useSignal<DOMRect | null>(null);

  useHover(element, hover);
  useFocus(element, focus);

  const hidden = useComputed(() => {
    const isFocus = focus.value;
    const isActive = active?.value ?? false;
    const isHover = hover.value;
    return !(isFocus || isActive || isHover || isToggled);
  });

  return (
    <View
      class={[
        hidden.value ? "opacity-0" : "opacity-100",
        "transition-opacity duration-200",
      ]}
    >
      <Item
        onClick={onClick}
        rect={openerRect}
        element={element}
        toggled={toggled}
        interactive
        size={size}
      >
        <Icon>
          {icon}
        </Icon>
      </Item>
    </View>
  );
}

function TextLine(props: {
  title?: string;
  placeholder?: string;
  hasIcon?: boolean;
}) {
  const { title = "", placeholder = "", hasIcon } = props;

  if (!title.trim() && !placeholder.trim()) return null;

  const isEmpty = title.trim() === "";
  return (
    <View
      class={[
        "flex-1 overflow-hidden whitespace-nowrap",
        hasIcon ? "pr-2" : "px-2",
        isEmpty ? "text-gray-500 italic" : "",
      ]}
      style={{
        textOverflow: "ellipsis",
      }}
    >
      {title.trim() || placeholder}
    </View>
  );
}

function smaller(size: ItemSize) {
  return Math.max(0, size - 1) as ItemSize;
}

/** MAIN **/

export type ItemSize = 0 | 1 | 2 | 3;

export type ItemProps = Omit<ViewProps<"article">, "icon" | "size"> & {
  color?: "green" | "red" | "blue" | "yellow" | "purple" | "gray";
  interactive?: boolean;
  border?: boolean;
  href?: string;
  selected?: boolean;
  disabled?: boolean;
  size?: ItemSize;
  toggled?: boolean;
  icon?: ViewChildren;
  secondaryIcon?: ViewNode;
  title?: string;
  secondaryChildren?: ViewNode;
  placeholder?: string;
  isSecondaryToggled?: boolean;
  preventDefault?: boolean;
  onSecondaryAction?: () => void;
  onDropped?: (data: string) => void;
  renderSecondary?: (props: {
    hovered: boolean;
    size: ItemSize;
    children: ViewNode;
  }) => ViewNode;
};

export function Item(props: ItemProps) {
  const {
    interactive,
    border,
    disabled,
    selected,
    toggled,
    size = 2,
    icon,
    secondaryIcon,
    color,
    children,
    secondaryChildren,
    placeholder,
    title,
    preventDefault,
    isSecondaryToggled,
    onClick,
    onSecondaryAction,
    onDropped,
    renderSecondary = (props) => props.children,
    viewProps,
    ...rest
  } = props;

  const hovered = useSignal(false);
  const dropping = useSignal<DropPosition>(undefined);
  const element = useSignal<HTMLDivElement | null>(null);

  useHover(element, hovered);

  // FIXME: This must be last hook, because it is called conditionally.
  if (onDropped) {
    useDropTarget({
      element,
      dropping,
      onDrop: onDropped ?? (() => {}),
    });
  }

  const tag = (() => {
    if (props.href) return "a";
    return "article";
  })();

  const cursor = (() => {
    if (disabled) return "cursor-default";
    if (interactive) return "cursor-pointer";
    return undefined;
  })();

  const bgOpacity = (() => {
    if (toggled) return 90;
    if (selected) return 10;
    return 0;
  })();

  const bgHoverOpacity = (() => {
    if (toggled) return 100;
    if (selected) return 10;
    if (disabled) return 0;
    if (interactive) return 5;
    return 0;
  })();

  const textColorLight = (() => {
    return color ? `${color}-500` : "gray-700";
  })();

  const textColorDark = (() => {
    return color ? `${color}-500` : "gray-300";
  })();

  const bgColorDark = (() => {
    if (toggled) return "gray-500";
    if (color) return `${color}-500`;
    return "white";
  })();

  const bgColorLight = (() => {
    if (toggled) return "gray-500";
    if (color) return `${color}-500`;
    return "black";
  })();

  const borderColor = (() => {
    if (dropping.value && onDropped) return "gray-300 dark:border-gray-700";
    if (!border) return "border-transparent";
  })();

  const height = (() => {
    return ["h-6", "h-8", "h-12", "h-16"][size];
  })();

  const minWidth = (() => {
    return ["min-w-6", "min-w-8", "min-w-12", "min-w-16"][size];
  })();

  const textSize = (() => {
    return ["text-xs", "text-xs", "text-sm", "text-base"][size];
  })();

  const iconSize = (() => {
    return [4, 4, 5, 6][size];
  })();

  const secondaryBorderRadius = (() => {
    return ["rounded-full", "rounded-full", "rounded-md", "rounded-lg"][size];
  })();

  const paddingLeft = (() => {
    return ["", "pl-2", "pl-2", "pl-2"][size];
  })();

  const gap = (() => {
    return ["gap-1", "gap-1", "gap-2", "gap-2"][size];
  })();

  return (
    <View
      viewProps={{ ...rest, ...viewProps }}
      tag={tag}
      element={element}
      value={title}
      tabIndex={interactive ? undefined : -1}
      onClick={(e: MouseEvent) => {
        if (onClick) {
          e.preventDefault();
          e.stopPropagation();
          // FIXME: Weird type error below.
          // @ts-ignore need to fix this
          onClick(e);
        }
      }}
      class={[
        "relative",
        "pr-2 flex items-center flex-shrink-0",
        "border-1",
        "transition-colors",
        "box-border",
        `text-${textColorLight} dark:text-${textColorDark}`,
        `bg-${bgColorLight} dark:bg-${bgColorDark}`,
        `bg-opacity-${bgOpacity} dark:bg-opacity-${bgOpacity}`,
        `hover:bg-opacity-${bgHoverOpacity} dark:hover:bg-opacity-${bgHoverOpacity}`,
        disabled && "opacity-50",
        gap,
        cursor,
        paddingLeft,
        secondaryBorderRadius,
        borderColor,
        height,
        minWidth,
        textSize,
      ]}
    >
      {icon && (
        <Item
          color={color}
          size={smaller(size)}
        >
          <Icon size={iconSize}>
            {icon}
          </Icon>
        </Item>
      )}
      {children}
      <TextLine
        title={title}
        placeholder={placeholder}
        hasIcon={!!icon}
      />
      {secondaryChildren}
      {renderSecondary({
        hovered: hovered.value,
        size: smaller(size),
        children: (
          <Secondary
            active={hovered}
            onClick={onSecondaryAction}
            icon={secondaryIcon}
            toggled={isSecondaryToggled}
            size={smaller(size)}
            isToggled={isSecondaryToggled}
          />
        ),
      })}
    </View>
  );
}
