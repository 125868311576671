import { variableNames } from "app/features/prompts/utils/tokenize.ts";
import { usePrompt } from "app/features/stores/hooks/use_prompt.ts";
import { useRouter } from "app/hooks/use_router.ts";
import { Asterisk } from "icons/asterisk.tsx";
import { FormatText } from "icons/format_text.tsx";
import { PlayList } from "icons/play_list.tsx";
import { VNode } from "local/deps/preact.ts";
import { Signal, useSignal } from "local/deps/preact/signals.ts";
import { Icon } from "local/ui/icon.tsx";
import { Item } from "local/ui/item.tsx";
import { Spinner } from "local/ui/spinner.tsx";
import { Tag } from "local/ui/tag.tsx";
import { TrashButton } from "local/ui/trash_button.tsx";
import { View } from "local/ui/view.tsx";
import { useMemo } from "../../../../packages/deps/preact/hooks.ts";
import type { StepItem } from "../hooks/use_step_items.ts";

/** HELPERS **/

const icons: Record<string, VNode> = {
  flows: <PlayList />,
  prompts: <FormatText />,
  empty: <Asterisk />,
};

const iconOpacities: Record<string, string> = {
  flow: "",
  prompt: "",
  empty: "opacity-50",
};

function renderEmptyTitle() {
  return (
    <View
      tag="p"
      class={[
        "text-gray-600 dark:text-gray-400 text-xs italic",
      ]}
    >
      Drop a prompt or flow here.
    </View>
  );
}

/** MAIN **/

export function StepItem(props: {
  index: number;
  stepItem: StepItem;
  onDrop: (data: string) => void;
  onUpdateName: (name: string) => void;
  onTrash: () => void;
  variablePalette?: Signal<{ name: string; color: string }[]>;
}) {
  const {
    index,
    onDrop,
    onTrash,
    onUpdateName,
    stepItem,
    variablePalette,
  } = props;

  const router = useRouter();

  const nameInput = useSignal(stepItem.name ?? "");
  const prompt = usePrompt(stepItem.id);

  const variables = useMemo(() => {
    const vars = variableNames(prompt.store.value.data?.body ?? "");
    return variablePalette?.value.filter((v) => vars.includes(v.name));
  }, [prompt.store.value.data?.body, variablePalette]);

  const href = (() => {
    if (stepItem.type === "flows") return router.href("flow", stepItem.id);
    if (stepItem.type === "prompts") return router.href("prompt", stepItem.id);
  })();

  const icon = icons[stepItem.type] ?? icons.empty;
  const iconOpacity = iconOpacities[stepItem.type] ?? iconOpacities.empty;

  const borderColor = variablePalette?.value.find((v) =>
    v.name === stepItem.name
  )?.color;

  const title = stepItem.type === "empty"
    ? renderEmptyTitle()
    : (stepItem.isLoading
      ? <Spinner size={4} />
      : <View>{stepItem.title}</View>);

  return (
    <Item onDropped={onDrop}>
      <View
        tag="p"
        class="flex-1 flex gap-2 items-center"
      >
        <Icon class={iconOpacity}>
          {icon}
        </Icon>
        <span class="mx-2">
          <Tag>#{index + 1}</Tag>
        </span>
        <View
          tag={href ? "a" : "p"}
          href={href}
          class={[
            href && "hover:underline",
          ]}
          title="Prompt or flow used in this step"
        >
          {title}
        </View>
        <View
          placeholder="Enter variable name for result"
          title="Variable name to store the result of this step. Will be available in successor steps. "
          tag="input"
          value={nameInput.value}
          onInput={(e) => {
            nameInput.value = e.currentTarget.value;
            onUpdateName(e.currentTarget.value);
          }}
          class={[
            // edit layer is interactive but only selection is visible
            // see: https://github.com/react-simple-code-editor/react-simple-code-editor#limitations
            "p-1",
            "bg-transparent outline-none resize-none",
            "border-1 rounded-md",
            borderColor
              ? `border-[${borderColor}]`
              : "border-gray-400 dark:border-gray-600",
          ]}
        />{" "}
        {/* Do a little colored dot/circle for each variable */}
        {variables?.map((v) => (
          <View
            title={`{{${v.name}}} is used in this step`}
            key={v.name}
            class={[
              "w-3 h-3 rounded-full",
              `bg-[${v.color}]`,
            ]}
          />
        ))}
      </View>

      <TrashButton
        title="Remove step from flow"
        onTrash={onTrash}
      />
    </Item>
  );
}
