import { useHover } from "app/hooks/use_hover.ts";
import { useSignal, useSignalEffect } from "local/deps/preact/signals.ts";
import { View, ViewNode, ViewProps } from "local/ui/view.tsx";
import { ScrollView } from "./scroll_view.tsx";

/** MAIN **/

export function Panel(
  props: ViewProps<"section"> & {
    raised?: boolean;
    header?: ViewNode;
    footer?: ViewNode;
    isBodyHidden?: boolean;
    gradient?: boolean;
    fullWidth?: boolean;
    fullHeight?: boolean;
    reverseGradient?: boolean;
    largePadding?: boolean;
  },
) {
  const {
    raised,
    header,
    footer,
    isBodyHidden,
    children,
    fullWidth,
    fullHeight,
    reverseGradient,
    gradient,
    largePadding,
    ...viewProps
  } = props;

  const container = useSignal<HTMLElement | null>(null);
  const hovered = useSignal(false);
  useHover(container, hovered);

  const scrollTop = useSignal(true);

  const scrollEnd = useSignal(true);

  const contentRect = useSignal<DOMRect | undefined>(undefined);

  function onScroll() {
    const element = container.value;
    if (!element) return;

    setTimeout(() => {
      const offset = element.scrollTop;
      const available = element.scrollHeight;
      const visible = element.clientHeight;
      scrollTop.value = offset === 0;
      scrollEnd.value = offset + visible >= available;
    }, 100);
  }

  useSignalEffect(() => {
    contentRect.value;
    onScroll();
  });

  useSignalEffect(() => {
    const element = container.value;
    if (!element) return;
    onScroll();
    element.addEventListener("scroll", onScroll);
    return () => {
      element.removeEventListener("scroll", onScroll);
    };
  });

  return (
    <View
      tag="section"
      viewProps={viewProps}
      class={[
        "absolute inset-0 flex flex-col",
        gradient &&
        "bg-gradient-to-r from-gray-100 to-gray-200 dark:from-gray-900 dark:to-gray-800",
        reverseGradient &&
        "bg-gradient-to-l from-gray-100 to-gray-200 dark:from-gray-900 dark:to-gray-800",
        raised && "shadow-2xl rounded-xl",
      ]}
    >
      <View
        class={[
          "absolute inset-0",
          "flex-1 flex flex-col items-stretch content-stretch",
          fullWidth ? "" : "max-w-2xl",
          footer ? "" : "pb-8",
        ]}
      >
        <View
          tag="header"
          class={[
            "relative text-2xl px-8",
            "h-28 flex items-center",
          ]}
        >
          {header}
        </View>
        <View tag="section" class="relative flex-1">
          {fullHeight
            ? (
              <View class="px-8 absolute inset-0">
                {children}
              </View>
            )
            : (
              <ScrollView>
                {children}
              </ScrollView>
            )}
        </View>
        {footer
          ? (
            <View
              tag="footer"
              class={[
                "relative text-2xl px-8",
                "h-28 flex items-center",
              ]}
            >
              {footer}
            </View>
          )
          : null}
      </View>
    </View>
  );
}
