import { createMutation } from "app/utils/graphql.ts";
import {
  array,
  enums,
  nullable,
  object,
  string,
} from "local/deps/superstruct.ts";

/** HELPERS **/

export const query = createMutation({
  name: "createFlowRunner",
  vars: object({
    input: object({
      flowCommitId: string(),
      variables: nullable(
        array(
          object({
            name: string(),
            value: string(),
          }),
        ),
      ),
    }),
  }),
  output: object({
    id: string(),
    createdAt: string(),
    status: enums(["pending", "ready", "running", "success", "error"]),
  }),
  body: `
    mutation ($input: CreateFlowRunnerInput!) {
      createFlowRunner(input: $input) {
        id
        createdAt
        status
      }
    }
  `,
});
