import { createContext } from "local/deps/preact.ts";
import { useContext, useEffect } from "local/deps/preact/hooks.ts";

/** HELPERS **/

function createEventBus(): EventBus {
  const self: EventBus = {
    subscribers: new Map(),
    publish: (event: Event) => {
      const handlers = self.subscribers.get(event.type);
      if (handlers) {
        handlers.forEach((handler) => handler(event));
      }
    },
    subscribe: (type, handler) => {
      let handlers = self.subscribers.get(type);
      if (!handlers) {
        handlers = new Set<(event: Event) => void>();
        self.subscribers.set(type, handlers);
      }
      handlers!.add(handler as never);
      return () => {
        handlers?.delete(handler as never);
      };
    },
  };
  return self;
}

/** MAIN **/

export type PromptCreateEvent = {
  type: "prompt-create";
  data: {
    id: string;
    name: string;
    body: string;
  };
};

export type PromptUpdateEvent = {
  type: "prompt-update";
  data: {
    id: string;
    name?: string;
  };
};

export type PromptDeleteEvent = {
  type: "prompt-delete";
  data: {
    id: string;
  };
};

export type FlowCreateEvent = {
  type: "flow-create";
  data: {
    id: string;
    name: string;
  };
};

export type FlowUpdateEvent = {
  type: "flow-update";
  data: {
    id: string;
    name?: string;
  };
};

export type CompletionCreateEvent = {
  type: "completion-create";
  data: {
    id: string;
    status: "pending" | "running" | "success" | "error" | "cancelled";
    createdAt: string;
  };
};

export type FlowRunnerCreateEvent = {
  type: "flow-runner-create";
  data: {
    id: string;
    status: "ready" | "pending" | "success" | "error" | "running" | "cancelled";
    createdAt: string;
  };
};

export type EventMap = {
  "prompt-create": PromptCreateEvent;
  "prompt-update": PromptUpdateEvent;
  "prompt-delete": PromptDeleteEvent;
  "flow-create": FlowCreateEvent;
  "flow-update": FlowUpdateEvent;
  "completion-create": CompletionCreateEvent;
  "flow-runner-create": FlowRunnerCreateEvent;
};

export type EventType = keyof EventMap;

export type Event = EventMap[EventType];

export type EventBus = {
  subscribers: Map<EventType, Set<(event: Event) => void>>;
  publish: (event: Event) => void;
  subscribe: <T extends keyof EventMap>(
    type: T,
    handler: (event: EventMap[T]) => void,
  ) => () => void;
};

const EventBusContext = createContext<EventBus>(
  createEventBus(),
);

export function useEventBus() {
  return useContext(EventBusContext);
}

export function useEvent<T extends EventType>(
  type: T,
  handler: (event: EventMap[T]) => void,
) {
  const { subscribe } = useEventBus();
  useEffect(() => subscribe(type, handler), [type, handler]);
}
