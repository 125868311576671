import { Status } from "app/components/status.tsx";
import { tokenize } from "app/features/prompts/utils/tokenize.ts";
import { Signal } from "local/deps/preact/signals.ts";
import { Animated } from "local/ui/animated.tsx";
import { Item } from "local/ui/item.tsx";
import { View, ViewNode } from "local/ui/view.tsx";
import { Completion, PromptCommit } from "../../stores/types/mod.ts";

/** MAIN **/

const VARIABLE_PALETTE = [
  "#fd7f6f",
  "#7eb0d5",
  "#b2e061",
  "#bd7ebe",
  "#ffb55a",
  "#ffee65",
  "#beb9db",
  "#fdcce5",
  "#8bd3c7",
];

export function CompletionViewer(props: {
  renderActions?: () => ViewNode;
  completion: Completion;
  commit: PromptCommit;
  promptParamsOpen: Signal<boolean>;
}) {
  const {
    commit,
    completion,
    renderActions,
    promptParamsOpen,
  } = props;

  const tokens = [...tokenize({
    body: commit.promptSnapshot.body,
    variables: completion.variables ?? undefined,
    output: completion.output,
  })];

  return (
    <View
      class={[
        "flex flex-col",
        "transition-all duration-200 ease-in-out",
        "border-1 rounded-md",
        "border-gray-400 dark:border-gray-600",
      ]}
    >
      <Item class="text-xs text-gray-500">
        <Status
          status={completion.status}
          startedAt={new Date(completion.createdAt)}
          stoppedAt={completion.stoppedAt
            ? new Date(completion.stoppedAt)
            : undefined}
        />
        <View>
        </View>
        <View class="flex-1" />
        {renderActions?.()}
      </Item>
      <Animated>
        {promptParamsOpen.value && (
          <View
            class={[
              "pr-2 pl-4 py-2",
              "flex items-center flex-wrap gap-2",
            ]}
          >
            <View class="text-sm text-gray-500">
              model: {commit.promptSnapshot.parameters.model}
            </View>
            <View class="text-sm text-gray-500">
              max tokens: {commit.promptSnapshot.parameters.maxTokens}
            </View>
            <View class="text-sm text-gray-500">
              temperature: {commit.promptSnapshot.parameters.temperature}
            </View>
            {commit.promptSnapshot.parameters.stopSequences.length !== 0 && (
              <View class="text-sm text-gray-500">
                stop sequences:{" "}
                {commit.promptSnapshot.parameters.stopSequences.join(", ")}
              </View>
            )}
            <View class="text-sm text-gray-500">
              frequency penalty:{" "}
              {commit.promptSnapshot.parameters.frequencyPenalty}
            </View>
            <View class="text-sm text-gray-500">
              presence penalty:{" "}
              {commit.promptSnapshot.parameters.presencePenalty}
            </View>
          </View>
        )}
      </Animated>
      <View
        class={[
          "flex border-b-1 mx-2 border-gray-400 dark:border-gray-600 border-dashed",
        ]}
      />
      <View
        class={[
          "mt-1",
          "py-2 px-3",
          "font-mono text-xs leading-loose",
          "whitespace-pre-wrap",
          "select-text",
        ]}
      >
        {tokens.map((token, i) => {
          if (token.type === "variable") {
            return (
              <View tag="span" class="text-blue-500">
                {token.value}
              </View>
            );
          } else if (token.type === "output") {
            return (
              <View tag="span" class="text-green-500">
                {token.value}
              </View>
            );
          } else {
            return (
              <View tag="span">
                {token.value}
              </View>
            );
          }
        })}
      </View>
    </View>
  );
}
