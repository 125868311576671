import {
  useCompletionRepo,
  usePromptCommitRepo,
} from "app/features/stores/mod.ts";
import { useRepoItem } from "app/hooks/use_repo_item.ts";
import { ArrowLeft } from "icons/arrow_left.tsx";
import { Signal } from "local/deps/preact/signals.ts";
import { Chip } from "local/ui/chip.tsx";
import { Icon } from "local/ui/icon.tsx";
import { Panel } from "local/ui/panel.tsx";
import { Spinner } from "local/ui/spinner.tsx";
import { View } from "local/ui/view.tsx";
import { CompletionView } from "./completion_view.tsx";

/** MAIN **/

export interface CompletionDetailsProps {
  completionId: Signal<string | null>;
}

export function CompletionDetails(
  props: CompletionDetailsProps,
) {
  const { completionId } = props;

  const completionRepo = useCompletionRepo();
  const completionItem = useRepoItem(completionRepo, completionId.value ?? "");
  const completion = completionItem.store.value.data;

  const commitRepo = usePromptCommitRepo();
  const commitItem = useRepoItem(commitRepo, completion?.promptCommitId ?? "");
  const commit = commitItem.store.value.data;

  const loading = completionItem.store.value.status.isLoading ||
    commitItem.store.value.status.isLoading;

  return (
    <Panel
      isBodyHidden={loading}
      header={loading
        ? (
          <View class="flex items-center gap-4 text-lg text-gray-500">
            <Spinner size={8} />
            Loading prompt results...
          </View>
        )
        : (
          <View
            tag="text"
            class={[
              "h-full",
              "w-full",
              "flex",
              "items-center",
              "justify-between",
            ]}
          >
            {commit?.promptSnapshot.name}
            <Chip
              onClick={() => completionId.value = null}
            >
              <Icon>
                <ArrowLeft />
              </Icon>
              Back to editor
            </Chip>
          </View>
        )}
    >
      <View>
      </View>
      <CompletionView id={completionId} />
    </Panel>
  );
}
