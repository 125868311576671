import { debounce } from "local/deps/debounce.ts";
import { produce } from "local/deps/immer.ts";
import { computed, signal } from "local/deps/preact/signals-core.ts";
import {
  Entity,
  IdStore,
  RepoItemStore,
  RepoState,
  RepoStore,
  Resource,
} from "./repo.ts";

/** MAIN **/

const Helpers = {
  mutate,
  merge,
  saveAll,
  one,
};

export function store<T extends Resource>() {
  return signal<RepoState<T>>({
    pending: 0,
    data: [],
  });
}

export function mutate<T extends Resource>(store: RepoStore<T>) {
  return (fn: (state: RepoState<T>) => void) => {
    store.value = produce(store.value, fn);
  };
}

export function createAll<T extends Resource>(store: RepoStore<T>, options: {
  handler: (items: T[]) => Promise<T[]>;
  optimistic?: boolean;
}) {
  const { handler, optimistic = true } = options;
  const mutate = Helpers.mutate(store);

  return async (items: T[]) => {
    if (optimistic) {
      mutate((state) => {
        state.data.push(...items);
      });
    }

    const result = await handler(items);

    mutate((state) => {
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        const remote = result[i];
        const created = state.data.find((current) => current.id === item.id);
        if (created) {
          created.id = remote.id;
        }
      }
    });

    return result;
  };
}

export function merge<T extends Resource>(store: RepoStore<T>, options: {
  equals: (a: T, b: T) => boolean;
}) {
  const { equals } = options;
  const mutate = Helpers.mutate(store);
  return (items: T[]) => {
    mutate((state) => {
      const { data } = state;

      const created: T[] = [];
      const updated: T[] = [];

      for (const item of items) {
        const current = data.find((current) => current.id === item.id);
        if (!current) {
          created.push(item);
        } else if (!equals(item, current)) {
          updated.push(item);
        }
      }

      if (created.length > 0 || updated.length > 0) {
        const modified = data.map((current) => {
          const updated = items.find((item) => current.id === item.id);
          return updated || current;
        });

        state.data = [...modified, ...created];
      }
    });
  };
}

export function load<T extends Resource>(store: RepoStore<T>, options: {
  equals?: (a: T, b: T) => boolean;
} = {}) {
  const { equals } = options;

  const mutate = Helpers.mutate(store);

  const merge = Helpers.merge(store, {
    equals: equals || ((a, b) => a.id === b.id),
  });

  return async function (fn: () => Promise<T[] | void>): Promise<T[]> {
    mutate((state) => {
      state.pending++;
    });

    const items = await fn();

    if (items) {
      merge(items);
    }

    mutate((state) => {
      state.pending--;
    });

    return items ?? [];
  };
}

export function one<T>(
  handler: (items: T[]) => unknown,
) {
  return (item: T) => {
    handler([item]);
  };
}

export function first<T extends Resource>(
  handler: (items: T[]) => Promise<T[]>,
) {
  return async (item: T) => {
    const result = await handler([item]);
    return result[0];
  };
}

export function many<T>(
  handler: (item: T) => unknown,
) {
  return (items: T[]) => {
    for (const item of items) {
      handler(item);
    }
  };
}

export function status<T extends Resource>(store: RepoStore<T>) {
  return function (item: Entity<T> | undefined) {
    const { pending } = store.value;
    const isLoaded = item !== undefined;
    const isLoading = pending > 0 && item === undefined;
    const isMissing = pending === 0 && item === undefined;
    const isCreating = item?.localId !== undefined;
    return { isMissing, isLoading, isCreating, isLoaded };
  };
}

export function saveAll<T extends Resource>(store: RepoStore<T>, options: {
  equals: (a: T, b: T) => boolean;
  handler: (items: T[]) => void;
}) {
  const { equals, handler } = options;

  const merge = Helpers.merge(store, {
    equals,
  });

  const upload = debounce((items: T[]) => {
    handler(items);
  }, 1000);

  return function (items: T[]) {
    const { data } = store.value;

    const updated: T[] = [];
    for (const item of items) {
      const current = data.find((current) => current.id === item.id);
      if (current && !equals(current, item)) {
        updated.push(item);
      }
    }

    if (updated.length > 0) {
      merge(updated);
      upload(updated);
    }
  };
}

export function update<T extends Resource>(store: RepoStore<T>, options: {
  equals: (a: T, b: T) => boolean;
  handler: (items: T[]) => void;
}) {
  const { equals, handler } = options;

  const saveAll = Helpers.saveAll(store, {
    equals,
    handler,
  });

  const save = Helpers.one(
    saveAll,
  );

  return function (store: RepoItemStore<T>, fn: (item: T) => void) {
    const newItem = produce(store.value.data, (draft) => {
      if (!draft) {
        console.error(`Cannot update item that is not loaded`);
        return;
      }
      fn(draft as T);
    });

    if (newItem) {
      save(newItem);
    }
  };
}

export function removeAll<T extends Resource>(store: RepoStore<T>, options: {
  handler: (ids: string[]) => Promise<void>;
}) {
  const { handler } = options;

  const mutate = Helpers.mutate(store);

  return async function (ids: string[]) {
    mutate((state) => {
      const idSet = new Set(ids);
      state.data = state.data.filter((item) => !idSet.has(item.id));
    });

    await handler(ids);
  };
}

export function select<T extends Resource>(
  repo: RepoStore<T>,
  id: IdStore,
): RepoItemStore<T> {
  return computed(() => {
    const currentId = id.value;
    const data = repo.value.data.find((i) => i.id === currentId);
    return {
      data,
      status: status(repo)(data),
    };
  });
}
