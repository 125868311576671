import { Brain } from "app/components/icons/brain.tsx";
import { Connect } from "app/components/icons/connect.tsx";
import { useRouter } from "app/hooks/use_router.ts";
import { Danger } from "icons/danger.tsx";
import { User } from "icons/user.tsx";
import { List } from "local/ui/list.tsx";
import { Panel } from "local/ui/panel.tsx";
import { View } from "local/ui/view.tsx";
import { useOpenaiStatus } from "../hooks/use_openai_status.ts";
import { SettingsItem } from "./settings_item.tsx";
import { SettingsStatus } from "./settings_status.tsx";

/** MAIN **/

export function Settings() {
  const openaiStatus = useOpenaiStatus();
  const openaiStatusError = ["missing", "invalid"].includes(openaiStatus.value);

  const router = useRouter();

  const openaiStatusIndicator = openaiStatusError && (
    <SettingsStatus
      color="yellow"
      icon={<Danger />}
      message="Add your OpenAI API key to enable AI features."
    />
  );

  return (
    <Panel
      gradient
      header={
        <View class="text-2xl">
          Settings
        </View>
      }
    >
      <List>
        <SettingsItem
          href={router.href("settings", "account")}
          label="Account"
          icon={<User />}
        />
        <SettingsItem
          href={router.href("settings", "openai")}
          label="OpenAI"
          icon={<Brain />}
          status={openaiStatusIndicator || undefined}
        />
        <SettingsItem
          href={router.href("settings", "connect")}
          label="Connect"
          icon={<Connect />}
        />
      </List>
    </Panel>
  );
}
