import { assert, Struct } from "local/deps/superstruct.ts";
import { URLPattern } from "local/deps/urlpattern.ts";

/** MAIN **/

export type Route<T> = {
  path: string;
  type: Struct<T>;
};

export type Router = {
  add: <T>(route: Route<T>) => void;
  // deno-lint-ignore no-explicit-any
  match: (url: URL | string) => undefined | { path: string; data: any };
  url: (...parts: (string | number)[]) => URL;
  href: (...parts: (string | number)[]) => string;
};

export function createRouter(root: URL): Router {
  // deno-lint-ignore no-explicit-any
  const routes: Route<any>[] = [];
  const patterns = new Map<string, URLPattern>();

  function toUrl(path: string) {
    return new URL("#" + path, root.href);
  }

  function url(...parts: (string | number)[]) {
    return new URL("#/" + parts.join("/"), root.href);
  }

  function getPattern(path: string) {
    return patterns.get(path)!;
  }

  function add<T>(route: Route<T>) {
    const { path } = route;
    if (patterns.has(path)) {
      throw new Error(`Duplicate route: ${path}`);
    }
    routes.push(route);
    patterns.set(path, new URLPattern(toUrl(path)));
  }

  function match(url: URL | string) {
    for (const route of routes) {
      const { path, type } = route;
      const pattern = getPattern(path);
      const result = pattern.exec(url);

      if (result) {
        const data = result.hash.groups;
        assert(data, type);
        return { path, data };
      }
    }
  }

  function href(...parts: (string | number)[]) {
    return url(...parts).href;
  }

  return {
    add,
    match,
    url,
    href,
  };
}
