import {
  array,
  enums,
  Infer,
  nullable,
  object,
  string,
} from "local/deps/superstruct.ts";

/** MAIN **/

export type Completion = Infer<typeof Completion>;

export const Completion = object({
  id: string(),
  status: enums(["pending", "running", "success", "error"]),
  variables: nullable(
    array(
      object({
        name: string(),
        value: string(),
      }),
    ),
  ),
  output: nullable(string()),
  createdAt: string(),
  updatedAt: nullable(string()),
  startedAt: nullable(string()),
  stoppedAt: nullable(string()),
  promptCommitId: string(),
});
