/** MAIN **/

import { useRepoItem } from "app/hooks/use_repo_item.ts";
import { useMemo } from "local/deps/preact/hooks.ts";
import { computed } from "local/deps/preact/signals.ts";
import { useCompletionRepo } from "./use_completion_repo.ts";
import { usePromptCommitRepo } from "./use_prompt_commit_repo.ts";
import { usePromptRepo } from "./use_prompt_repo.ts";

export function usePrompt(id: string) {
  const repo = usePromptRepo();
  const item = useRepoItem(repo, id);
  const promptCommitRepo = usePromptCommitRepo();
  const completionsRepo = useCompletionRepo();

  return useMemo(() => {
    // Prompt -> Completion[]
    const completions = {
      load: () => {
        return completionsRepo.loadForPrompt(id);
      },
      store: computed(() => {
        const commitIds = new Set(
          promptCommitRepo.store.value.data
            .filter((item) => item.promptId === id)
            .map((item) => item.id),
        );

        const completions = completionsRepo.store.value.data
          .filter((item) => commitIds.has(item.promptCommitId))
          .sort((a, b) => -a.createdAt.localeCompare(b.createdAt));

        const loading = completionsRepo.store.value.pending > 0;

        return {
          data: completions,
          loading,
        };
      }),
    };

    return {
      store: item.store,
      completions,
      save: item.save,
    };
  }, [id]);
}
