import { View } from "local/ui/view.tsx";

/** MAIN **/

export function Error404() {
  return (
    <View
      class={[
        "absolute top-0 left-0 right-0 bottom-0",
        "flex flex-col items-center justify-center",
        "text-2xl",
      ]}
    >
      <View
        tag="h1"
        class={[
          "text-4xl",
        ]}
      >
        404
      </View>

      <View
        tag="h2"
        class={[
          "text-2xl",
        ]}
      >
        Page not found
      </View>
    </View>
  );
}
