import {
  array,
  Infer,
  nullable,
  object,
  optional,
  string,
} from "local/deps/superstruct.ts";

/** MAIN **/

export type TableRowCell = Infer<typeof TableRowCell>;

export const TableRowCell = object({
  name: string(),
  data: string(),
});

export type TableRow = Infer<typeof TableRow>;

export const TableRow = object({
  id: string(),
  createdAt: string(),
  updatedAt: optional(nullable(string())),
  tableId: string(),
  cells: array(TableRowCell),
});

export function equalsTableRow(a: TableRow, b: TableRow) {
  return (
    a.id === b.id &&
    a.tableId === b.tableId &&
    a.cells.length === b.cells.length &&
    a.cells.every((cell, i) => {
      return (
        cell.name === b.cells[i].name &&
        cell.data === b.cells[i].data
      );
    })
  );
}
