import type { ComponentChildren, JSX, VNode } from "local/deps/preact.ts";
import { useEffect, useRef } from "local/deps/preact/hooks.ts";
import type { Signal } from "local/deps/preact/signals-core.ts";
import type { Token } from "local/deps/twind.ts";

/** MAIN **/

export type ViewNode = VNode;

export type ViewChildren = ComponentChildren;

export type ViewTag = keyof JSX.IntrinsicElements;

export type HtmlProps<Tag extends ViewTag> =
  & Omit<JSX.IntrinsicElements[Tag], "class" | "style">
  & { class?: Token }
  & { style?: JSX.CSSProperties };

export type ViewProps<Tag extends ViewTag = ViewTag> =
  & HtmlProps<Tag>
  & {
    tag?: Tag;
    onElement?: (element: HTMLElement) => void;
    element?: Signal<HTMLElement | null | undefined>;
    rect?: Signal<DOMRectReadOnly | null | undefined>;
    hovered?: Signal<boolean>;
    pointer?: Signal<{ x: number; y: number }>;
    viewProps?: ViewProps<Tag>;
    onResize?: (rect: DOMRectReadOnly) => void;
  };

export function View<Tag extends ViewTag>(props: ViewProps<Tag>) {
  const {
    tag,
    element,
    rect,
    hovered,
    pointer,
    onElement,
    style,
    viewProps,
    class: className,
    ...rest
  } = { ...props.viewProps, ...props };

  const {
    style: viewStyle,
    class: viewClassName,
    ...viewRest
  } = viewProps ?? {};

  const Tag = tag ?? "div" as keyof JSX.IntrinsicElements;

  const ref = useRef<HTMLElement>(null);

  const mergedProps = {
    class: [
      className,
      viewClassName,
    ],
    style: {
      ...style,
      ...viewStyle,
    },
    ...rest,
    ...viewRest,
  };

  const { onResize, ...finalProps } = mergedProps;

  useEffect(() => {
    const el = ref.current;
    if (!el) return;
    if (!hovered) return;

    const onMouseEnter = () => {
      hovered.value = true;
    };

    const onMouseLeave = () => {
      hovered.value = false;
    };

    el.addEventListener("mouseenter", onMouseEnter);
    el.addEventListener("mouseleave", onMouseLeave);

    return () => {
      el.removeEventListener("mouseenter", onMouseEnter);
      el.removeEventListener("mouseleave", onMouseLeave);
    };
  }, [ref.current, hovered]);

  useEffect(() => {
    const el = ref.current;
    if (!el) return;
    if (!pointer) return;

    const onMove = (e: PointerEvent) => {
      const rect = el.getBoundingClientRect();
      pointer.value = {
        x: e.clientX - rect.left,
        y: e.clientY - rect.top,
      };
    };

    addEventListener("pointermove", onMove);
    return () => {
      removeEventListener("pointermove", onMove);
    };
  }, [pointer]);

  useEffect(() => {
    if (!globalThis.document) return;

    const el = ref.current;
    let cancel: () => void = () => {};

    const resize = () => {
      if (!el) return;
      const r = el.getBoundingClientRect();
      if (rect) rect.value = r;
      if (onResize) onResize(r);
    };

    if (rect || onResize) {
      resize();
    }

    requestAnimationFrame(() => {
      if (!el) return;
      onElement?.(el);

      if (element) {
        element.value = el;
      }

      if (rect || onResize) {
        const observer = new ResizeObserver(resize);
        observer.observe(el);
        cancel = () => observer.disconnect();
      }

      return () => {
        cancel();
        if (element) {
          element.value = null;
        }
        if (rect) {
          rect.value = null;
        }
      };
    });
  }, [element, onResize, rect]);

  return (
    // @ts-ignore TODO: figure out how to type this
    <Tag ref={ref} {...finalProps} />
  );
}
