import { FlowRunner } from "app/features/stores/mod.ts";
import { createQuery, fields } from "app/utils/graphql.ts";
import { array, Infer, object, string } from "local/deps/superstruct.ts";

/** MAIN **/

export type Output = Infer<typeof Output>;

export const Output = object({
  flowCommits: array(
    object({
      flowRunners: array(
        FlowRunner,
      ),
    }),
  ),
});

export const query = createQuery({
  name: "getFlow",
  output: Output,
  vars: object({
    id: string(),
  }),
  body: `
    query ($id: ID!) {
      getFlow(id: $id) {
        flowCommits {
          flowRunners {
            ${fields(FlowRunner)}
          }
        }
      }
    }
  `,
});
