import { useSignal } from "local/deps/preact/signals.ts";

/** MAIN **/

export function useOpenaiStatus() {
  // TODO: use store to manage that value
  // Problem with event bus:
  // Late subscribers (e.g. settings drawer) will not receive event
  // until keys are fetched again.
  // NOTE: Use "missing" or "error" to test the sidebar indicators.
  return useSignal("ok");
}
