import { Key } from "icons/key.tsx";
import { Trash } from "icons/trash.tsx";
import { Age } from "local/ui/age.tsx";
import { Item } from "local/ui/item.tsx";
import { Spinner } from "local/ui/spinner.tsx";
import { View } from "local/ui/view.tsx";
import { useApiKeyRepo } from "../../stores/mod.ts";
import { useDialog } from "../hooks/use_dialog.ts";
import { KeyEditor } from "./key_editor.tsx";

/** MAIN **/

export function KeyWidget() {
  const dialog = useDialog();

  const repo = useApiKeyRepo();
  const loading = repo.store.value.pending > 0;

  const currentKey = repo.store.value.data
    .sort((a, b) => a.createdAt > b.createdAt ? -1 : 1)
    .at(0);

  function onTrash(id: string) {
    const confirmed = window.confirm("Delete this API key?");
    if (!confirmed) {
      return;
    }
    repo.remove(id);
  }

  return (
    <>
      {loading && (
        <Item>
          <Spinner />
          <View class="text-xs text-gray-500">
            Loading your key...
          </View>
        </Item>
      )}
      {!loading && currentKey && (
        <Item
          border
          class="font-mono"
          icon={<Key />}
          title={"*".repeat(10) + currentKey.secret.slice(-4)}
          secondaryChildren={
            <View tag="p" class="font-sans text-xs text-gray-500">
              uploaded <Age time={new Date(currentKey.createdAt)} />
            </View>
          }
          secondaryIcon={<Trash />}
          onSecondaryAction={() => onTrash(currentKey.id)}
        />
      )}
      {!loading && !currentKey && (
        <KeyEditor
          onSubmit={() => {
            dialog.close();
          }}
        />
      )}
    </>
  );
}
