import { useQuerySignal } from "app/hooks/graphql.ts";
import { useEffect } from "local/deps/preact/hooks.ts";
import * as getFlowCommit from "../queries/flow_commit.ts";

/** MAIN **/
export function useFlowCommit(id: string) {
  const result = useQuerySignal(getFlowCommit.query, { id });

  useEffect(() => {
    result.load();
  }, [id]);

  return { ...result };
}
