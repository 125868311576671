import { useQuery } from "app/hooks/graphql.ts";
import { createMutation, createQuery, fields } from "app/utils/graphql.ts";
import { nanoid } from "local/deps/nanoid.ts";
import { array, nullable, object, string } from "local/deps/superstruct.ts";
import { createRepo, Repo } from "local/repos/mod.ts";
import { Completion, PromptCommit } from "../types/mod.ts";
import { usePromptCommitRepo } from "./use_prompt_commit_repo.ts";

/** HELPERS **/

const listQuery = createQuery({
  name: "getPrompt",
  vars: object({
    id: string(),
  }),
  output: object({
    promptCommits: array(
      object({
        ...PromptCommit.schema,
        completions: array(Completion),
      }),
    ),
  }),
  body: `
    query($id: ID!) {
      getPrompt(id: $id) {
        promptCommits {
          ${fields(PromptCommit)}
          completions {
            ${fields(Completion)}
          }
        }
      }
    }
  `,
});

const getQuery = createQuery({
  name: "getCompletion",
  vars: object({
    id: string(),
  }),
  output: Completion,
  body: `
    query($id: ID!) {
      getCompletion(id: $id) {
        ${fields(Completion)}
      }
    }
  `,
});

const createMutations = createMutation({
  name: "createCompletion",
  vars: object({
    input: object({
      promptCommitId: string(),
      variables: nullable(
        array(
          object({
            name: string(),
            value: string(),
          }),
        ),
      ),
    }),
  }),
  output: Completion,
  body: `
    mutation ($input: CreateCompletionInput!) {
      createCompletion(input: $input) {
        ${fields(Completion)}
      }
    }
  `,
});

/** MAIN **/

let repo: Repo<Completion> & {
  loadForPrompt: (promptId: string) => void;
  loadById: (id: string) => void;
  fromPromptCommit: (
    promptCommitId: string,
    variables?: { name: string; value: string }[],
  ) => Promise<Completion>;
};

export function useCompletionRepo() {
  const exec = useQuery();
  const promptCommitRepo = usePromptCommitRepo();

  if (repo) return repo;

  const legacyRepo = createRepo<Completion>({
    create: async (item) => {
      return await exec(createMutations, {
        input: {
          promptCommitId: item.promptCommitId,
          variables: item.variables,
        },
      });
    },
    update: () => {
      throw new Error("Entity cannot be updated");
    },
    remove: () => {
      throw new Error("Entity cannot be deleted");
    },
    equals: (a, b) => {
      // completions are immutable
      return a.id === b.id && a.status === b.status;
    },
  });

  const { load, merge, create } = legacyRepo;

  function loadForPrompt(promptId: string) {
    load(async () => {
      const result = await exec(listQuery, {
        id: promptId,
      });

      const promptCommits = result.promptCommits;
      const completions = promptCommits.flatMap((pc) => pc.completions);

      promptCommitRepo.merge(promptCommits);
      merge(completions);
    });
  }

  function loadById(id: string) {
    load(async () => {
      const result = await exec(getQuery, {
        id,
      });

      merge([result]);
    });
  }

  function fromPromptCommit(
    promptCommitId: string,
    variables?: { name: string; value: string }[],
  ) {
    return create({
      id: nanoid(),
      status: "pending",
      variables: variables ?? null,
      output: "",
      createdAt: new Date().toISOString(),
      updatedAt: null,
      startedAt: null,
      stoppedAt: null,
      promptCommitId,
    });
  }

  repo = {
    ...legacyRepo,
    loadForPrompt,
    loadById,
    fromPromptCommit,
  };

  return repo;
}
