import { useApiKeyRepo } from "app/features/stores/mod.ts";
import { nanoid } from "local/deps/nanoid.ts";
import { useSignal } from "local/deps/preact/signals.ts";

/** MAIN **/

export type ApiKey = {
  id: string;
  vendor: string;
  secret: string;
  createdAt: string;
};

export function useKeyEditor(props: {
  onSubmit?: () => void;
} = {}) {
  const { onSubmit } = props;

  const submitting = useSignal(false);
  const repo = useApiKeyRepo();

  const draft = {
    vendor: useSignal("openai"),
    secret: useSignal(""),
  };

  function clear() {
    draft.vendor.value = "openai";
    draft.secret.value = "";
  }

  async function submit() {
    submitting.value = true;
    await repo.create({
      id: nanoid(),
      createdAt: new Date().toISOString(),
      vendor: draft.vendor.value,
      secret: draft.secret.value,
    });
    submitting.value = false;
    onSubmit?.();
  }

  return {
    submit,
    clear,
    submitting,
    draft,
  };
}
