import { Error } from "app/components/icons/error.tsx";
import { Check } from "icons/check.tsx";
import { Age } from "local/ui/age.tsx";
import { Icon } from "local/ui/icon.tsx";
import { Spinner } from "local/ui/spinner.tsx";
import { View } from "local/ui/view.tsx";

/** MAIN **/

export function Status(props: {
  status: "running" | "success" | "error" | "pending" | "ready" | "cancelled";
  startedAt: Date;
  stoppedAt?: Date;
}) {
  const { status, startedAt, stoppedAt } = props;

  const isLoading = status === "running" || status === "pending" ||
    status === "ready";
  const isSuccess = status === "success";
  const isFailed = status === "error";
  const isCancelled = status === "cancelled";

  const duration = (() => {
    if (!stoppedAt) return null;
    const d1 = new Date(startedAt);
    const d2 = new Date(stoppedAt);
    const duration = Math.abs(d1.getTime() - d2.getTime()) / 1000;
    return duration + " " + (duration === 1 ? "second" : "seconds");
  })();

  return (
    <View class="flex gap-2">
      {isLoading && <Spinner />}
      {isSuccess && (
        <Icon title="Success" class="text-green-500">
          <Check />
        </Icon>
      )}
      {isFailed && (
        <Icon title="Failed" class="text-red-500">
          <Error />
        </Icon>
      )}
      {isCancelled && (
        <Icon title="Cancelled" class="text-yellow-500">
          <Error />
        </Icon>
      )}
      <View class="select-text">
        started{" "}
        <Age
          class="font-bold"
          time={startedAt}
        />
        {duration && (
          <>
            {" "}and finished in{" "}
            <View tag="time" class="font-bold">
              {duration}
            </View>
          </>
        )}
      </View>
    </View>
  );
}
