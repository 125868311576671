import * as config from "app/config/client.ts";
import { RouterContext } from "app/hooks/use_router.ts";
import { Dashboard } from "app/pages/dashboard.tsx";
import { Editor as FlowEditor } from "app/pages/flow/editor.tsx";
import { Editor as PromptEditor } from "app/pages/prompt/editor.tsx";
import { Account as AccountSettings } from "app/pages/settings/account.tsx";
import { Connect as ConnectSettings } from "app/pages/settings/connect.tsx";
import { Openai as OpenaiSettings } from "app/pages/settings/openai.tsx";
import { Editor as TableEditor } from "app/pages/table/editor.tsx";
import { createRouterRenderer } from "app/utils/router_renderer.ts";
import { object, string } from "local/deps/superstruct.ts";
import { useLocationHref } from "../../../hooks/use_location_href.ts";
import { Error404 } from "./error404.tsx";
import { Layout } from "./layout.tsx";

/** HELPERS **/

const pages = createRouterRenderer(
  config.root,
);

pages.add({
  path: "/dashboard",
  type: object(),
  render: () => <Dashboard />,
});

pages.add({
  path: "/settings/account",
  type: object(),
  render: () => <AccountSettings />,
});

pages.add({
  path: "/settings/openai",
  type: object(),
  render: () => <OpenaiSettings />,
});

pages.add({
  path: "/settings/connect",
  type: object(),
  render: () => <ConnectSettings />,
});

pages.add({
  path: "/prompt/:promptId",
  type: object({
    promptId: string(),
  }),
  render: (route) => {
    return (
      <PromptEditor
        promptId={route.promptId}
      />
    );
  },
});

pages.add({
  path: "/prompt/:promptId/completions/:completionId",
  type: object({
    promptId: string(),
    completionId: string(),
  }),
  render: (route) => {
    return (
      <PromptEditor
        promptId={route.promptId}
        completionId={route.completionId}
      />
    );
  },
});

pages.add({
  path: "/flow/:flowId",
  type: object({
    flowId: string(),
  }),
  render: (route) => {
    return <FlowEditor flowId={route.flowId} />;
  },
});

pages.add({
  path: "/flow/:flowId/runner/:flowRunnerId",
  type: object({
    flowId: string(),
    flowRunnerId: string(),
  }),
  render: (route) => {
    return (
      <FlowEditor
        flowId={route.flowId}
        flowRunnerId={route.flowRunnerId}
      />
    );
  },
});

pages.add({
  path: "/table/:tableId",
  type: object({
    tableId: string(),
  }),
  render: (route) => {
    return (
      <TableEditor
        tableId={route.tableId}
      />
    );
  },
});

pages.add({
  path: "/error/:code",
  type: object({
    code: string(),
  }),
  render: (route) => {
    if (route.code === "404") {
      return <Error404 />;
    }
    throw new Error(`Unhandled error code: ${route.code}`);
  },
});

/** MAIN **/

export function Router() {
  const href = useLocationHref();

  return (
    <RouterContext.Provider value={pages.router}>
      <Layout>
        {pages.render(new URL(href))}
      </Layout>
    </RouterContext.Provider>
  );
}
