import { Dashboard } from "app/pages/dashboard.tsx";
import { ReadonlySignal, useComputed } from "local/deps/preact/signals.ts";
import { Panel } from "local/ui/panel.tsx";
import { View, ViewNode } from "local/ui/view.tsx";
import { useMemorySignal } from "../../../utils/signals.ts";
import { Flows } from "./flows.tsx";
import { Prompts } from "./prompts.tsx";
import { Settings } from "./settings.tsx";
import { Tables } from "./tables.tsx";

/** MAIN **/

export function SidebarRouter(props: {
  selected: ReadonlySignal<string | undefined>;
}) {
  const { selected } = props;

  const prev = useMemorySignal(selected, "empty");

  const renderers: Record<string, () => ViewNode> = {
    dashboard: () => <Dashboard />,
    prompts: () => <Prompts />,
    flows: () => <Flows />,
    tables: () => <Tables />,
    settings: () => <Settings />,
    empty: () => <Panel gradient />,
  };

  const renderer = renderers[selected.value ?? prev.value] ?? renderers.empty;

  const isOpen = useComputed(() => {
    return selected.value != null && selected.value !== "dashboard";
  });

  return (
    <View
      class={[
        "flex flex-1",
        "relative z-40",
        "transition-all",
        !isOpen.value && "transform -translate-x-full",
      ]}
    >
      {renderer()}
    </View>
  );
}
