import { Infer, object, string } from "local/deps/superstruct.ts";

/** MAIN **/

export type ApiKey = Infer<typeof ApiKey>;

export const ApiKey = object({
  id: string(),
  createdAt: string(),
  vendor: string(),
  secret: string(),
});
