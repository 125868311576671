import { nanoid } from "../../../../packages/deps/nanoid.ts";

/** MAIN **/

export type StepType = "prompts" | "flows" | "empty";

export type State = {
  id: string;
  type: StepType;
  name: string;
};

export function fromArgs(id: string, type: StepType): State {
  return { id, type, name: "" };
}

export function fromPromptId(id: string): State {
  return fromArgs(id, "prompts");
}

export function fromFlowId(id: string): State {
  return fromArgs(id, "flows");
}

export function fromEmpty(): State {
  return fromArgs(nanoid(), "empty");
}
