/** MAIN **/

import { array, assert } from "local/deps/superstruct.ts";
import { FLOWERY_API_KEY, FLOWERY_FLOW_URLS } from "./config.ts";
import { parseEffect } from "./parse_effect.ts";
import { Effect, Execution } from "./types.ts";

export async function paginatedLoad(url: URL): Promise<Execution[]> {
  let next = url;
  const effects = [];

  while (next) {
    const effectJsonResponse = await fetch(next, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${FLOWERY_API_KEY}`,
      },
    })
      .then((result) => result.json())
      .then((body) => {
        assert(body.data, array(Execution));
        return body;
      });

    next = effectJsonResponse.links.next;
    effects.push(...effectJsonResponse.data);
  }

  return effects;
}

export async function loadEffects(): Promise<Effect[]> {
  const promises = FLOWERY_FLOW_URLS.map(paginatedLoad);

  const executions = await Promise.all(promises);

  return executions
    .flatMap((executions) => executions)
    .sort((a, b) =>
      a.attributes.createdAt.localeCompare(b.attributes.createdAt)
    )
    .filter((execution) => execution.attributes.status === "success")
    .map((execution) => execution.attributes.output as string)
    .filter(Boolean)
    .map((output) => parseEffect(output));
}
