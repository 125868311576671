import { Item } from "local/ui/item.tsx";
import { StackedBar } from "local/ui/stacked_bar.tsx";
import { View } from "local/ui/view.tsx";
import { Effect } from "../types.ts";

/** MAIN **/

export function LayerItem(props: {
  effect: Effect;
  layer: string;
  maxTotal: number;
  time: number;
  onClick: () => void;
}) {
  const { effect, layer, maxTotal, onClick } = props;

  const frames = effect.frames
    .filter((frame) => frame.layer === layer);

  const data = frames
    .map((frame) => ({
      amount: frame.duration,
      color: frame.color,
    }));

  const total = frames
    .reduce((acc, frame) => acc + frame.duration, 0);

  const cursor = (props.time % total) / total;

  return (
    <Item
      onClick={onClick}
      interactive
    >
      <View class="flex flex-col w-full gap-1 mr-2">
        <View class="flex gap-2">
          <View
            tag="h3"
            class="text-xs flex-1 text-gray-700 dark:text-gray-300"
          >
            {layer}
          </View>
        </View>
        <View
          class={[
            "w-full relative h-2",
            "bg-black rounded-sm overflow-hidden",
          ]}
        >
          <StackedBar
            total={maxTotal}
            data={data}
            normalized
          />
          <View
            class={[
              "absolute h-full w-[1px]",
            ]}
            style={{
              left: `${cursor * 100}%`,
              borderLeft: "1px solid #000",
              borderRight: "1px solid #fff",
            }}
          />
        </View>
      </View>
    </Item>
  );
}
