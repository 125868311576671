import { Table as TableIcon } from "app/components/icons/table.tsx";
import { FileAdd } from "icons/file_add.tsx";
import { MathPlus } from "icons/math_plus.tsx";
import { useSignal } from "local/deps/preact/signals.ts";
import { Chip } from "local/ui/chip.tsx";
import { Icon } from "local/ui/icon.tsx";
import { Item } from "local/ui/item.tsx";
import { List } from "local/ui/list.tsx";

/** MAIN **/

export function TableEmptyView(props: {
  onAddColumn: () => void;
  onOpenFile: () => void;
  onDropFile: (file: File) => void;
}) {
  const { onAddColumn, onOpenFile, onDropFile } = props;
  const dropping = useSignal(false);
  return (
    <List
      class={[
        "h-full",
        "border-1 rounded-lg",
        "border-dashed",
        dropping.value
          ? "border-gray-500"
          : "border-gray-300 dark:border-gray-700",
        dropping.value ? "bg-white dark:bg-black" : "",
        dropping.value
          ? "bg-opacity-10 dark:bg-opacity-10"
          : "bg-opacity-0 dark:bg-opacity-0",
        "transition-colors duration-200",
        "flex flex-col items-center justify-center",
        "text-gray-500",
      ]}
      onDrop={(event) => {
        event.preventDefault();
        dropping.value = false;
        const file = event.dataTransfer?.files[0];
        if (file) {
          onDropFile(file);
        }
      }}
      onDragOver={(event) => {
        event.preventDefault();
        dropping.value = true;
      }}
      onDragLeave={(event) => {
        event.preventDefault();
        dropping.value = false;
      }}
    >
      <Icon size={16} class="text-gray-300 dark:text-gray-700">
        <TableIcon />
      </Icon>
      <Item placeholder="This table is empty." />
      <Item>
        <Chip
          color="green-500"
          onClick={onAddColumn}
        >
          <Icon>
            <MathPlus />
          </Icon>
          Add Column
        </Chip>
        <Chip
          color="blue-500"
          onClick={onOpenFile}
        >
          <Icon>
            <FileAdd />
          </Icon>
          Upload File
        </Chip>
      </Item>
    </List>
  );
}
