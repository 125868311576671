import * as twind from "app/utils/twind.ts";
import * as React from "preact";

/** MAIN **/

export function initTwind() {
  const sheet = twind.cssomSheet();
  const instance = twind.create({
    sheet,
    darkMode: "class",
  });
  twind.install(instance);
}

export function renderPreact(options: {
  root: React.VNode;
  rootId?: string;
}) {
  const {
    root,
    rootId = "root",
  } = options;

  const rootElement = document.getElementById(rootId);

  if (!rootElement) {
    throw new Error(`Could not find root element with id "${rootId}"`);
  }

  React.render(root, rootElement);
}
