import { useEvent } from "app/hooks/event_bus.tsx";
import { usePolling } from "app/hooks/use_polling.tsx";
import { useCallback, useEffect } from "local/deps/preact/hooks.ts";
import {
  Signal,
  useComputed,
  useSignal,
  useSignalEffect,
} from "local/deps/preact/signals.ts";
import { Item } from "local/ui/item.tsx";
import { List } from "local/ui/list.tsx";
import { Panel } from "local/ui/panel.tsx";
import { Spinner } from "local/ui/spinner.tsx";
import { Tag } from "local/ui/tag.tsx";
import { View } from "local/ui/view.tsx";
import { useFlowRunners } from "../hooks/use_flow_runners.ts";
import { FlowRunnerItem } from "./flow_runner_item.tsx";

/** MAIN **/

export interface FlowRunnerListProps {
  flowId: string;
  flowRunnerId: Signal<string | null>;
}

export function FlowRunnerList(props: FlowRunnerListProps) {
  const { flowId, flowRunnerId } = props;

  const items = useFlowRunners(flowId);

  const polling = usePolling(
    items.load,
    5000,
  );

  const locals = useSignal<{
    id: string;
    createdAt: string;
    status: string;
  }[]>([]);

  const loading = useComputed(() =>
    items.loading.value && !locals.value.length
  );

  const onSelect = useCallback((id: string) => {
    flowRunnerId.value = flowRunnerId.value === id ? null : id;
  }, [flowRunnerId]);

  useSignalEffect(() => {
    locals.value = items.data.value ?? [];
  });

  const isProcessingRunners = useComputed(() =>
    locals.value?.some((c) =>
      c.status === "pending" || c.status === "running" || c.status === "ready"
    )
  );

  useEffect(() => {
    if (isProcessingRunners.value) {
      polling.start();
    } else {
      polling.stop();
    }

    return () => {
      polling.stop();
    };
  }, [isProcessingRunners.value, polling]);

  useEvent("flow-runner-create", (event) => {
    locals.value = [event.data, ...locals.value];
  });

  return (
    <Panel
      reverseGradient
      header={
        <View tag="h2" class="flex gap-2 items-center text-2xl">
          Results
          {!loading.value && locals.value?.length > 0 && (
            <span>
              <Tag class="mr-3">{locals.value?.length ?? 0}</Tag>
            </span>
          )}
        </View>
      }
    >
      <List>
        {loading.value && (
          <Item>
            <Spinner />
            <p class="text-gray-600 dark:text-gray-400 text-xs italic">
              Loading your results...
            </p>
          </Item>
        )}
        {(!loading.value && !locals.value.length)
          ? (
            <View class="text-xs italic text-gray-500">
              No results to display
            </View>
          )
          : (locals.value?.map((item) => (
            <FlowRunnerItem
              selected={item.id === flowRunnerId.value}
              key={item.id}
              createdAt={item.createdAt}
              onClick={() => onSelect(item.id)}
              promptId={item.id}
              status={item.status}
            />
          )))}
      </List>
    </Panel>
  );
}
