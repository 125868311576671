/** MAIN **/

export const FLOWERY_FLOW_URLS = [
  new URL(
    "https://99d9cc62pj.execute-api.eu-west-1.amazonaws.com/prod/v2022-02-17/workflows/0804d2ab-aef1-4990-8a12-eb71add261f1/executions",
  ),
  new URL(
    "https://99d9cc62pj.execute-api.eu-west-1.amazonaws.com/prod/v2022-02-17/workflows/35a8e491-e2a3-4c03-a778-2407ae8d77f6/executions",
  ),
];

export const FLOWERY_API_KEY = "Hn6bGDSWyEKOaK7arJt3nFUGLL7DIXzt";
