import { useHover } from "app/hooks/use_hover.ts";
import { useTheme } from "app/hooks/use_theme.ts";
import { Bulb } from "icons/bulb.tsx";
import { useSignal } from "local/deps/preact/signals.ts";
import { Chip } from "local/ui/chip.tsx";
import { Icon } from "local/ui/icon.tsx";

/** MAIN **/

export function ThemeWidget() {
  const theme = useTheme();

  const isDark = theme.name === "dark";

  const element = useSignal<HTMLElement | null>(null);
  const hovered = useSignal(false);
  useHover(element, hovered);

  return (
    <Chip
      element={element}
      onClick={theme.toggle}
      title={isDark ? "Light Mode" : "Dark Mode"}
    >
      {hovered.value && (isDark ? "Light Mode" : "Dark Mode")}
      <Icon>
        <Bulb />
      </Icon>
    </Chip>
  );
}
