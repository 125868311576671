import { debounce } from "local/deps/debounce.ts";
import { ReadonlySignal, Signal } from "local/deps/preact/signals-core.ts";
import * as Helpers from "./helpers.ts";

/** MAIN **/

export type Resource = {
  id: string;
};

export type Entity<T extends Resource> = T & {
  localId?: string;
};

export type Draft<T extends Resource> = T | Omit<T, "id">;

export type RepoStatus = {
  isLoading: boolean;
  isLoaded: boolean;
};

export type RepoItemStatus = {
  isMissing: boolean;
  isLoading: boolean;
  isCreating: boolean;
  isLoaded: boolean;
};

export type RepoState<T extends Resource> = {
  pending: number;
  data: Entity<T>[];
};

export type RepoStore<T extends Resource> = Signal<RepoState<T>>;

export type IdStore = Signal<string>;

export type RepoItemState<T extends Resource> = {
  status: RepoItemStatus;
  data?: Entity<T>;
};

export type RepoItemStore<T extends Resource> = ReadonlySignal<
  RepoItemState<T>
>;

export type RepoHandlers<T extends Resource> = {
  create: (item: T) => T | Promise<T>;
  update: (item: T) => void | Promise<void>;
  remove: (id: string) => void | Promise<void>;
  equals: (a: T, b: T) => boolean;
};

export type Repo<T extends Resource> = {
  store: RepoStore<T>;
  handlers: RepoHandlers<T>;
  mutate: (fn: (state: RepoState<T>) => void) => void;
  load: (fn: () => Promise<void>) => Promise<void>;
  create: (item: T, options?: { optimistic?: boolean }) => Promise<T>;
  update: (item: T) => Promise<void>;
  remove: (id: string) => Promise<void>;
  merge: (items: T[]) => void;
  save: (item: T) => void;
  status: (item: Entity<T> | undefined) => RepoItemStatus;
};

export function createRepo<T extends Resource>(
  handlers: RepoHandlers<T>,
): Repo<T> {
  const store = Helpers.store<T>();

  const mutate = Helpers.mutate(store);

  const merge = Helpers.merge(store, handlers);

  const load = Helpers.load(store);

  async function create(
    item: T,
    options: { optimistic?: boolean } = { optimistic: true },
  ) {
    if (options.optimistic) {
      mutate((state) => {
        state.data.push(item);
      });

      const remote = await handlers.create(item);
      mutate((state) => {
        const created = state.data.find((current) => current.id === item.id);

        if (!created) return;
        created.id = remote.id;
      });
      return remote;
    } else {
      const remote = await handlers.create(item);
      mutate((state) => {
        state.data.push(remote);
      });
      return remote;
    }
  }

  async function update(item: T) {
    await handlers.update(item);
  }

  const upload = debounce((item: T) => {
    update(item);
  }, 1000);

  function save(item: T) {
    const { data } = store.value;

    const current = data.find((current) => current.id === item.id);
    if (!current) return;

    if (handlers.equals(current, item)) {
      return;
    }

    merge([item]);

    upload(item);
  }

  async function remove(id: string) {
    mutate((state) => {
      state.data = state.data.filter((item) => item.id !== id);
    });

    await handlers.remove(id);
  }

  const status = Helpers.status(store);

  return {
    store,
    handlers,
    load: async (fn) => {
      await load(fn);
    },
    mutate,
    merge,
    create,
    update,
    remove,
    save,
    status,
  };
}
