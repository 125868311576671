import { useEffect, useMemo } from "local/deps/preact/hooks.ts";
import { createEditor, Repo, Resource } from "local/repos/mod.ts";

/** MAIN **/

export function useRepoItem<T extends Resource>(repo: Repo<T>, id: string) {
  const editor = useMemo(() => {
    return createEditor(repo, id);
  }, []);

  useEffect(() => {
    editor.id.value = id;
  }, [id]);

  return editor;
}
