/** MAIN **/

export type User = {
  id: string;
  email: string;
  idToken?: string;
  accessToken?: string;
  tokenType: string;
  expiresIn: string;
  token: string;
};

export function createLoginUrl(options: {
  base: URL;
  redirectUri: URL;
  clientId: string;
  responseType: string;
  scope: string;
}): URL {
  const { base, clientId, redirectUri, responseType, scope } = options;
  const url = new URL(base);
  url.searchParams.set("client_id", clientId);
  url.searchParams.set("response_type", responseType);
  url.searchParams.set("scope", scope);
  url.searchParams.set("redirect_uri", redirectUri.href);
  return url;
}

export function parseUser(hash: string): User | undefined {
  // If not URL params, ignore.
  let params: URLSearchParams;
  try {
    params = new URLSearchParams(hash.replace("#", ""));
  } catch (_) {
    return;
  }

  const idToken = params.get("id_token") ?? undefined;
  const accessToken = params.get("access_token") ?? undefined;
  const tokenType = params.get("token_type");
  const expiresIn = params.get("expires_in");

  // If no tokens, ignore.
  if (!idToken && !accessToken) return;
  if (!tokenType) return;
  if (!expiresIn) return;

  const idData = parseToken(idToken);
  const accessData = parseToken(accessToken);

  return {
    id: idData?.sub ?? accessData?.sub!,
    email: idData?.email ?? accessData?.email!,
    idToken,
    accessToken,
    tokenType,
    expiresIn,
    token: idToken ?? accessToken!,
  };
}

function parseToken(token?: string):
  | {
    sub: string;
    email: string;
  }
  | undefined {
  if (!token) return;
  const decoded = atob(token.split(".")[1]);
  return JSON.parse(decoded);
}
