import { useMemo } from "local/deps/preact/hooks.ts";
import { View } from "local/ui/view.tsx";
import { Effect } from "../types.ts";

/** HELPERS **/

function lerp(t: number, a: number, b: number) {
  return a + t * (b - a);
}

function uniform(a: number, b: number) {
  return lerp(Math.random(), a, b);
}

function uniformInt(a: number, b: number) {
  return Math.floor(uniform(a, b));
}

function cumulative(arr: number[]) {
  return arr.reduce((acc, n) => [...acc, acc[acc.length - 1] + n], [0]);
}

/** MAIN **/

export function Petals(props: {
  width: number;
  height: number;
  index: number;
  count: number;
  seed: number;
  time: number;
  effect: Effect;
  layer: string;
}) {
  const { width, height, index, count, seed, time, effect, layer } = props;

  const frames = effect.frames
    .filter((frame) => frame.layer === layer);

  const offsets = cumulative(frames.map((frame) => frame.duration))
    .slice(0, -1);

  const total = frames
    .reduce((acc, frame) => acc + frame.duration, 0);

  const param = time % total;
  const direction = Math.pow(-1, index % 2);
  const speed = 16 / ((count - index) + 1);
  const offset = direction * time * speed;
  const radius = 0.75 + 0.25 *
      Math.sin(
        1 / 2 * time * Math.pow(
          (index + 1) / count,
          2,
        ),
      );

  const current = frames
    .find((frame, i) => {
      return offsets[i] <= param && param < offsets[i] + frame.duration;
    });

  const petals = useMemo(() => {
    const leaves = uniformInt(2, 64);
    const petals = new Array(leaves).fill(0).map((_, i) => i);

    const minRadius = 120;
    const maxRadius = Math.max(
      Math.min(width, height) / 2 * 0.75,
      minRadius + 100,
    );
    const innerRadius = uniform(minRadius, maxRadius);
    const outerRadius = uniform(innerRadius, maxRadius);
    const centerRadius = (innerRadius + outerRadius) / 2;

    const radialRadius = (outerRadius - innerRadius) / 2;

    const minAngularRadius = 0;
    const maxAngularRadius = Math.min(centerRadius * Math.PI / leaves, 5);
    const angularRadius = uniform(minAngularRadius, maxAngularRadius);

    const smallerRadius = Math.min(radialRadius, angularRadius);
    const largerRadius = Math.max(radialRadius, angularRadius);
    const aspectRatio = largerRadius / smallerRadius;
    const forceRound = aspectRatio < 3.5;

    const radii = {
      radial: forceRound ? smallerRadius : radialRadius,
      angular: forceRound ? smallerRadius : angularRadius,
    };

    return petals.map((i) => {
      const angle = (i / leaves) * Math.PI * 2;
      const x = Math.cos(angle) * centerRadius;
      const y = Math.sin(angle) * centerRadius;
      const tilt = angle * (180 / Math.PI) - 90;
      const transform = `translate(${x}, ${y}) rotate(${tilt})`;

      return {
        rx: radii.angular,
        ry: radii.radial,
        index: i,
        transform,
      };
    });
  }, [width, height, seed]);

  const centerTransform = `translate(${width / 2}, ${height / 2})`;
  const layerTransform = `scale(${radius}) rotate(${offset})`;

  return (
    <View tag="g" transform={centerTransform}>
      <View tag="g" transform={layerTransform}>
        {petals.map((petal) => {
          return (
            <View
              tag="g"
              key={petal.index}
              transform={petal.transform}
            >
              <View
                tag="ellipse"
                rx={petal.rx}
                ry={petal.ry}
                fill={current?.color}
                class="transition-colors duration-100"
              />
            </View>
          );
        })}
      </View>
    </View>
  );
}
