import { Home } from "app/components/icons/home.tsx";
import { Settings as SettingsIcon } from "app/components/icons/settings.tsx";
import { Table as TablesIcon } from "app/components/icons/table.tsx";
import { useRouter } from "app/hooks/use_router.ts";
import { FormatText } from "icons/format_text.tsx";
import { PlayList } from "icons/play_list.tsx";
import { useCallback, useEffect } from "local/deps/preact/hooks.ts";
import { useComputed, useSignal } from "local/deps/preact/signals.ts";
import { View } from "local/ui/view.tsx";
import { useLocationHref } from "../../../hooks/use_location_href.ts";
import { useOpenaiStatus } from "../hooks/use_openai_status.ts";
import { SidebarGlyph } from "./sidebar_glyph.tsx";
import { SidebarItem } from "./sidebar_item.tsx";
import { SidebarRouter } from "./sidebar_router.tsx";

/** MAIN **/

export function Sidebar(props: {
  width: number;
}) {
  const { width } = props;

  const router = useRouter();
  const href = useLocationHref();

  const selected = useSignal<string | undefined>(undefined);

  const select = useCallback((value: string | undefined) => {
    selected.value = selected.value === value ? undefined : value;
  }, []);

  const openaiStatus = useOpenaiStatus();

  const settingsIndicator = useComputed(() => {
    const status = openaiStatus.value;
    const problem = ["missing", "invalid"].includes(status);
    return problem ? "yellow" : undefined;
  });

  useEffect(() => {
    if (selected.value !== undefined) return;

    const match = router.match(href);

    if (match?.path.startsWith("/prompt") && selected.value !== "prompts") {
      select("prompts");
    } else if (match?.path.startsWith("/flow") && selected.value !== "flows") {
      select("flows");
    } else if (
      match?.path.startsWith("/settings") && selected.value !== "settings"
    ) {
      select("settings");
    }
  }, [href, select, router]);

  return (
    <View
      class={[
        "flex flex-row-reverse",
      ]}
      style={{
        width: `${width}px`,
      }}
    >
      <SidebarRouter selected={selected} />
      <View
        class={[
          "relative z-50",
          "w-20 h-full py-6",
          "border-r border-gray-200 dark:border-gray-800",
          "bg-gray-100 dark:bg-gray-900",
          "transition-all",
          "flex flex-col gap-4 items-center justify-center",
        ]}
      >
        <SidebarItem
          tag="a"
          href={router.href("dashboard")}
          onClick={() => {
            select(undefined);
          }}
        >
          <SidebarGlyph
            label="Home"
            icon={<Home />}
          />
        </SidebarItem>
        <View class="flex-1" />
        <SidebarItem
          selected={selected.value === "prompts"}
          onClick={() => select("prompts")}
        >
          <SidebarGlyph
            label="Prompts"
            icon={<FormatText />}
          />
        </SidebarItem>
        <SidebarItem
          selected={selected.value === "flows"}
          onClick={() => select("flows")}
        >
          <SidebarGlyph
            label="Flows"
            icon={<PlayList />}
          />
        </SidebarItem>
        <SidebarItem
          selected={selected.value === "tables"}
          onClick={() => select("tables")}
        >
          <SidebarGlyph
            label="Tables"
            iconSize={5}
            icon={<TablesIcon />}
          />
        </SidebarItem>
        <View class="flex-1" />
        <SidebarItem
          selected={selected.value === "settings"}
          onClick={() => select("settings")}
        >
          <SidebarGlyph
            label="Settings"
            icon={<SettingsIcon />}
            statusColor={settingsIndicator.value}
          />
        </SidebarItem>
      </View>
    </View>
  );
}
