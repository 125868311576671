import { Icon } from "local/ui/icon.tsx";
import { View, ViewNode } from "local/ui/view.tsx";

/** MAIN **/

export function ScrollHelper(props: {
  onClick: () => void;
  label: string;
  icon: ViewNode;
  visible: boolean;
}) {
  const { label, icon, visible, onClick } = props;
  return (
    <View
      class={[
        "flex relative z-20",
        visible ? "opacity-0 pointer-events-none" : "opacity-100",
        "transition-opacity",
      ]}
    >
      <View class="px-4 flex-1">
        <View
          onClick={onClick}
          class={[
            "cursor-pointer",
            "w-full",
            "transition-opacity",
            "border-b-1 border-gray-300 dark:border-gray-700",
          ]}
        />
      </View>
      <View class="absolute inset-0 flex items-center justify-center">
        <View
          onClick={onClick}
          title={label}
          class={[
            "cursor-pointer",
            "text-gray-500",
            "flex items-center justify-center w-6 h-6",
            "rounded-full",
            "bg-gray-300 dark:bg-gray-700",
          ]}
        >
          <Icon size={3}>
            {icon}
          </Icon>
        </View>
      </View>
    </View>
  );
}
