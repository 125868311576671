import { useFocus } from "app/hooks/use_focus.ts";
import { useHover } from "app/hooks/use_hover.ts";
import { Trash } from "icons/trash.tsx";
import { Signal, useComputed, useSignal } from "local/deps/preact/signals.ts";
import { Chip } from "./chip.tsx";
import { Icon } from "./icon.tsx";

/** MAIN **/

export function TrashButton(props: {
  onTrash: () => void;
  parentHover?: Signal<boolean>;
  title?: string;
  confirm?: string;
}) {
  const { onTrash, parentHover, confirm, title } = props;

  const element = useSignal<HTMLElement | null>(null);

  const hovered = useSignal(false);
  const focused = useSignal(false);

  useHover(element, hovered);
  useFocus(element, focused);

  const hidden = useComputed(() => {
    const visibleValue = parentHover?.value ?? true;
    const hoverValue = hovered.value;
    const focusValue = focused.value;
    return !(visibleValue || hoverValue || focusValue);
  });

  return (
    <Chip
      title={title}
      class={[
        hidden.value ? "opacity-0" : "opacity-100",
      ]}
      onClick={() => {
        let confirmed = true;
        if (confirm) {
          confirmed = window.confirm(confirm);
        }
        if (confirmed) {
          onTrash();
        }
      }}
    >
      <Icon>
        <Trash />
      </Icon>
    </Chip>
  );
}
