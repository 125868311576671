import * as config from "app/config/client.ts";
import { Lily } from "app/features/app/components/lily.tsx";
import { User } from "icons/user.tsx";
import { useEffect, useState } from "local/deps/preact/hooks.ts";
import { Chip } from "local/ui/chip.tsx";
import { Icon } from "local/ui/icon.tsx";
import { View } from "local/ui/view.tsx";
import { useMessage } from "../hooks/use_message.ts";

/** MAIN **/

export function Home() {
  const loginUrl = config.auth.url;
  const message = useMessage();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(true);
  }, []);

  return (
    <View
      class={[
        "absolute inset-0",
        "flex flex-col items-center justify-center",
        "w-full h-full",
        "text-gray-700 dark:text-gray-300",
      ]}
    >
      <Lily open={open} radius={0.75} />

      <View class="absolute top-0 right-0 flex m-4">
        <Chip href={loginUrl.href}>
          Login
          <Icon>
            <User />
          </Icon>
        </Chip>
      </View>
    </View>
  );
}
