import { useQuery } from "app/hooks/graphql.ts";
import { createMutation, fields } from "app/utils/graphql.ts";
import { nanoid } from "local/deps/nanoid.ts";
import { array, number, object, string } from "local/deps/superstruct.ts";
import { createRepo, Repo } from "local/repos/mod.ts";
import { Prompt, PromptCommit } from "../types/mod.ts";

/** HELPERS **/

const CreatePromptCommitInput = object({
  prompt: object({
    id: string(),
    name: string(),
    body: string(),
    parameters: object({
      temperature: number(),
      maxTokens: number(),
      stopSequences: array(string()),
      frequencyPenalty: number(),
      presencePenalty: number(),
      mode: string(),
      model: string(),
    }),
  }),
  message: string(),
});

const createMutations = createMutation({
  name: "createPromptCommit",
  vars: object({
    input: CreatePromptCommitInput,
  }),
  output: PromptCommit,
  body: `
    mutation ($input: CreatePromptCommitInput!) {
      createPromptCommit(input: $input) {
        ${fields(PromptCommit)}
      }
    }
  `,
});

/** MAIN **/

let repo: Repo<PromptCommit>;

export function usePromptCommitRepo() {
  const exec = useQuery();

  if (repo) return repo;

  repo = createRepo({
    create: (item) => {
      return exec(createMutations, {
        input: {
          prompt: {
            id: item.promptId,
            name: item.promptSnapshot.name,
            body: item.promptSnapshot.body,
            parameters: item.promptSnapshot.parameters,
          },
          message: "Auto-generated commit",
        },
      });
    },
    update: () => {
      throw new Error("Entity cannot be updated");
    },
    remove: () => {
      throw new Error("Entity cannot be deleted");
    },
    equals: (a, b) => {
      // completions are immutable
      return a.id === b.id;
    },
  });

  function createForPrompt(prompt: Prompt, message: string) {
    const item: PromptCommit = {
      id: nanoid(),
      createdAt: new Date().toISOString(),
      promptId: prompt.id,
      message,
      promptSnapshot: {
        name: prompt.name,
        body: prompt.body,
        parameters: prompt.parameters,
      },
    };
    repo.merge([item]);
    return item;
  }

  return {
    ...repo,
    createForPrompt,
  };
}
