import {
  computed,
  ReadonlySignal,
  Signal,
  signal,
} from "local/deps/preact/signals-core.ts";
import { Draft, Entity, Repo, Resource } from "./repo.ts";

/** MAIN **/

export type EditorStatus = {
  isMissing: boolean;
  isLoading: boolean;
  isCreating: boolean;
  isLoaded: boolean;
};

export type EditorState<T extends Resource> = {
  status: EditorStatus;
  data: Entity<T> | undefined;
};

export type Editor<T extends Resource> = {
  id: Signal<string>;
  store: ReadonlySignal<EditorState<T>>;
  create: (item: Draft<T>) => Promise<T>;
  update: (item: Draft<T>) => Promise<void>;
  remove: () => Promise<void>;
  save: (item: Draft<T>) => void;
};

export function createEditor<T extends Resource>(
  repo: Repo<T>,
  initialId: string,
): Editor<T> {
  const id = signal(initialId);

  const store = computed<EditorState<T>>(() => {
    const data = repo.store.value.data.find((i) => i.id === id.value);
    return {
      data,
      status: repo.status(data ?? { id: id.value } as T),
    };
  });

  function create(draft: Draft<T>) {
    return repo.create({
      ...draft,
      id: id.value,
    } as T);
  }

  function update(draft: Draft<T>) {
    return repo.update({
      ...draft,
      id: id.value,
    } as T);
  }

  function remove() {
    return repo.remove(id.value);
  }

  function save(draft: Draft<T>) {
    repo.save({
      ...draft,
      id: id.value,
    } as T);
  }

  return {
    id,
    store,
    update,
    create,
    remove,
    save,
  };
}
