import { useEffect } from "local/deps/preact/hooks.ts";
import { useSignal } from "local/deps/preact/signals.ts";

/** MAIN **/

export function useTime() {
  const time = useSignal(0);

  useEffect(() => {
    const start = Date.now();
    let running = true;
    const loop = () => {
      if (!running) return;
      time.value = (Date.now() - start) / 1000;
      requestAnimationFrame(loop);
    };

    loop();

    return () => {
      running = false;
    };
  }, []);

  return time; // seconds
}
