import { TableEditor } from "app/features/tables/mod.ts";
import { Toolbar } from "app/features/toolbar/mod.ts";
import { View } from "local/ui/view.tsx";

export function Editor(props: {
  tableId: string;
}) {
  return (
    <View class="flex flex-1 relative">
      <View class="absolute left-0 top-0 bottom-0 right-12">
        <TableEditor
          id={props.tableId}
        />
      </View>
      <Toolbar />
    </View>
  );
}
