import { View, ViewProps, ViewTag } from "./view.tsx";

/** MAIN **/

export type FieldColor = "gray" | "blue" | "green" | "red" | "yellow";

export type FieldProps<T extends ViewTag> = ViewProps<T> & {
  disabled?: boolean;
  placeholder?: string;
  value?: string;
  invisible?: boolean;
  color?: string;
};

export function Field<T extends ViewTag>(props: FieldProps<T>) {
  const {
    invisible,
    color = "gray",
    style,
    ...viewProps
  } = props;

  const border = color.startsWith("#")
    ? `border-[${color}] dark:border-[${color}]`
    : `border-${color}-400 dark:border-${color}-600`;

  return (
    <View
      viewProps={viewProps}
      class={[
        "py-3 px-4 border-1",
        "leading-loose",
        "whitespace-pre-wrap break-all",
        !invisible && "transition-all duration-200 ease-in-out",
        "rounded-md",
        "bg-transparent outline-none resize-none",
        invisible ? "border-transparent" : border,
        props.disabled && !invisible && "cursor-not-allowed text-gray-500",
        "placeholder-gray-500",
        "flex-shrink-0",
      ]}
      style={{
        fontSize: "inherit",
        lineHeight: "inherit",
        fontFamily: "inherit",
        ...style,
      }}
    />
  );
}
