import { usePromptRepo } from "app/features/stores/mod.ts";
import { useLocationHref } from "app/hooks/use_location_href.ts";
import { navigateTo, useRouter } from "app/hooks/use_router.ts";
import { FormatText } from "icons/format_text.tsx";
import { MathPlus } from "icons/math_plus.tsx";
import { Trash } from "icons/trash.tsx";
import { nanoid } from "local/deps/nanoid.ts";
import { useSignal } from "local/deps/preact/signals.ts";
import { Icon } from "local/ui/icon.tsx";
import { Item } from "local/ui/item.tsx";
import { List } from "local/ui/list.tsx";
import { Panel } from "local/ui/panel.tsx";
import { Spinner } from "local/ui/spinner.tsx";
import { Tag } from "local/ui/tag.tsx";
import { View } from "local/ui/view.tsx";

/** MAIN **/

export function Prompts() {
  const repo = usePromptRepo();

  const router = useRouter();

  const creating = useSignal(false);

  const href = useLocationHref();

  async function onCreate() {
    creating.value = true;
    const data = await repo.create({
      id: nanoid(),
      name: "New Prompt",
      body: "",
      parameters: {
        temperature: 0.7,
        maxTokens: 256,
        stopSequences: [],
        frequencyPenalty: 0,
        presencePenalty: 0,
        mode: "chat",
        model: "gpt-3.5-turbo",
      },
    });
    creating.value = false;
    navigateTo(router.url("prompt", data.id));
  }

  const { data, pending } = repo.store.value;
  const loading = pending > 0;

  async function onTrash(id: string) {
    const confirmed = window.confirm("Delete this prompt?");
    if (confirmed) {
      const match = router.match(href);
      if (match?.data?.promptId === id) {
        navigateTo(router.url("prompt"));
      }

      await repo.remove(id);
    }
  }

  // NOTE: Sort on copy.
  const items = data.slice().sort((a, b) => a.name.localeCompare(b.name));

  return (
    <Panel
      gradient
      header={
        <View tag="h2" class="flex gap-2 items-center text-2xl">
          Prompts
          {!loading && data.length > 0 && (
            <span>
              <Tag class="mr-3">{data.length}</Tag>
            </span>
          )}
        </View>
      }
    >
      <List>
        <Item
          interactive
          color="green"
          onClick={onCreate}
          disabled={creating.value}
        >
          {!creating.value && (
            <Icon>
              <MathPlus />
            </Icon>
          )}
          {creating.value && <Spinner />}
          Create Prompt
        </Item>
        {loading && (
          <Item>
            <Spinner />
            <p class="text-gray-600 dark:text-gray-400 text-xs italic">
              Loading your prompts...
            </p>
          </Item>
        )}
        {items.map((item) => {
          const itemHref = router.href("prompt", item.id);
          return (
            <Item
              id={item.id}
              key={item.id}
              title={item.name}
              onSecondaryAction={() => onTrash(item.id)}
              selected={itemHref === href}
              interactive
              href={itemHref}
              icon={<FormatText />}
              secondaryIcon={<Trash />}
              placeholder="Untitled"
            />
          );
        })}
      </List>
    </Panel>
  );
}
