import { Signal, useSignalEffect } from "local/deps/preact/signals.ts";

/** MAIN **/

export type DropPosition = "above" | "below" | "inside" | undefined;

export function useDropTarget(props: {
  element: Signal<HTMLElement | null>;
  dropping: Signal<DropPosition>;
  onDrop: (data: string) => void;
}) {
  const { dropping, element } = props;

  function handleDragOver(e: DragEvent) {
    e.preventDefault();

    const target = element.value;
    if (!target) {
      dropping.value = undefined;
      return;
    }

    const y = e.clientY;
    const { top, height } = target.getBoundingClientRect();
    const fraction = 0.2;
    const isAbove = y < top + height * fraction;
    const isBelow = y > top + height * (1 - fraction);
    dropping.value = isAbove ? "above" : isBelow ? "below" : "inside";
  }

  function handleDragLeave(e: DragEvent) {
    e.preventDefault();
    dropping.value = undefined;
  }

  function handleDrop(e: DragEvent) {
    e.preventDefault();
    const dataTransfer = e.dataTransfer;
    if (!dataTransfer) return;
    const data = dataTransfer.getData("text/plain");
    if (!data) return;
    props.onDrop(data);
  }

  useSignalEffect(() => {
    const el = element.value;
    if (!el) return;
    el.addEventListener("dragover", handleDragOver);
    el.addEventListener("dragleave", handleDragLeave);
    el.addEventListener("drop", handleDrop);
    return () => {
      el.removeEventListener("dragover", handleDragOver);
      el.removeEventListener("dragleave", handleDragLeave);
      el.removeEventListener("drop", handleDrop);
    };
  });
}
