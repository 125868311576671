import { Panel } from "./panel.tsx";
import { Spinner } from "./spinner.tsx";
import { View, ViewChildren } from "./view.tsx";

/** MAIN **/

export function EditorPanel(props: {
  title: string;
  onTitleChange: (title: string) => void;
  loading?: boolean;
  loadingMessage?: string;
  children: ViewChildren;
  fullWidth?: boolean;
  fullHeight?: boolean;
}) {
  const {
    title,
    fullWidth,
    fullHeight,
    onTitleChange,
    loading,
    loadingMessage,
    children,
  } = props;

  return (
    <Panel
      fullWidth={fullWidth}
      fullHeight={fullHeight}
      isBodyHidden={loading}
      header={loading
        ? (
          <View class="flex items-center gap-4 text-lg text-gray-500">
            <Spinner size={8} />
            {loadingMessage}
          </View>
        )
        : (
          <View
            tag="input"
            class={[
              "absolute inset-0",
              "px-8",
              "bg-transparent border-none outline-none",
              "placeholder-gray-400 dark:placeholder-gray-600",
            ]}
            placeholder="Enter a name"
            value={title}
            onInput={(e) => {
              onTitleChange(e.currentTarget.value);
            }}
          />
        )}
    >
      {children}
    </Panel>
  );
}
