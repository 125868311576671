import { Die20 } from "app/components/icons/die20.tsx";
import { Flower as FlowerIcon } from "app/components/icons/flower.tsx";
import { useTime } from "app/hooks/use_time.tsx";
import { useEffect } from "local/deps/preact/hooks.ts";
import {
  useComputed,
  useSignal,
  useSignalEffect,
} from "local/deps/preact/signals.ts";
import { Blockquote } from "local/text/blockquote.tsx";
import { Animated } from "local/ui/animated.tsx";
import { Chip } from "local/ui/chip.tsx";
import { Icon } from "local/ui/icon.tsx";
import { List } from "local/ui/list.tsx";
import { ListDivider } from "local/ui/list_divider.tsx";
import { Panel } from "local/ui/panel.tsx";
import { View } from "local/ui/view.tsx";
import { createEffect } from "../create_effect.ts";
import { loadEffects } from "../load_effects.ts";
import { Effect } from "../types.ts";
import { Layers } from "./layer_list.tsx";
import { Switcher } from "./switcher.tsx";
import { Visuals } from "./visuals.tsx";

/** HELPERS **/

const _TEST_EFFECT: Effect = {
  description: "test effect",
  frames: [{
    duration: 1,
    layer: "background",
    color: "#ff0000",
  }, {
    duration: 1,
    layer: "background",
    color: "#00ff00",
  }, {
    duration: 0.5,
    layer: "foreground",
    color: "#0000ff",
  }, {
    duration: 0.5,
    layer: "foreground",
    color: "#000000",
  }, {
    duration: 0.5,
    layer: "foreground",
    color: "#00ffff",
  }],
};

/** MAIN **/

export function Flower(props: {
  hidden: boolean;
}) {
  const { hidden } = props;

  const seed = useSignal<number>(0);
  const index = useSignal<number>(0);
  const effects = useSignal<Effect[]>([]);
  const creating = useSignal(false);
  const input = useSignal("");
  const time = useTime();

  useEffect(() => {
    loadEffects().then((value) => {
      effects.value = value;
      index.value = effects.value.length - 1;
    });
  }, []);

  const effect = useComputed<Effect>(() => {
    if (effects.value.length === 0) return _TEST_EFFECT;
    return effects.value[index.value];
  });

  useSignalEffect(() => {
    seed.value = index.value;
  });

  function refresh() {
    seed.value = seed.value + 1;
  }

  function onCreate() {
    if (creating.value) return;
    creating.value = true;
    createEffect(input.value).then((value) => {
      effects.value = [...effects.value, value];
      index.value = effects.value.length - 1;
      creating.value = false;
    });
  }

  return (
    <View
      class={[
        "absolute inset-0",
        "flex items-center justify-center",
      ]}
    >
      <Visuals
        effect={effect}
        hidden={hidden}
        seed={seed.value}
        time={time}
      />
      <View
        class={[
          "absolute right-[5rem] w-[300px] h-full",
        ]}
      >
        <Panel
          header={
            <List class="w-full">
              <Switcher
                count={effects.value.length}
                index={index}
              />
            </List>
          }
        >
          <List>
            <Animated>
              <Blockquote class="text-xs text-gray-700 dark:text-gray-300 select-text">
                {effect.value.description}
              </Blockquote>
            </Animated>
            <ListDivider hidden />
            <Animated>
              <Layers
                effect={effect}
                time={time}
              />
            </Animated>
            <ListDivider hidden />
            <View class="py-2 px-2 flex gap-2 h-12 items-center">
              <Chip
                title="Randomize"
                class="text-xs"
                onClick={refresh}
              >
                <Icon>
                  <Die20 />
                </Icon>
              </Chip>
              {!creating.value && (
                <Chip
                  title="Grow a flower"
                  onClick={onCreate}
                  color="green-500"
                >
                  <Icon>
                    <FlowerIcon />
                  </Icon>
                  Generate new
                </Chip>
              )}
              {creating.value && (
                <Chip
                  title="Flower is growing..."
                  disabled
                >
                  <Icon class="animate-spin">
                    <FlowerIcon />
                  </Icon>
                </Chip>
              )}
            </View>
            <View
              tag="textarea"
              value={input.value}
              onInput={(e) => {
                input.value = e.currentTarget.value;
              }}
              placeholder="Describe a colorful effect"
              class={[
                "my-1",
                "w-full",
                "py-2 px-3 border-1",
                "font-mono text-xs leading-loose",
                "whitespace-pre-wrap break-all",
                "rounded-md",
                "border-1 rounded-md",
                "border-gray-400 dark:border-gray-600",
                "bg-transparent outline-none",
                "placeholder-gray-400 dark:placeholder-gray-600",
              ]}
            />
          </List>
        </Panel>
      </View>
    </View>
  );
}
