import {
  array,
  Infer,
  number,
  object,
  string,
} from "local/deps/superstruct.ts";

/** MAIN **/

export type Prompt = Infer<typeof Prompt>;

export const Prompt = object({
  id: string(),
  name: string(),
  body: string(),
  parameters: object({
    temperature: number(),
    maxTokens: number(),
    stopSequences: array(string()),
    frequencyPenalty: number(),
    presencePenalty: number(),
    mode: string(),
    model: string(),
  }),
});
