import { FunctionalComponent } from "preact";
import { createPortal } from "preact/compat";

interface PortalProps {
  target: HTMLElement | null;
}

const Portal: FunctionalComponent<PortalProps> = ({ target, children }) =>
  target ? createPortal(<>{children}</>, target) : null;

export default Portal;
