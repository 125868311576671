import {
  ReadonlySignal,
  useSignal,
  useSignalEffect,
} from "local/deps/preact/signals.ts";

/** MAIN **/

/**
 * Creates a signal that will be initialized with the given value and will
 * keep the last value until the next non-undefined value is set.
 */
export function useMemorySignal<T>(
  input: ReadonlySignal<T | undefined>,
  initialValue: T,
) {
  const memory = useSignal(initialValue);

  useSignalEffect(() => {
    const newValue = input.value;
    if (newValue !== undefined) {
      memory.value = newValue;
    }
  });

  return memory;
}
