import * as Graphql from "app/utils/graphql.ts";
import { createContext } from "local/deps/preact.ts";
import { useContext } from "local/deps/preact/hooks.ts";
import { usePromiseGuard } from "./guard.ts";
import { usePromise } from "./use_promise.ts";

/** MAIN **/

export type GraphqlContext = Graphql.Context;

export type GraphqlExec = <I, O>(
  query: Graphql.Query<I, O>,
  args?: I,
) => Promise<O>;

export const GraphqlContext = createContext<GraphqlContext | undefined>(
  undefined,
);

export function useQuery(): GraphqlExec {
  const context = useContext(GraphqlContext);
  if (!context) {
    throw new Error("useGraphql must be used within a GraphqlProvider");
  }
  const guard = usePromiseGuard();

  return (query, args) => {
    const { exec } = Graphql;
    const promise = exec(context, { query, args });
    if (query.guarded) guard(promise);
    return promise;
  };
}

export function useQuerySignal<I, O>(query: Graphql.Query<I, O>, args?: I) {
  const exec = useQuery();
  return usePromise(() => exec(query, args), [query, args]);
}
