import { useCallback, useEffect } from "local/deps/preact/hooks.ts";
import { useSignal } from "local/deps/preact/signals.ts";

/** MAIN **/

export function usePromise<T>(fn: () => Promise<T>, deps: unknown[]) {
  const loading = useSignal(true);
  const error = useSignal<Error | undefined>(undefined);

  const data = useSignal<T | undefined>(undefined);

  const load = useCallback(() => {
    loading.value = true;
    error.value = undefined;
    fn()
      .then((result) => {
        data.value = result;
      })
      .catch((error) => {
        error.value = error;
        data.value = undefined;
        console.error(error);
      })
      .finally(() => {
        loading.value = false;
      });
  }, deps);

  useEffect(() => {
    load();
  }, []);

  return {
    loading,
    error,
    data,
    load,
  };
}
