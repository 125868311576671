import { View } from "./view.tsx";

/** HELPERS **/

function cumulative(arr: number[]) {
  return arr.reduce((acc, n) => [...acc, acc[acc.length - 1] + n], [0]);
}

/** MAIN **/

export function StackedBar(props: {
  normalized: boolean;
  total?: number;
  data: {
    amount: number;
    color: string;
  }[];
}) {
  const { normalized, total, data } = props;

  const sum = data.reduce((acc, { amount }) => acc + amount, 0);
  const scale = normalized ? sum : (total ?? sum);
  const widths = data.map(({ amount }) => amount / scale);
  const lefts = cumulative(widths).slice(0, -1);

  return (
    <>
      {data.map((segment, i) => (
        <View
          tag="span"
          class="absolute top-0 left-0 h-full"
          style={{
            left: `${lefts[i] * 100}%`,
            width: `${widths[i] * 100}%`,
            backgroundColor: segment.color,
          }}
        />
      ))}
    </>
  );
}
