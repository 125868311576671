import { md } from "local/markdown/md.ts";
import { Markdown } from "local/markdown/mod.ts";
import { View } from "local/ui/view.tsx";
import { UserApiKeyWidget } from "./user-api-key-widget.tsx";

const source = () =>
  md`
## FloweryAI API Key

The FloweryAI API key is used to authenticate and authorize access to FloweryAI's APIs.
It enables you to start workflows securely from external sources, such as PipeDream or Zapier.

Whenver you execute a flow from an external source, you have to add this API key to the headers as follows:&nbsp;&nbsp;\`Authorization: Bearer <your-api-key>\`

${<UserApiKeyWidget />}
`;

export function UserApiKeySettings() {
  return (
    <View class="flex flex-col gap-6">
      <Markdown root={source()} />
    </View>
  );
}
