import { useHover } from "app/hooks/use_hover.ts";
import { ArrowDown } from "icons/arrow_down.tsx";
import { ArrowUp } from "icons/arrow_up.tsx";
import { useSignal, useSignalEffect } from "local/deps/preact/signals.ts";
import { View, ViewNode, ViewProps } from "local/ui/view.tsx";
import { ScrollHelper } from "./scroll_helper.tsx";

/** MAIN **/

export function ScrollView(
  props: ViewProps<"section"> & {
    raised?: boolean;
    header?: ViewNode;
    footer?: ViewNode;
    isBodyHidden?: boolean;
    gradient?: boolean;
    reverseGradient?: boolean;
    largePadding?: boolean;
  },
) {
  const {
    raised,
    header,
    footer,
    isBodyHidden,
    children,
    reverseGradient,
    gradient,
    largePadding,
    ...viewProps
  } = props;

  const container = useSignal<HTMLElement | null>(null);
  const hovered = useSignal(false);
  useHover(container, hovered);

  const scrollTop = useSignal(true);

  const scrollEnd = useSignal(true);

  const contentRect = useSignal<DOMRect | undefined>(undefined);

  const showScrollBar = hovered.value && !(scrollEnd.value && scrollTop.value);

  function onScroll() {
    const element = container.value;
    if (!element) return;

    setTimeout(() => {
      const offset = element.scrollTop;
      const available = element.scrollHeight;
      const visible = element.clientHeight;
      scrollTop.value = offset === 0;
      scrollEnd.value = offset + visible >= available;
    }, 100);
  }

  useSignalEffect(() => {
    contentRect.value;
    onScroll();
  });

  useSignalEffect(() => {
    const element = container.value;
    if (!element) return;
    onScroll();
    element.addEventListener("scroll", onScroll);
    return () => {
      element.removeEventListener("scroll", onScroll);
    };
  });

  function scrollTo(offset: number) {
    const element = container.value;
    if (!element) return;
    element.scrollTo({
      top: offset,
      behavior: "smooth",
    });
  }

  function scrollToBottom() {
    const element = container.value;
    if (!element) return;
    scrollTo(element.scrollHeight);
  }

  function scrollToTop() {
    scrollTo(0);
  }

  return (
    <View
      viewProps={viewProps}
      class={[
        "absolute inset-0 flex flex-col",
      ]}
    >
      <ScrollHelper
        icon={<ArrowUp />}
        label="Scroll to top"
        visible={scrollTop.value}
        onClick={scrollToTop}
      />
      <View
        element={container}
        class={[
          "flex-1 relative",
          "flex flex-col py-6 px-8",
          "overflow-y-auto",
          showScrollBar ? "pr-6 overflow-y-scroll" : "pr-8 overflow-y-hidden",
          isBodyHidden && "opacity-0 pointer-events-none",
          "transition-opacity",
        ]}
      >
        <View rect={contentRect}>
          {children}
        </View>
      </View>
      <ScrollHelper
        icon={<ArrowDown />}
        label="Scroll to bottom"
        visible={scrollEnd.value}
        onClick={scrollToBottom}
      />
    </View>
  );
}
