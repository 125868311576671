import { Error } from "app/components/icons/error.tsx";
import { Ghost } from "icons/ghost.tsx";
import { Age } from "local/ui/age.tsx";
import { Icon } from "local/ui/icon.tsx";
import { Item } from "local/ui/item.tsx";
import { Spinner } from "local/ui/spinner.tsx";

/** MAIN **/

export interface FlowRunnerItem {
  promptId: string;
  createdAt: string;
  status: string;
  selected: boolean;
  onClick: () => void;
}

export function FlowRunnerItem(props: FlowRunnerItem) {
  const {
    createdAt,
    status,
    onClick,
    selected,
  } = props;

  const loading = status === "pending" || status === "running" ||
    status === "ready";

  return (
    <Item
      selected={selected}
      interactive
      onClick={onClick}
    >
      {loading && <Spinner />}
      {status === "success" && (
        <Icon>
          <Ghost />
        </Icon>
      )}
      {status === "error" && (
        <Icon title="Flow failed" class="text-red-500">
          <Error />
        </Icon>
      )}
      <Age time={new Date(createdAt)}></Age>
    </Item>
  );
}
