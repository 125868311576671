import { useComputed } from "local/deps/preact/signals.ts";

/** HELPERS **/

const messages = [
  "connect computational cortices",
  "harness emergent intelligence",
  "build the future of workflows",
  "transform ideas into action",
  "automate decision-making",
  "unite the machine hive",
  "orchestrate mechanical cognition",
  "ascend to autopilot heaven",
  "dream up a new universe",
  "cognitive symphonies achieved",
  "enliven robotic potential",
  "blend mind and machine",
  "problem-solving revolutionized",
  "weave thought threads",
];

/** MAIN **/

export function useMessage() {
  return useComputed(() => {
    return messages[Math.floor(Date.now() / 60_000) % messages.length];
  });
}
