import { Sidebar } from "app/features/sidebar/mod.ts";
import { useLocationHref } from "app/hooks/use_location_href.ts";
import { useRouter } from "app/hooks/use_router.ts";
import { Flower } from "local/brightbeats/components/flower.tsx";
import { useMemo } from "local/deps/preact/hooks.ts";
import { MarkdownContext } from "local/markdown/context.ts";
import { DefaultTheme } from "local/markdown/theme.tsx";
import { View, ViewChildren } from "local/ui/view.tsx";

/** MAIN **/

export function Layout(props: {
  children?: ViewChildren;
}) {
  const { children } = props;
  const sidebarWidth = 380;
  const href = useLocationHref();

  const router = useRouter();
  const isDashboardOpen = router.href("dashboard") === href;

  const sidebar = useMemo(() => {
    return (
      <Sidebar
        width={sidebarWidth}
      />
    );
  }, [sidebarWidth]);

  return (
    <View
      class={[
        "flex items-stretch",
        "absolute inset-0",
        "select-none",
      ]}
    >
      <View
        class={[
          "absolute inset-0 flex flex-col items-center justify-center",
          "transition-opacity",
          "bg-gradient-to-br from-gray-100 to-gray-200 dark:(from-gray-900 to-gray-800)",
          !isDashboardOpen && "pointer-events-none",
          isDashboardOpen ? "opacity-100" : "opacity-0",
        ]}
      >
        <Flower
          hidden={!isDashboardOpen}
        />
        <View
          tag="h1"
          class={[
            "relative z-10",
            "text-2xl text-gray-900 dark:text-gray-100",
          ]}
        >
          Flowery<b class="text-3xl">AI</b>
        </View>
      </View>
      <View
        class="relative flex"
        style={{
          width: `${sidebarWidth}px`,
        }}
      >
        {sidebar}
      </View>
      <MarkdownContext.Provider
        value={{
          theme: DefaultTheme,
          slots: {},
        }}
      >
        {children}
      </MarkdownContext.Provider>
    </View>
  );
}
