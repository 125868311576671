import { Logout } from "app/components/icons/logout.tsx";
import { useAuth } from "app/hooks/auth.ts";
import { Mail } from "icons/mail.tsx";
import { Markdown, md } from "local/markdown/mod.ts";
import { Item } from "local/ui/item.tsx";
import { List } from "local/ui/list.tsx";
import { View } from "local/ui/view.tsx";

/** HELPERS **/

function Widget() {
  const { user, logout } = useAuth();
  return (
    <List>
      <Item
        icon={<Mail />}
        title={user.email}
      />
      <Item
        icon={<Logout />}
        title="Logout"
        color="blue"
        interactive
        onClick={() => {
          logout();
        }}
      />
    </List>
  );
}

const markdown = md`
## Account Settings

${<Widget />}
`;

/** MAIN **/

export function UserSettings() {
  return (
    <View class="flex flex-col gap-6">
      <Markdown root={markdown} />
    </View>
  );
}
