import { useEventBus } from "app/hooks/event_bus.tsx";
import { GraphqlExec, useQuery } from "app/hooks/graphql.ts";
import { Repo } from "../../../../packages/repos/mod.ts";
import * as promptCommitMutations from "../../prompts/mutations/mod.ts";
import { useFlowRepo, usePromptRepo } from "../../stores/mod.ts";
import * as flowCommitMutations from "../mutations/mod.ts";
import * as mutations from "../mutations/mod.ts";
import * as Flow from "../types/flow.ts";

/** HELPER **/

type PromptRepo = Repo<{
  id: string;
  name: string;
  body: string;
  parameters: {
    temperature: number;
    maxTokens: number;
    stopSequences: string[];
    frequencyPenalty: number;
    presencePenalty: number;
    mode: string;
    model: string;
  };
}>;
type FlowRepo = Repo<Flow.State>;

async function commitPrompt(
  promptId: string,
  repos: { prompts: PromptRepo },
  query: GraphqlExec,
  name: string,
): Promise<{ id: string; type: "prompt-commits"; name: string }> {
  const prompt = repos.prompts.store.value.data.find((p) => p.id === promptId);

  if (!prompt) {
    throw new Error("Prompt not found");
  }

  const commitRef = await query(
    promptCommitMutations.createPromptCommit.query,
    {
      input: {
        prompt,
        message: "Auto-generated commit",
      },
    },
  );

  return { id: commitRef.id, type: "prompt-commits", name };
}

async function commitFlow(
  flowId: string,
  repos: { prompts: PromptRepo; flows: FlowRepo },
  query: GraphqlExec,
): Promise<{ id: string; type: "flow-commits" }> {
  const flow = repos.flows.store.value.data.find((f) => f.id === flowId);

  if (!flow) {
    throw new Error("Flow not found");
  }

  const commitPromises = (flow?.steps
    .flatMap((step) => {
      if (step.type === "prompts") {
        return commitPrompt(step.id, repos, query, step.name);
      }

      if (step.type === "flows") {
        return commitFlow(step.id, repos, query);
      }
    })
    .filter(Boolean) ?? []) as Promise<{
      id: string;
      type: "flow-commits" | "prompt-commits";
      name: string;
    }>[];

  const commits = await Promise.all(commitPromises);

  const commitRef = await query(flowCommitMutations.createFlowCommit.query, {
    input: {
      flow: { ...flow, steps: commits },
      message: "Auto-generated commit",
    },
  });
  return {
    id: commitRef.id,
    type: "flow-commits",
  };
}

function assertFlowSizeLimit(
  flow: Flow.State,
  limit: number,
  repos: { flows: FlowRepo; prompts: PromptRepo },
): boolean {
  let flowSize = 0;

  const steps = [...flow.steps];
  let currentStep: Flow.State["steps"][0] | undefined;

  while (((currentStep = steps.shift()), currentStep)) {
    if (flowSize > limit) {
      return false;
    }

    if (currentStep.type === "flows") {
      const step = repos.flows.store.value.data.find(
        (f) => f.id === currentStep?.id,
      );

      steps.push(...(step?.steps ?? []));
      flowSize++;
    } else {
      flowSize++;
    }
  }
  return true;
}

/** MAIN **/
export function usePlay() {
  const query = useQuery();
  const eventBus = useEventBus();
  const promptRepo = usePromptRepo();

  const flowRepo = useFlowRepo();

  return async function (options: {
    flow: Flow.State;
    variables?: { name: string; value: string }[];
  }) {
    const flowSizeOk = assertFlowSizeLimit(options.flow, 50, {
      flows: flowRepo,
      prompts: promptRepo,
    });

    if (!flowSizeOk) {
      throw new Error("Flow size exceeds limit");
    }

    const commitRef = await commitFlow(
      options.flow.id,
      {
        prompts: promptRepo,
        flows: flowRepo,
      },
      query,
    );

    const runner = await query(mutations.createFlowRunner.query, {
      input: {
        flowCommitId: commitRef.id,
        variables: options.variables ?? null,
      },
    });

    eventBus.publish({
      type: "flow-runner-create",
      data: runner,
    });
  };
}
