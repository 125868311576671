import { useQuery } from "app/hooks/graphql.ts";
import { createMutation, createQuery, fields } from "app/utils/graphql.ts";
import {
  array,
  number,
  object,
  string,
  unknown,
} from "local/deps/superstruct.ts";
import { createRepo, Repo } from "local/repos/mod.ts";
import { Prompt } from "../types/mod.ts";

/** HELPERS **/

const listQuery = createQuery({
  name: "listPrompts",
  output: array(Prompt),
  body: `
    query {
      listPrompts {
        ${fields(Prompt)}
      }
    }
  `,
});

const createMutations = createMutation({
  name: "createPrompt",
  vars: object({
    input: object({
      name: string(),
      body: string(),
      parameters: object({
        temperature: number(),
        maxTokens: number(),
        stopSequences: array(string()),
        frequencyPenalty: number(),
        presencePenalty: number(),
        mode: string(),
        model: string(),
      }),
    }),
  }),
  output: Prompt,
  body: `
    mutation ($input: CreatePromptInput!) {
      createPrompt(input: $input) {
        ${fields(Prompt)}
      }
    }
  `,
});

const updateMutation = createMutation({
  name: "updatePrompt",
  vars: object({
    input: Prompt,
  }),
  output: unknown(),
  body: `
    mutation ($input: UpdatePromptInput!) {
      updatePrompt(input: $input) {
        id
      }
    }
  `,
});

const deleteMutation = createMutation({
  name: "deletePrompt",
  vars: object({
    id: string(),
  }),
  output: unknown(),
  body: `
    mutation ($id: ID!) {
      deletePrompt(id: $id) {
        id
      }
    }
  `,
});

/** MAIN **/

let repo: Repo<Prompt>;

export function usePromptRepo() {
  const exec = useQuery();
  if (repo) return repo;

  repo = createRepo({
    create: (item) => {
      return exec(createMutations, {
        input: {
          body: item.body,
          name: item.name,
          parameters: item.parameters,
        },
      });
    },
    update: async (data) => {
      await exec(updateMutation, { input: data });
    },
    remove: async (id) => {
      await exec(deleteMutation, { id });
    },
    equals: (a, b) => {
      return (
        a.id === b.id &&
        a.name === b.name &&
        a.body === b.body &&
        JSON.stringify(a.parameters) === JSON.stringify(b.parameters)
      );
    },
  });

  const { load, merge } = repo;

  load(async () => {
    merge(await exec(listQuery));
  });

  return repo;
}
