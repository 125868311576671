import {
  ReadonlySignal,
  Signal,
  useComputed,
  useSignal,
} from "local/deps/preact/signals.ts";
import { List } from "local/ui/list.tsx";
import { Effect } from "../types.ts";
import { LayerItem } from "./layer_item.tsx";

/** MAIN **/

export function Layers(props: {
  effect: Signal<Effect>;
  time: ReadonlySignal<number>;
}) {
  const { effect, time } = props;

  const visibleLayers = useSignal<string[]>([]);

  const layers = useComputed(() => {
    const list: string[] = [];
    for (const frame of effect.value.frames) {
      if (!list.includes(frame.layer)) list.push(frame.layer);
    }
    return list;
  });

  const maxTotal = useComputed<number>(() => {
    return Math.max(
      ...layers.value.map((layer) => {
        return effect.value.frames
          .filter((frame) => frame.layer === layer)
          .reduce((acc, frame) => acc + frame.duration, 0);
      }),
    );
  });

  function toggleLayer(name: string) {
    const layers = [...visibleLayers.value];
    const index = layers.indexOf(name);
    if (index === -1) {
      layers.push(name);
    } else {
      layers.splice(index, 1);
    }
    visibleLayers.value = layers;
  }

  return (
    <List>
      {layers.value.map((layer) => (
        <LayerItem
          key={layer}
          effect={effect.value}
          layer={layer}
          time={time.value}
          maxTotal={maxTotal.value}
          onClick={() => toggleLayer(layer)}
        />
      ))}
    </List>
  );
}
