import { array, enums, Infer, object, string } from "local/deps/superstruct.ts";

/** MAIN **/

export type Flow = Infer<typeof Flow>;

export const Flow = object({
  id: string(),
  name: string(),
  steps: array(object({
    id: string(),
    name: string(),
    type: enums([
      "prompts",
      "flows",
      "empty",
    ]),
  })),
});
