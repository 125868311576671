import { Effect } from "local/brightbeats/mod.ts";
import {
  ReadonlySignal,
  Signal,
  useComputed,
  useSignal,
} from "local/deps/preact/signals.ts";
import { View } from "local/ui/view.tsx";
import { Petals } from "./petals.tsx";

/** MAIN **/

export function Visuals(props: {
  hidden: boolean;
  effect: Signal<Effect>;
  seed: number;
  time: ReadonlySignal<number>;
}) {
  const { hidden, effect, seed, time } = props;
  const rect = useSignal<DOMRect | undefined>(undefined);

  const { width, height } = rect.value ?? { width: 0, height: 0 };

  const layers = useComputed(() => {
    const list: string[] = [];
    for (const frame of effect.value.frames) {
      if (!list.includes(frame.layer)) list.push(frame.layer);
    }
    return list;
  });

  return (
    <View
      rect={rect}
      class={[
        "absolute inset-0",
      ]}
    >
      <View
        tag="svg"
        width={width}
        height={height}
        rect={rect}
        class={[
          "absolute inset-0",
        ]}
        style={{
          transition: "transform 500ms",
          transform: `scale(${hidden ? 0 : 1})`,
        }}
      >
        {layers.value.map((layer, i) => (
          <Petals
            key={i}
            height={height}
            width={width}
            seed={seed}
            count={layers.value.length}
            index={i}
            effect={effect.value}
            layer={layer}
            time={time.value}
          />
        ))}
      </View>
    </View>
  );
}
