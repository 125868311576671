import { JSX, options, VNode } from "local/deps/preact.ts";
import * as twind from "local/deps/twind.ts";

/** HELPERS **/

// deno-lint-ignore no-explicit-any
type DOMProps = JSX.HTMLAttributes<any>;

/** MAIN **/

export { create, cssomSheet } from "https://esm.sh/twind@0.16.17";
export { virtualSheet } from "https://esm.sh/twind@0.16.17/sheets";
export type { VirtualSheet } from "https://esm.sh/twind@0.16.17/sheets";

export function install(instance: twind.Instance) {
  const { tw } = instance;
  options.vnode = (vnode: VNode<DOMProps>) => {
    if (typeof vnode.type === "string" && typeof vnode.props === "object") {
      const { props } = vnode;
      const classes: string[] = [];
      if (props.class) {
        classes.push(tw(props.class));
        props.class = undefined;
      }
      if (props.className) {
        classes.push(tw(props.className));
      }
      if (classes.length) {
        props.class = classes.join(" ");
      }
    }
  };
}
