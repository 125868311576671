import {
  array,
  Infer,
  number,
  object,
  string,
} from "local/deps/superstruct.ts";

/** MAIN **/

export type PromptCommit = Infer<typeof PromptCommit>;

export const PromptCommit = object({
  id: string(),
  createdAt: string(),
  promptId: string(),
  message: string(),
  promptSnapshot: object({
    name: string(),
    body: string(),
    parameters: object({
      temperature: number(),
      maxTokens: number(),
      stopSequences: array(string()),
      frequencyPenalty: number(),
      presencePenalty: number(),
      mode: string(),
      model: string(),
    }),
  }),
});
