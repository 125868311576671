import { SpinnerTwo } from "icons/spinner_two.tsx";
import { Icon } from "./icon.tsx";

/** MAIN **/

export function Spinner(props: {
  size?: number;
}) {
  const { size } = props;
  return (
    <Icon class="animate-spin opacity-50" size={size}>
      <SpinnerTwo />
    </Icon>
  );
}
