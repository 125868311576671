import { ArrowLeft } from "icons/arrow_left.tsx";
import { Signal, useComputed } from "local/deps/preact/signals.ts";
import { Chip } from "local/ui/chip.tsx";
import { Icon } from "local/ui/icon.tsx";
import { Panel } from "local/ui/panel.tsx";
import { Spinner } from "local/ui/spinner.tsx";
import { View } from "local/ui/view.tsx";
import { useFlowRunner } from "../hooks/use_flow_runner.ts";
import { FlowRunner } from "./flow_runner.tsx";

/** MAIN **/

export interface FlowRunnerDetailsProps {
  flowRunnerId: Signal<string | null>;
}

export function FlowRunnerDetails(
  props: FlowRunnerDetailsProps,
) {
  const { flowRunnerId } = props;

  const item = useFlowRunner(flowRunnerId.value ?? "");

  const loading = useComputed(() => {
    return item.loading.value && !item.data.value;
  });

  return (
    <Panel
      isBodyHidden={loading.value}
      header={loading.value
        ? (
          <View class="flex items-center gap-4 text-lg text-gray-500">
            <Spinner size={8} />
            Loading prompt results...
          </View>
        )
        : (
          <View
            tag="text"
            class={[
              "h-full",
              "w-full",
              "flex",
              "items-center",
              "justify-between",
            ]}
          >
            <Chip
              onClick={() => flowRunnerId.value = null}
            >
              <Icon>
                <ArrowLeft />
              </Icon>
              Back to editor
            </Chip>
          </View>
        )}
    >
      <FlowRunner reload={item.load} flowRunner={item.data.value} />
    </Panel>
  );
}
