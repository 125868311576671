import { useQuerySignal } from "app/hooks/graphql.ts";
import { useEffect } from "local/deps/preact/hooks.ts";
import * as getFlowRunner from "../queries/flow_runner.ts";

/** MAIN **/
export function useFlowRunner(id: string) {
  const result = useQuerySignal(getFlowRunner.query, { id });

  useEffect(() => {
    result.load();
  }, [id]);

  useEffect(() => {
    result.data.value = undefined;
  }, [id]);

  return { ...result };
}
