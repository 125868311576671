import { assert } from "local/deps/superstruct.ts";
import { FLOWERY_API_KEY, FLOWERY_FLOW_URLS } from "./config.ts";
import { parseEffect } from "./parse_effect.ts";
import { Effect, Execution } from "./types.ts";

/** MAIN **/

async function create(url: URL, input: string | null): Promise<Execution> {
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${FLOWERY_API_KEY}`,
    },
    body: JSON.stringify({
      data: {
        type: "executions",
        attributes: {
          variables: [{ name: "input", value: input ?? "" }],
        },
      },
    }),
  });

  const json = await response.json();
  assert(json.data, Execution);
  return json.data;
}

async function poll(url: URL, id: string): Promise<Execution> {
  const pollingUrl = new URL(id, url.href + "/");
  const response = await fetch(pollingUrl, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${FLOWERY_API_KEY}`,
    },
  });

  const json = await response.json();
  assert(json.data, Execution);
  return json.data;
}

/** MAIN **/

export async function createEffect(input: string | null): Promise<Effect> {
  let url: URL;
  if (!input) {
    url = FLOWERY_FLOW_URLS[1];
  } else {
    url = FLOWERY_FLOW_URLS[0];
  }

  const { id } = await create(url, input);
  return new Promise((resolve, reject) => {
    const poller = async () => {
      const { attributes } = await poll(url, id);
      const { status, output } = attributes;
      if (status === "success") {
        if (!output) {
          reject(new Error("Effect output missing"));
          return;
        }
        const effect = parseEffect(output);
        resolve(effect);
      } else if (status === "error") {
        reject(new Error("Effect failed: " + attributes.statusReason));
      } else {
        setTimeout(() => {
          poller();
        }, 1000);
      }
    };

    poller();
  });
}
