import { md } from "local/markdown/md.ts";
import { Markdown } from "local/markdown/mod.ts";
import { View } from "local/ui/view.tsx";
import { KeyWidget } from "./key_widget.tsx";

/** HELPERS **/

const source = () =>
  md`
## OpenAI API Key

An OpenAI API key is required to access the OpenAI platform and its services. 
It is used to authenticate and authorize access to OpenAI's APIs.

${<KeyWidget />}

> API keys can be created on the 
> [OpenAI website](https://platform.openai.com/account/api-keys).
> This key is only used to run prompts on your behalf. 
> You can revoke the key at any time.
`;

/** MAIN **/

export function KeySettings() {
  return (
    <View class="flex flex-col gap-6">
      <Markdown root={source()} />
    </View>
  );
}
