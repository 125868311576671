import { Error } from "app/components/icons/error.tsx";
import { Ghost } from "icons/ghost.tsx";
import { Age } from "local/ui/age.tsx";
import { Icon } from "local/ui/icon.tsx";
import { Item } from "local/ui/item.tsx";
import { Spinner } from "local/ui/spinner.tsx";
import { Completion } from "./completions_list.tsx";

/** MAIN **/

export interface CompletionItemProps {
  selected: boolean;
  completion: {
    id: string;
    promptId: string;
    createdAt: string;
    status: Completion["status"];
  };
  onCompletionClicked: (id: string) => void;
}

export function CompletionItem(props: CompletionItemProps) {
  const {
    completion: { id, createdAt, status },
    onCompletionClicked,
    selected,
  } = props;

  return (
    <Item
      selected={selected}
      interactive
      onClick={() => onCompletionClicked(id)}
    >
      {status === "pending" && <Spinner />}
      {status === "success" && (
        <Icon>
          <Ghost />
        </Icon>
      )}
      {status === "error" && (
        <Icon title="Prompt failed" class="text-red-500">
          <Error />
        </Icon>
      )}
      <Age time={new Date(createdAt)}></Age>
    </Item>
  );
}
