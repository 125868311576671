import { View } from "local/ui/view.tsx";
import { useCallback } from "../../packages/deps/preact/hooks.ts";

/** MAIN **/

export interface NumberSliderInputProps {
  title: string;
  value: string | number;
  upperLimit: number;
  lowerLimit: number;
  step?: number;
  tooltip?: string;
  onInput: (newNumber: number) => void;
}

export function NumberSliderInput(props: NumberSliderInputProps) {
  const { title, onInput, value, upperLimit, lowerLimit, step, tooltip } =
    props;

  const changeNumberInput = useCallback(
    (e: Event) => {
      const target = e.currentTarget as HTMLInputElement;
      const number = Number(target.value);

      if (isNaN(number)) {
        target.value = (value).toString();
        return;
      }

      if (number > upperLimit || number < lowerLimit) {
        target.value = (value).toString();
        return;
      }

      onInput(number);
    },
    [upperLimit, lowerLimit, onInput],
  );

  return (
    <View title={tooltip} class={["flex flex-col w-full"]}>
      <View class={["flex w-full justify-between items-between"]}>
        <View class="text-sm mb-1">
          {title}
        </View>
        <View class="text-sm">
          {value}
        </View>
      </View>
      <View
        id={title}
        tag="input"
        type="range"
        min={lowerLimit}
        max={upperLimit}
        value={value}
        step={step}
        onInput={changeNumberInput}
      />
    </View>
  );
}
