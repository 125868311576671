import { useSignal } from "local/deps/preact/signals.ts";
import { View, ViewNode } from "local/ui/view.tsx";
import { useMemo } from "../deps/preact/hooks.ts";
import { Field } from "./field.tsx";

/** MAIN **/

export type Token = {
  type: string;
  value: string;
};

export type TextEditorProps<T> = {
  value: T;
  disabled?: boolean;
  title?: string;
  borderColor?: string;
  placeholder?: ViewNode;
  onChange: (value: T) => void;
  stringify: (value: T) => string;
  tokenize: (value: T) => Iterable<Token>;
  parse: (value: string) => T;
  renderToken: (token: Token, index: number) => ViewNode;
};

export function TextEditor<T>(props: TextEditorProps<T>) {
  const {
    value,
    title,
    disabled,
    parse,
    tokenize,
    stringify,
    renderToken,
    onChange,
    borderColor,
  } = props;

  const rect = useSignal<DOMRect | undefined>(undefined);

  const tokens = useMemo(() => {
    return [...tokenize(value)];
  }, [value]);

  const text = useMemo(() => {
    return stringify(value);
  }, [value]);

  return (
    <View
      tag="div"
      title={title}
      class={[
        "relative",
        "text-mono",
      ]}
      style={{
        // Small correction necessary to get 48px height for single line.
        // This is needed to align Field vertically with Item.
        height: Math.round((rect.value?.height ?? 1) - 1),
      }}
    >
      <Field
        tag="pre"
        rect={rect}
        color={borderColor}
        // view layer is visible but non-interactive
        class={[
          "pointer-events-none",
        ]}
      >
        {text === "" && props.placeholder}
        {tokens.map((token, index) => renderToken(token, index))}

        <View tag="span" class="text-transparent">
          <br />
        </View>
      </Field>
      <Field
        tag="textarea"
        value={text}
        disabled={disabled}
        onInput={(e) => {
          if (disabled) return;
          onChange(parse(e.currentTarget.value));
        }}
        // edit layer is interactive but only selection is visible
        // see: https://github.com/react-simple-code-editor/react-simple-code-editor#limitations
        invisible
        class={[
          "absolute inset-0",
          "overflow-hidden",
        ]}
        style={{
          "-webkit-text-fill-color": "transparent",
          "text-fill-color": "transparent",
        }}
      />
    </View>
  );
}
